@charset "utf-8";
 /***************************

object/utility/_background.scss

***************************/
.u-bg--white{
  background-color:#FFF;
}

.u-bg--gray{
  background-color:#f4f4f4;
}

.u-bg--gray2{
  background-color:#7d7d7d;
}

.u-bg--gray3{
  background-color:#eee;
}

.u-bg--graph{
  background-image:url(../img/common/bg_graph.png);
}

.u-bg--bunkyo_blue{
  background-color:$bunkyo_blue;
}

.u-bg--blue01{
  background-color:#EDF2F8;
}