@charset "utf-8";
 /***************************

layout/_anchor-link.scss

***************************/

.l-anchor-link__item{
  @include flex-item;
  border:1px solid $bunkyo_blue;
  @include border-radius(10px);
  margin-right:15px;
  margin-bottom:10px;

  a{
    color:$bunkyo_blue;
    font-weight:bold;
    text-align:center;
    padding:15px 30px 15px 15px;
    position: relative;
    display:table;
    text-decoration:none;
    span{
      display: table-cell;
      vertical-align: middle;
    }
  }
}
.l-anchor-link__item a:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f107";
  font-weight:bold;
  font-size:14px;
  position: absolute;
  right: 10px;
  top:calc(50% - 10px);
}

.l-anchor-link__item:hover{
  background:$bunkyo_blue;

  a{
    color:#fff;
    opacity:1;
  }
}

@include min-tab{
  .l-anchor-link{
    @include flexbox;
    @include flex-justify-start;
    @include flex-wrap;
  }

  .l-anchor-link.is-3column{
    max-width:930px;
    margin-left:auto;
    margin-right:auto;

    .l-anchor-link__item{
      width:calc((100% - 30px) / 3);
    }
    .l-anchor-link__item:nth-of-type(3n){
      margin-right:0;
    }

  }

  .l-anchor-link.is-4column{
    //max-width:930px;
    margin-left:auto;
    margin-right:auto;

    .l-anchor-link__item{
      width:calc((100% - 45px) / 4);
    }
    .l-anchor-link__item:nth-of-type(4n){
      margin-right:0;
    }

  }

  .l-anchor-link.is-5column{
    //max-width:930px;
    margin-left:auto;
    margin-right:auto;

    .l-anchor-link__item{
      width:calc((100% - 60px) / 5);
    }
    .l-anchor-link__item:nth-of-type(5n){
      margin-right:0;
    }

  }

}

@include max-tab{
  .l-anchor-link__item a{
    padding:10px 30px 10px 15px;
  }
}