@charset "utf-8";
 /***************************

object/project/_favorable.scss

***************************/

.p-favorable-priority__table__th1{
  width:20%;
}

.p-favorable-priority__table__th2{
  width:40%;
}

.p-favorable-exellence__table__th1{
  width:20%;
}

.p-favorable-exellence__table__th2{
  width:calc(80% / 3);
}