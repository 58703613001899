@charset "utf-8";
 /***************************

object/project/_campus-event.scss

***************************/

.p-campus-event{
  margin-bottom:50px;
}

.p-campus-event__img{
  @include flex-item;
  @include flexbox;
  @include flex-wrap;
  @include flex-align-start;
}

.p-campus-event__img__item{
  @include flex-item;
  width:calc((100% - 15px) / 2);
  margin-bottom:15px;
}

@include min-tab{
  .p-campus-event__img__item{
    width:calc((100% - 30px) / 2);
    margin-bottom:30px;
  }

  .p-campus-event__img__item:not(:nth-of-type(2n)){
    margin-right:30px;
  }

}

@include max-tab{
  .p-campus-event__img__item:not(:nth-of-type(2n)){
    margin-right:15px;
  }
}

@include min-sp{
  .p-campus-event{
    @include flexbox;
  }

  .p-campus-event__list{
    @include flex-item;
    width:calc((100% - 40px) / 3);
    margin-right:40px;
  }

  .p-campus-event__img{
    @include flex-item;
    width:calc((100% - 40px) / 3 * 2);
  }
}

@include max-sp{
  .p-campus-event__list{
    margin-bottom:30px;
  }
}
