@charset "utf-8";
 /***************************

layout/_search.scss

***************************/
.l-search{
  background:#000;
  padding: 13px 15px;
}

.l-search__form{
position:relative;
}
.l-search__sbox{
padding:10px;
border:none;
border-radius:30px;
outline:0;
background:#fff;
width: 100%;
}
.l-search__sbtn{
height:50px;
position:absolute;
right:8px;
top:-7px;
background:none;
color:#666;
border:none;
font-size:20px;
}
.l-search__sbtn:hover{
color:#ccc;
}