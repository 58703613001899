@charset "utf-8";
 /***************************

layout/_slick.scss

***************************/
/* Slick Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
.slick-slide img{
  width: 100%;
  height: auto; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./img/common/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick/slick.eot");
  src: url("./fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick/slick.woff") format("woff"), url("./fonts/slick/slick.ttf") format("truetype"), url("./fonts/slick/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  z-index: 1;
  display: block;
  width: 16px;
  height: 31px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  margin-top: -15px;
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    opacity: 0.65;
    color: transparent; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.25; }

.slick-prev {
  left: 30px;
  background: url(img/common/btn-prev.png) no-repeat center center; }

.slick-next {
  right: 30px;
  background: url(img/common/btn-next.png) no-repeat center center; }

  /* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 0; }

.slick-dots {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
//        font-family: "slick";
        font-size: 30px;
//        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #AAA;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #000;
      opacity: 0.75; }

.slick-archive {
  padding-top: 10px;
  margin: 0 auto;
  width: calc(100% - 50px); }

.slick-archive__item {
  padding: 5px; }
  .slick-archive__item a {
    display: block;
    max-width: 350px;
    margin: auto;
    color: #231815;
    text-decoration: none;
    position: relative; }
    .slick-archive__item a:hover {
      opacity: 0.65; }

.slick-archive__date {
  margin-top: 5px;
  display: block; }

.slick-archive__title {
  display: block;
  font-weight: bold; }

.slick-archive__label {
  background-color: #464a4c;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: .2em 2em; }

@media screen and (max-width: 560px) {
  .slick-archive__date {
    font-size: 1rem; }
  .slick-archive__title {
    font-size: 1.2rem; }
  .slick-archive__label {
    font-size: 1rem; } }
