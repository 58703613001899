@charset "utf-8";
 /***************************

object/project/_home.scss

***************************/
.p-home-heading--type1{
  @include ff-rc;
  color:$bunkyo_blue;
  font-size:40px;
  text-align:center;
  span{
    display:block;
    color:#221815;
    font-size:13px;
    margin-top:-7px;
  }
}

@include max-sp{
  .p-home-heading--type1{
    font-size:36px;
  }
}

.p-home-heading--type2{
  @include ff-rc;
  color:#fff;
  font-size:40px;
  text-align:center;
  margin-bottom:30px;
  span{
    display:block;
    font-size:13px;
    margin-top:-7px;
  }
}

.p-home-heading--type3{
  @include ff-rc;
  font-size:32px;
  border-bottom:2px solid #201813;
  color:#201813;
  display:inline-block;
}

.p-home-main-visual{
  position:relative;
  .slick-dots{
    @include flexbox;
    width:50px;
    right:110px;
    counter-reset: item;
    li{
      flex:1 1 auto;
      position:relative;
      button{
        width:100%;
      }
      button:before{
        content:"";
        background-color:#FFF;
        height:1px;
        width:100%;
      }
    }
    li.slick-active{
      button:before{
        background-color:$bunkyo_blue;
        opacity:1;
      }
    }
    li:before{
      counter-increment: item;
      content:counter(item)"";
      color:#fff;
      opacity:0;
    }
    li:first-of-type:before{
      position:absolute;
      top:-10px;
      left:-1em;
      opacity:1;
    }
    li:last-of-type:before{
      position:absolute;
      top:-10px;
      right:-1em;
      opacity:1;
    }
  }
}

.p-home-main-visual__message{
  position:absolute;
  top:calc(50% - 150px);
  left:100px;
  z-index:5;
}

.p-home-main-visual__btn-scroll{
  position:absolute;
  bottom:52px;
  left:calc(50% - 15px);
  z-index:6;
  height:auto;
  width:80px;
}

.p-home-main-visual__slide__item{
  height:calc(100vh - 246px);
  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;'/*IE対策*/
  }
}

.p-home-main-visual__news{
  background:$bunkyo_blue;
}

.p-home-main-visual__news__heading{
  @include flex-item;
  background:#000;
  color:#fff;
  font-size:14px;
  padding:15px 50px;
  text-align:center;
}

.p-home-main-visual__news__slide{
  @include flex-item;
  background:$bunkyo_blue;
  padding: 0 30px;
  a{
    color:#fff;
    font-weight:bold;
    text-decoration:none;
  }
  span{
    color:#8EABD1;
    margin-right:15px;
  }
}

@include min-tab{
  .p-home-main-visual__news__slide{
    width:calc(100% - 198px);
  }
}

@include max-pc{
  .p-home-main-visual__slide__item{
    height:calc(100vh - 132px);
  }
}

@include max-tab{
  .p-home-main-visual__slide__item{
    height:calc(100vh - 157px);
  }

  .p-home-main-visual__news__slide{
    padding: 10px 20px;
  }
  .p-home-main-visual__message{
    left:10%;
    margin-right:20%;
  }
  .p-home-main-visual__btn-scroll{
    display:none;
  }
}

.p-home-pickup{
  padding:80px 0 60px;
}

.p-home-pickup__list__item__img{
  img{
    object-fit: cover;
    font-family: 'object-fit: cover;'/*IE対策*/
  }
}

@media screen and (min-width: 1461px){
  .p-home-pickup__list__item__img{
    height:calc((1280px / 4 - 40px) / 325 * 217);
    img{
      height:calc((1280px / 4 - 40px) / 325 * 217);
    }
  }
}

@media screen and (min-width: 1311px) and (max-width: 1460px){
  .p-home-pickup__list__item__img{
    height:calc((1205px / 4 - 40px) / 325 * 217);
      img{
        height:calc((1205px / 4 - 40px) / 325 * 217);
    }
  }
}

@media screen and (min-width: 1101px) and (max-width: 1310px){
  .p-home-pickup__list__item__img{
    height:calc(((100vw - 107px) / 4 - 40px) / 325 * 217);
      img{
      height:calc(((100vw - 107px) / 4 - 40px) / 325 * 217);
    }
  }
}
@media screen and (max-width: 1100px){
  .p-home-pickup__list__item__img{
    height:calc((100vw / 4 - 40px) / 325 * 217);
      img{
        height:calc((100vw / 4 - 40px) / 325 * 217);
    }
  }
}
@include max-tab{
  .p-home-pickup__list__item__img{
    height:calc(((100vw - 60px) / 2 - 40px) / 325 * 217);
      img{
      height:calc(((100vw - 60px) / 2 - 40px) / 325 * 217);
    }
  }
}

@include max-sp{
  .p-home-pickup__list__item__img{
    height:calc((100vw - 40px) / 325 * 217);
      img{
        height:calc((100vw - 40px) / 325 * 217);
    }
  }
}

@media screen and (min-width: 1101px) and (max-width: 1460px){
  .p-home-pickup__list{
    padding-right:90px;
  }
}

@include max-tab{
  .p-home-pickup{
    padding:30px 0;
  }
}

.p-home-pickup__list{
  max-width:1280px;
  margin:0 auto;
  padding-bottom:35px;
}

.p-home-pickup__list__item{
  padding:20px;
  a{
    text-decoration:none;
    color:#000;
  }
}

@include min-sp{
  .p-home-pickup__list__item{
    max-width:365px;
  }
}

.p-home-event__list__item{
  position:relative;
  margin-bottom:50px;
  a{
    text-decoration:none;
    display:block;
  }
}

.p-home-pickup{
  .slick-dots{
    li{
      padding:0 12px;
      button:before{
        color:#D1DDE8;
      }
    }
    li.slick-active{
      button:before{
        color:$bunkyo_blue;
        opacity:1;
      }
    }
  }
}

.p-home-event__list__item__date{
  background:$bunkyo_blue;
  color:#fff;
  font-size:12px;
  text-align:center;
  padding-top:5px;
  width:80px;
  position:absolute;
  top:-10px;
  left:-10px;
  z-index:5;

  span{
    display:block;
    margin-top:5px;
    padding:10px 5px;
    background:#D1DDE8;
    color:#000;
    font-weight:bold;
    font-size:28px;
  }
}

.p-home-event__list__item__date.is-end{
  background:#666;
  color:#999;
  font-size:20px;
  font-weight:bold;
  padding:0;
  line-height:86px;
}

.p-home-event__list__item__img{
  //padding:50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:20px;
  background:#fff;
  position:relative;
  text-align: center;
}

.p-home-event__list__item__tag{
  color:#fff;
  background:$bunkyo_blue;
  padding:2px 5px;
  width:135px;
  text-align:center;
  position:absolute;
  bottom:0;
  left:0;
  font-size:12px;
}

.p-home-event__list__item__tag.is-end{
  color:#000;
  background:#ddd;
  font-weight:bold;
  display:none;
}

.p-home-event__list__item__heading{
  color:$bunkyo_blue;
  font-size: 16px;
  text-align:left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.p-home-event__list__item__sche{
  color:#000;
}

@include min-tab{
  .p-home-event__list{
    width:calc(50% - 20px);
  }
  .p-home-event__list.is-first{
    margin-right:40px;
    @include flex-item;

    .p-home-event__list__item__heading{
      font-size:28px;
    }

    .p-home-event__list__item__date{
      padding-top:5px;
      width:80px;
      font-size:12px;

      span{
        margin-top:5px;
        padding:10px 5px;
        font-size:28px;
      }
    }
    .p-home-event__list__item__date.is-end{
      padding:27px 0;
      font-size:24px;
    }
  }

  .p-home-event__list.is-first{
    .p-home-event__list__item__img{
      height:540px;
      img{
        object-fit: contain;
        height: 540px;
        width: 100%;
        font-family: 'object-fit: contain;'/*IE対策*/
      }
    }

  }

  .p-home-event__list.is-second{
    @include flex-item;
    @include flexbox;
    @include flex-wrap;

    .p-home-event__list__item__img{
      height:270px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        object-fit: contain;
        height: 270px;
        width: 100%;
        font-family: 'object-fit: contain;'/*IE対策*/
      }
    }

    .p-home-event__list__item{
      width:calc(50% - 20px);
    }

    .p-home-event__list__item:nth-of-type(2n-1){
      margin-right:40px;
    }
  }

}

@include max-tab{
  .p-home-event__list.is-first{
    .p-home-event__list__item__img{
      height:calc(100vw - 15px);
      img{
        object-fit: contain;
        height:calc(100vw - 15px);
        width: 100%;
        font-family: 'object-fit: contain;'/*IE対策*/
      }
    }
  }
  .is-second{

    .p-home-event__list__item__img{
      display:none;
    }
    .p-home-event__list__item{
      background:#fff;
      margin-bottom:20px;
      a{
        padding:25px 10px 25px 85px ;
      }
    }

    .p-home-event__list__item__date{
      top:0;
      left:0;
      height:100%;
      span{
        height:calc(100% - 26px);
        //padding-top:50%;
      }
    }
    .p-home-event__list__item__tag{
      position:static;
      margin-bottom:10px;
    }
  }
}

.p-home-report{
  background:#fff;
  padding:40px 60px;
}

.p-home-report__heading{
  @include ff-rc;
  color:$bunkyo_blue;
  font-size:28px;
  text-align:center;
  position:relative;
  margin-bottom:25px;
  text-decoration:none;
  span{
    display:block;
    color:#221815;
    font-size:13px;
    margin-top:-7px;
  }
}

.p-home-report__list__item{
  padding:0 20px;
  a{
    text-decoration:none;
  }
}

.p-home-report__list__item__img{
  //padding:45px 0;
  background:#EDF2F8;
  margin-bottom:10px;
  position:relative;
  height:270px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    object-fit: contain;
    height: 270px;
    width: 100%;
    font-family: 'object-fit: contain;'/*IE対策*/
  }
}

.p-home-report__list__heading{
  font-weight:bold;
  color:$bunkyo_blue;
  text-align:left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@include min-tab{
  .p-home-report__list__item{
    @include flex-item;
    width:25%;
  }
  .p-home-report__heading:before{
    content:"";
    width:1px;
    height:60px;
    background:$bunkyo_blue;
    position:absolute;
    top:-70px;
    left:50%;
  }
}

@include max-tab{
  .p-home-report{
    padding:30px 20px;
  }
  .p-home-report__list__item{
    border-top:1px dotted #ccc;
    padding:0;
    a{
      padding:20px 15px;
      display:block;
    }
  }
  .p-home-report__list__item:last-of-type{
    border-bottom:1px dotted #ccc;
  }
  .p-home-report__list__item__img{
    display:none;
  }
}

@include max-sp{
  .p-home-report{
    padding:30px 10px;
  }
  .p-home-report__list__item{
    a{
      padding: 20px 5px;
    }
  }
}

.p-home-admission{
  background:url(../img/common/bg_classroom.jpg) no-repeat center;
  background-size:cover;
  padding:80px 0 90px;
}

@include min-tab{
  .p-home-admission-list{
    @include flex-item;
    width:calc(50% - 20px);
    margin-right:40px;
  }
}

@include max-tab{
  .p-home-admission{
    padding:40px 0;
  }
  .p-home-admission-list{
    margin-bottom:30px;
  }
}

.p-home-admission-list__item{
  margin-bottom:3px;
  a{
    padding: 25px 20px;
    background:#fff;
    position:relative;
    text-decoration:none;
    color:#000;
    display:block;
  }

  a:after{
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 25px;
    width: 4px;
    height: 4px;
    border-top: 1px solid #1F1813;
    border-right: 1px solid #1F1813;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.p-home-admission-list2{
  @include flexbox;
  @include flex-wrap;
}

@include min-tab{
  .p-home-admission-list2{
    @include flex-item;
    width:calc(50% - 20px);
  }
}

.p-home-admission-list2__item{
  @include flex-item;
  margin-bottom:20px;
}

.p-home-admission-list2__item.is-2column{
  width:calc(50% - 10px);
  max-width:300px;
}

.p-home-admission-list2__item.is-2column:nth-of-type(2n-1){
  margin-right:20px;
}

@include max-tab{
  .p-home-admission-list2__item{
    margin-bottom:10px;
  }
//  .p-home-admission-list2__item.is-2column{
//    width:calc(50% - 5px);
//  }
//  .p-home-admission-list2__item.is-2column:nth-of-type(2n-1){
//    margin-right:10px;
//  }
}

.p-home-course{
  padding:75px 0 100px;
}

.p-home-course__list{
  @include flex-justify-between;
}

.p-home-course__list__item{
  @include flex-item;
  position:relative;
  margin-bottom:80px;
  a{
    text-decoration:none;
  }
}

.p-home-course__list__item:hover{
  opacity:0.5;
}

@include min-tab{
  .p-home-course__list__item{
    width:calc((100% - 80px) / 3);
  }
  .p-home-course__list__item:not(:nth-of-type(3n)){
    //margin-right:40px;
  }
}


@media screen and (max-width: 780px) and (min-width: 451px){
  .p-home-course__list__item{
    width:calc((100% - 10px) / 2);
  }

  .p-home-course__list__item:nth-of-type(2n-1){
    margin-right:10px;
  }
}

@include max-sp{
  .p-home-course__list__item{
    margin-bottom:50px;
  }
}


.p-home-course__list__item__headding{
  text-align:center;
  font-weight:bold;
  width:100%;
}

.p-home-course__list__item__heading__depart{
  background:rgba(25, 55, 96, 0.9);
  color:#fff;
  font-size:16px;
  padding:15px 5px;
  display:block;
}

.p-home-course__list__item__heading__depart.primary,
.p-home-course__list__item__heading__depart.secondary{
  background:rgba(233, 79, 80, 0.9);
}

.p-home-course__list__item__heading__course{
  background:rgba(255, 255, 255, 0.9);
  padding:25px 5px;
  display:block;
  color:#000;
}

@include min-tab{
  .p-home-course__list__item__headding{
    position:absolute;
    bottom:-45px;
    left:50%;
    transform: translate(-50%);
    max-width:300px;
  }
}

.p-home-course__list__item.is-human{
  .p-home-course__list__item__heading__depart{
    background:rgba(33, 104, 154, 0.9);
  }
  .p-home-course__list__item__heading__depart.welfare{
    background:rgba(32, 176, 128, 0.9);
  }
  .p-home-course__list__item__heading__depart.psychology{
    background:rgba(131, 114, 178, 0.9);
  }
  .p-home-course__list__item__heading__depart.nutrition{
    background:rgba(242, 144, 25, 0.9);
  }
  .p-home-course__list__item__heading__depart.global{
    background:rgba(19, 147, 180, 0.9);
  }
  .p-home-course__list__item__heading__course{
    background:#FFF;
  }
}

@include max-tab{
  .p-home-course__list__item__heading__depart{
    background:rgba(25, 55, 96, 1);
  }
  .p-home-course__list__item__heading__course{
    background:rgba(255, 255, 255, 1);
  }
  .p-home-course__list__item.is-human{
    .p-home-course__list__item__heading__depart{
      background:rgba(33, 104, 154, 0.9);
    }
  }
}


.p-home-special{
  background:url(../img/common/bg_logo.png) no-repeat top right;
  padding:75px 0 60px;
}

.p-home-special__list__item{
  @include flex-item;
  position:relative;
  margin-bottom:40px;
}


@include min-tab{
  .p-home-special__list__item{
    width:calc((100% - 80px) / 3);
  }
  .p-home-special__list__item:not(:nth-of-type(3n)){
    margin-right:40px;
  }
}


@media screen and (max-width: 780px) and (min-width: 451px){
  .p-home-special__list__item{
    width:calc((100% - 10px) / 2);
  }

  .p-home-special__list__item:nth-of-type(2n-1){
    margin-right:10px;
  }
}


.p-home-movie{
  padding:70px 0 75px;
}

.p-home-movie__list{
  padding:70px 0 75px;
  @include flex-justify-between;
}

.p-home-special__list__item{
  @include flex-item;
  position:relative;
}

.p-home-movie__list__item{
  max-width:290px;
}

@include min-tab{
  .p-home-movie__list__item{
    width:calc((100% - 120px) / 4);
  }
  .p-home-movie__list__item:not(:nth-of-type(4n)){
    margin-right:40px;
  }
  .p-home-movie__list__item:nth-of-type(n+5){
    margin-top:40px;
  }
}


@media screen and (max-width: 780px) and (min-width: 451px){
  .p-home-movie__list{
    @include flex-justify-center;
  }
  .p-home-movie__list__item{
    width:calc((100% - 10px) / 2);
    margin-bottom:20px;
  }

  .p-home-movie__list__item:nth-of-type(2n-1){
    margin-right:10px;
  }
}

@include max-sp{
  .p-home-movie__list__item{
    margin:0 auto 40px;
  }
}

.p-home-movie__item__heading{
  color:#fff;
  background:$bunkyo_blue;
  font-size:14px;
  padding:15px 5px;
}