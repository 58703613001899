@charset "utf-8";
 /***************************

object/component/_heading.scss

***************************/
.c-title-type1{
  font-size:32px;
  font-weight: normal;
  position: relative;
  border-top: 2px solid #1f1f1f;
  border-bottom: 1px solid #ddd;
  padding: 30px 2px;
  text-align:left;
  a{
    display:block;
    text-decoration:none;
    color:inherit;
  }
}

.c-title-type1:before{
  content:"";
  width: 30vw;
  max-width: 128px;
  height: 2px;
  @include bunkyo_blue_bkg;
  position: absolute;
  top:-2px;
  left:0;
}
@include min-sp{
  .c-title-type1{
    br{
      display:none;
    }
  }
}
@include max-sp{
  .c-title-type1{
    font-size:26px;
  }
}

.c-title-type2{
  font-size:18px;
  font-weight: bold;
  position: relative;
  border-left: 4px solid $bunkyo_blue;
  padding: 10px 15px;
  text-align:left;
  a{
    display:block;
    text-decoration:none;
    color:inherit;
  }
}

.c-title-type3{
  font-size:16px;
  font-weight: bold;
  padding:10px 15px 10px 25px;
  background:#f4f4f4;
  text-align:left;
  position:relative;
  a{
    display:block;
    text-decoration:none;
    color:inherit;
  }
}
.c-title-type3:before{
  content:"";
  width:15px;
  height:2px;
  position:absolute;
  top:calc(50% - 1px);
  left:0;
  background-color:$bunkyo_blue;
}

.c-title-type4{
  font-size:16px;
  font-weight: bold;
  padding:3px;
  color:$bunkyo_blue;
  display:inline-block;
  text-align:left;
}

.c-title-type5{
  font-size:18px;
  font-weight:bold;
  text-align:left;
}

.c-title-type5:before{
  content:"―";
  margin-right:5px;
}

.c-first-letter--bunkyo_blue:first-letter{
  color:$bunkyo_blue;
  display:inline-block;
}