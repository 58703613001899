@charset "utf-8";
 /***************************

object/project/_access.scss

***************************/

@include max-tab{
  .p-access-map{
    width:1100px;
  }
}