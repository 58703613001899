@charset "utf-8";
 /***************************

layout/_page-title.scss

***************************/
.l-page-title{
  background:url(../img/common/lower_pagetitle_other.jpg) no-repeat center;
  background-size:cover;
  position:relative;
}
.l-page-title.is-about{
  background-image:url(../img/common/lower_pagetitle_about.jpg);
}
.l-page-title.is-academic{
  background-image:url(../img/common/lower_pagetitle_academic.jpg);
}
.l-page-title.is-admission{
  background-image:url(../img/common/lower_pagetitle_admission.jpg);
}
.l-page-title.is-campuslife{
  background-image:url(../img/common/lower_pagetitle_campuslife.jpg);
}
.l-page-title.is-career{
  background-image:url(../img/common/lower_pagetitle_career.jpg);
}
.l-page-title.is-exam{
  background-image:url(../img/common/lower_pagetitle_exam.jpg);
}
.l-page-title.is-alum{
  background-image:url(../img/common/lower_pagetitle_alum.jpg);
}
.l-page-title.is-current{
  background-image:url(../img/common/lower_pagetitle_current.jpg);
}
.l-page-title.is-highschool{
  background-image:url(../img/common/lower_pagetitle_highschool.jpg);
}
.l-page-title.is-parent{
  background-image:url(../img/common/lower_pagetitle_parent.jpg);
}
.l-page-title.is-region{
  background-image:url(../img/common/lower_pagetitle_region.jpg);
}

.l-page-title__title{
  color:#fff;
}

.l-lower-menu__list{
  position:relative;
}

@include min-tab{
  .l-page-title{
    height:250px;
  }

  .l-page-title__title{
    font-size:48px;
    font-weight:bold;
    color:#fff;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
  }
}

@include max-tab{
  .l-page-title{
    padding: 50px 0;
  }

  .l-page-title__title{
    font-size:30px;
    text-align:center;
  }
}

.l-lower-menu{
  padding:10px;
  @include flexbox;
  @include flex-justify-start;
  @include flex-wrap;
  background:#f4f4f4;
  margin-top:70px;
}

.l-lower-menu.is-3column{
  max-width:776px;
  margin-left:auto;
  margin-right:auto;
}

.l-lower-menu.is-4column{
  max-width:1028px;
  margin-left:auto;
  margin-right:auto;
}

@include max-pc{
  .l-lower-menu{
    display:none;
  }
}

.l-lower-menu__list{
  @include flex-item;
  width: calc(100% / 5);
  border: 1px solid #ddd;
  background:#fff;
}

.l-lower-menu__list a{
  padding: 20px 20px 20px 10px;
  //padding: 20px 35px 20px 20px;
  font-size:14px;
  color:#202020;
  position: relative;
  text-decoration:none;
  color:#000;
  display:block;
  }

.l-lower-menu__list a:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  color:$bunkyo_blue;
  position: absolute;
  top:calc(50% - 11px);
  right: 8px;
//  right: 18px;
}

.l-lower-menu__list:nth-of-type(n+6){
  border-top:none;
}

.l-lower-menu__list:nth-of-type(n+2):not(:nth-of-type(5n+1)){
  border-left:none;
}

.l-lower-menu__list:hover{
  background: #E1E9F0;
  > a{
    color:$bunkyo_blue;
    opacity:1;
  }
}

.l-lower-menu__list.is-active{
  background: $bunkyo_blue;
}
.l-lower-menu__list.is-active:hover{
  > a{
    opacity:0.5;
  }
}

.l-lower-menu__list.is-active > a,
.l-lower-menu__list.is-active > a:after{
  color: #FFF;
}

//.l-lower-menu__list.is-active a{
//  pointer-events: none;
//}

.l-lower-menu__list__lower{
  position:absolute;
  top:62px;
  visibility: hidden;
  opacity:0;
  z-index:6;
  width:100%;
  -webkit-transition: all .5s ease;
    transition: all .5s ease;
}
.l-lower-menu__list__lower__list{
  border-top:1px solid #ddd;
  a{
    background:#f4f4f4;
    display:block;
    color:#000;
    padding: 15px 15px;
  }
  a:hover{
    background:$bunkyo_blue;
    color:#FFF;
    opacity:1;
  }
}
.l-lower-menu__list__lower__list__lower__list{
  border-top: 1px solid #ddd;
  a{
    padding-left: 30px;
    position:relative;
  }
  a:before{
    content:"";
    position:absolute;
    top:calc(50% - 1px);
    left:10px;
    width:10px;
    height:2px;
    background:$bunkyo_blue;
  }

}
.l-lower-menu__list__lower__list.is-active,
.l-lower-menu__list__lower__list__lower__list.is-active{
  > a{
    background:$bunkyo_blue;
    color:#FFF;
    opacity:1;
  }
  > a:after{
    color:#fff;
  }
}

.l-lower-menu__list__lower__list,
.l-lower-menu__list__lower__list__lower__list{
  > a:hover:after{
    color:#fff;
  }
}

.l-lower-menu__list:hover{
    .l-lower-menu__list__lower{
      visibility: visible;
      opacity:1;
    }
}