@charset "utf-8";
 /***************************

layout/_footer.scss

***************************/
.footer__outline{
  margin-bottom:50px;
  padding-top:40px;
}

.footer__outline__attached__list{
  border:1px solid #ddd;
  a{
    display:block;
    padding:15px;
    text-align: center;
  }
}

.footer__link{
  border-top:1px solid #ddd;
  border-bottom:1px solid #ddd;
  padding:15px 0;
  margin-bottom:25px;
}

.footer__link__privacy{
  @include flexbox;
  @include flex-align-center;
  @include flex-wrap;

}

.footer__link__privacy__list{
  margin-right:15px;
}

.footer__link__sns{
  @include flexbox;
  @include flex-justify-center;
  @include flex-align-center;
}

.footer__link__sns__list{
  @include flex-item;
  margin: 0 12px;
  padding: 13px 0;
}

.footer__link__sns__list a{
  font-size:30px;
}

.footer__link__sns__list .fa-youtube{
  color:#FF0000;
}

.footer__link__sns__list .fa-line{
  color:#00B900;
}

@media screen and (min-width: 1099px){
  .footer__outline{
    @include flexbox;
    @include flex-align-center;
    @include flex-justify-between;
    @include flex-wrap;
  }

  .footer__outline__logo{
    margin-right:5px;
    flex: 0 0 auto;
  }

  .footer__outline__add{
    margin-top:5px;
    margin-right: 15px;
    margin-left: 10px;
    font-size: 12px;
//    flex: 0 0 auto;
  }

  .footer__outline__attached{
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .footer__outline__attached__list{
    margin-left:10px;

      a{
    padding:10px;
  }
  }

}

@media screen and (max-width: 1100px){
  .footer__outline__attached__list{
    margin-right:10px;
  }

  .footer__outline__add{
    margin-top:15px;
    margin-bottom:15px;
  }
}

@include min-tab{
  .footer__outline__attached{
    @include flexbox;
  }

  .footer__outline__attached__list{
    @include flex-item;
    min-width:190px;
  }

  .footer__link{
    @include flexbox;
    @include flex-justify-between;
  }
}

@include max-tab{
  .footer__outline__logo{
    margin-bottom:15px;
  }

  .footer__outline__attached__list{
    margin-bottom:10px;
  }
}

footer{
  .c-flexlist-type1__list{
    padding:0;
    a{
      display:block;
      padding: 20px 15px 20px 35px;
    }
    .c-link-type1:before{
      left:20px;
    }
  }
}

.to-top{
  position:fixed;
  bottom:0;
  right:0;
  width:80px;
  height:80px;
  background:$bunkyo_blue;
}

.to-top:before{
  content:"";
  position:absolute;
  top:50%;
  left:calc(50% - 5px);
  width:10px;
  height:10px;
  border-top:2px solid #fff;
  border-left:2px solid #fff;
  @include transform-rotate(45deg)
}

@include max-tab{
  .to-top{
    width:60px;
    height:60px;
  }
}