@charset "utf-8";
 /***************************

layout/_header.scss

***************************/

header{
  position:relative;
  a{
    display:block;
  }
}


.top-head .inner{
  @include flexbox;
  @include flex-justify-start;
  @include flex-align-center;
  padding:15px 110px 15px 15px;
  max-width:$contents_width;
  margin:0 auto;
  height: 80px;
}

@include max-tab{
  .top-head .inner{
    height:60px;
  }
}

.top-head__contact-menu{
  margin-left:auto;
}

.top-head__contact-menu__list{
  display: inline-block;
  position: relative;
  margin-right: 20px;
}

.top-head__contact-menu__list a{
  position:relative;
  padding-left: 22px;
  text-decoration:none;
  color:#000;
}

.top-head__contact-menu__list a:before{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  color:$bunkyo_blue;
  position:absolute;
  top: calc(50% - 11px);
  left: 9px;
}


.top-head__portal-menu{
  border: 1px solid #ddd;

  padding: 10px 0;
}

.top-head__portal-menu__list{
  display: inline-block;
  padding: 0 15px;
  a{
    text-decoration:none;
    color:#000;
  }
}

.top-head__portal-menu__list:nth-of-type(n+2){
  border-left:1px solid #ddd;
}

@include max-pc{
  .top-head__portal-menu,
  .top-head__contact-menu,
  .global-nav{
    display: none;
  }
}

.global-nav__target-cover{
  background:#EDF2F8;
}

.global-nav__target{
  @include flexbox;
  max-width:$contents_width;
  margin:0 auto;

}

.global-nav__target__list{
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: calc(100% / 5);
  text-align:center;
    position: relative;
}

.global-nav__target__list.is-acticve,
.global-nav__target__list:hover{
  background: #D9E3F0;
}

.global-nav__target__list:after{
  content:"";
  width: 1px;
  height: 12px;
  @include bunkyo_blue_bkg;
  position: absolute;
  bottom: 7px;
  right: 0;
}

.global-nav__target__list:first-of-type:before{
  content:"";
  width: 1px;
  height: 12px;
  @include bunkyo_blue_bkg;
  position: absolute;
  bottom: 7px;
  left: 0;
}

.global-nav__target__list a{
  padding: 15px;
  text-decoration:none;
  color:#000;
}

.global-nav__menu-cover{
  @include bunkyo_blue_bkg;
}

.global-nav__menu{
  @include flexbox;
  max-width:$contents_width;
  margin:0 auto;
}

.global-nav__menu__list{
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: calc(100% / 5);
  color: #FFF;
  text-align:center;
  border-right: 1px solid #1A65AF;
  position:relative;
}

.global-nav__menu__list.is-active,
.global-nav__menu__list:hover{
  background: #3977B9;
}

.global-nav__menu__list:first-of-type{
  border-left: 1px solid #1A65AF;
}

.global-nav__menu__list a{
  color: #FFF;
  padding: 20px 15px;
  text-decoration:none;
}

@media screen and (max-width: 1460px){
  .global-nav__target,
  .global-nav__menu{
    padding-right:90px;
  }
}

.nav-toggle{
  @include bunkyo_blue_bkg;
  width: 80px;
  height: 80px;
  position: absolute;
  top:0;
  left:-80px;
  padding: 30px 28px;
  cursor: pointer;
}

.nav-toggle span{
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .nav-toggle span, .nav-toggle span::before, .nav-toggle span::after {
      display: block;
      width: 24px;
      height: 2px;
      background-color: #fff;
      outline: 1px solid transparent;
      -webkit-transition-property: background-color, -webkit-transform;
      transition-property: background-color, -webkit-transform;
      transition-property: background-color, transform;
      transition-property: background-color, transform, -webkit-transform;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s; }
    .nav-toggle span::before, .nav-toggle span::after {
      position: absolute;
      content: ""; }
    .nav-toggle span::before {
      top: -8px; }
    .nav-toggle span::after {
      top: 8px; }
.open .nav-toggle span {
  background-color: transparent; }

.open .nav-toggle span::before {
  -webkit-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg); }

.open .nav-toggle span::after {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg); }

.folding-cover{
  display:none;
}

.open + .folding-cover{
  display:block;
  position:fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background:#000;
  opacity: .35;
  z-index: 900;
}

.folding-nav {
    width: 312px;
    height: 100%;
    transition: all 0.2s;
    transform: translate(312px);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    background-color: #FFF;
   }
   .folding-nav.open {
    transform: translate(17px);
   }

.folding-nav__scroll{
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}


.folding-nav__menu__list{
  border-bottom: 1px solid #DDD;
}

.folding-nav__menu__list > a{
  padding: 15px 50px 15px 15px;
  background:#FFF;
  position:relative;
  text-decoration:none;
  color:#000;
}

.folding-nav__menu__list > a:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  color:$bunkyo_blue;
  position: absolute;
  top:calc(50% - 11px);
  right: 16px;
}

.folding-nav__menu__list.is-active > a{
  background:$bunkyo_blue;
  color:#FFF;
}

.folding-nav__menu__list.is-active > a:after{
  color:#FFF;
}

.folding-nav__menu__list:not(.is-active) .folding-nav__menu__list__lower {
  display:none;
}

.folding-nav__menu__list.is-open .folding-nav__menu__list__lower {
  display:block;
}

.folding-nav__menu__list__lower__list{
  border-bottom:1px solid #FFF;
}

.folding-nav__menu__list__lower__list:last-of-type{
  border-bottom: none;
}

.folding-nav__menu__list__lower__list > a{
  padding: 10px 50px 10px 30px;
  background: #f4f4f4;
  position:relative;
  text-decoration:none;
  color:#000;
}

.folding-nav__menu__list__lower__list > a:after{
  content:"";
  position:absolute;
  top:calc(50% - 4px);
  right: 20px;
  width: 4px;
  height: 4px;
  border-top:1px solid $bunkyo_blue;
  border-right:1px solid $bunkyo_blue;
  @include transform-rotate(45deg);
}

.folding-nav__menu__list__lower__list.is-active{
    > a{
      background:#CEDAE6;
    }
//    > a:after{
//      border-top:1px solid #FFF;
//      border-right:1px solid #FFF;
//    }
}
.folding-nav__menu__list__lower__list__lower__list{
  border-top: 1px solid #fff;
  //background-color:#e4e4e4;
  > a{
  padding: 10px 10px 10px 45px;
  text-decoration:none;
  color:#000;
  background-color:#f4f4f4;
  }
}
.folding-nav__menu__list__lower__list__lower__list:last-of-type{
  border-bottom:none;
}
.folding-nav__menu__list__lower__list__lower__list__lower__list{
  > a{
    background-color:#f4f4f4;
    padding: 10px 10px 10px 60px;
    text-decoration:none;
    color:#000;
  }
}
.folding-nav__menu__list__lower__list__lower__list.is-active > a,
.folding-nav__menu__list__lower__list__lower__list__lower__list.is-active > a{
  background-color:#EDF2F8;
}

.folding-nav__target{
  @include flexbox;
  @include flex-wrap;
}

.folding-nav__target__list{
  @include flex-item;
  width: 50%;
  border-bottom: 1px solid #DDD;
}

.folding-nav__target__list:nth-of-type(2n+1){
  border-right: 1px solid #DDD;
}

.folding-nav__target__list a{
  padding: 15px;
  background:#EEE;
  text-decoration:none;
  color:#000;
}

.folding-nav__contact-menu__list a{
  padding: 15px 50px 15px 15px;
  background:$accent;
  position:relative;
  color:#FFF;
  text-decoration:none;
}

.folding-nav__contact-menu__list a:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  position: absolute;
  top:calc(50% - 11px);
  right: 16px;
}

.folding-nav__contact-menu__list i{
  margin-right:15px;
}

.folding-nav__portal-menu__list{
  border-bottom: 1px solid #DDD;
}

.folding-nav__portal-menu__list > a{
  padding: 15px 50px 15px 15px;
  background:#FFF;
  position:relative;
  text-decoration:none;
  color:#000;
}

.folding-nav__portal-menu__list > a:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  color:$bunkyo_blue;
  position: absolute;
  top:calc(50% - 11px);
  right: 16px;
}

.folding-nav__examl__list{
  border-bottom: 1px solid #FFF;
}

.folding-nav__exam__list > a{
  padding: 15px 50px 15px 15px;
  background:$bunkyo_blue;
  position:relative;
  color:#FFF;
  text-decoration:none;
}

.folding-nav__exam__list > a:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  position: absolute;
  top:calc(50% - 11px);
  right: 16px;
}

.folding-nav__exam__list > a img{
  margin-right: 10px;
}

.folding-nav__sns{
  @include flexbox;
  @include flex-justify-center;
  @include flex-align-center;
}

.folding-nav__sns__list{
  @include flex-item;
  margin: 0 25px;
  padding: 13px 0;
}

.folding-nav__sns__list a{
  font-size:30px;
}

.folding-nav__sns__list .fa-youtube{
  color:#FF0000;
}

.folding-nav__sns__list .fa-line{
  color:#00B900;
}

@include max-tab{
  .nav-toggle{
    width: 60px;
    height: 60px;
    left:-60px;
    padding: 21px 19px;
  }
  .folding-nav {
    width: calc(100% - 43px);
    height: 100%;
    transition: all 0.2s;
    transform: translate(100%);
  }
}

@include max-sp{
  .folding-nav__menu__list > a:after,
  .folding-nav__contact-menu__list a:after,
  .folding-nav__portal-menu__list > a:after,
  .folding-nav__exam__list > a:after{
    right: 26px;
  }

  .folding-nav__menu__list__lower__list > a:after{
    right: 30px;
  }
}