@charset "utf-8";
 /***************************

object/project/_becc.scss

***************************/

.p-becc-floor__list__item{
  background-repeat:no-repeat;
  background-size:cover;
  padding:50px 15px;
}

.p-becc-floor__list__item.is-floor3{
  background-image:url(../img/campuslife/becc/bg_floor3.png);
}

.p-becc-floor__list__item.is-floor2{
  background-image:url(../img/campuslife/becc/bg_floor2.png);
}

.p-becc-floor__list__item.is-floor1{
  background-image:url(../img/campuslife/becc/bg_floor1.png);
}

.p-becc-floor__list__item__box{
  padding:20px;
  background:rgba(255,255,255,.9);
}

@include min-tab{
  .p-becc-floor__list__item__box{
    width:50%;
    padding:30px;
  }
}

@include max-tab{
  .p-becc-floor__list__item__box{
    position:relative;
  bottom:-250px;
  }

  .p-becc-floor__list__item{
    margin-bottom:230px;
  }
}

.p-becc-floor__list__item.is-floor2 .p-becc-floor__list__item__box{
  margin-left:auto;
}

.p-becc-salc__slide{
  background:#f4f4f4;
}

.p-becc-salc__slide__item{
  position:relative;
}

.p-becc-salc__slide__item__text{
  background:rgba(255,255,255,.9);
  padding-top:20px;
  padding-bottom:20px;
  width:calc(100% - 60px);
}

@include min-tab{
  .p-becc-salc__slide__item__text{
    position:absolute;
    bottom:20px;
    left:50%;
    transform: translate(-50%);
  }
}

@include max-tab{
  .p-becc-salc__slide__item__text{
    position:relative;
    top:-50px;
  }
}

.p-becc-salc__slide__item__text__heading{
  font-size:24px;
  color:$bunkyo_blue;
  span{
    font-size:18px;
    display:inline-block;
  }
}

.p-becc-salc__heading{
  font-size:20px;
  margin-bottom:10px;
  span{
    font-size:16px;
    display:inline-block;
    margin-left:15px;
  }
}

@include max-tab{
  .p-becc-salc__heading{
    margin-left:0px;
    margin-top:15px;
  }
}

.p-becc-movie__box {
     position: relative;
     padding-bottom: 56.25%;
     height: 0;
     overflow: hidden;
}

.p-becc-movie__box iframe {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
}

.p-becc-sns__item--insta{
  width:calc((100% - 420px) / 2);
  margin-right:40px;
}
.p-becc-sns__item--fb{
  margin-right:40px;
}
.p-becc-sns__item--tw{
  @include flex-item;
  width:calc((100% - 420px) / 2);
  height:500px;
  overflow-y: scroll;
  border:1px solid #ddd;
}

@include min-tab{
  .p-becc-sns{
    @include flexbox;
    @include flex-justify-between;
  }
  .p-becc-sns__item--insta{
    @include flex-item;
  }
  .p-becc-sns__item--fb{
    @include flex-item;
    width:340px;
  }
  .p-becc-sns__item--tw{
    @include flex-item;
  }
}
@media screen and (max-width: 900px){
  .p-becc-sns{
    @include flex-wrap;
  }
  .p-becc-sns__item--insta{
    width:100%;
    margin-right:0px;
    margin-bottom:40px;
  }
  .p-becc-sns__item--tw{
    width:calc(100% - 380px);
  }
}

@include max-tab{
  .p-becc-sns__item--insta{
    width:100%;
  }
  .p-becc-sns__item--fb{
    width:100%;
    margin-right:0px;
    margin-bottom:40px;
    text-align:center;
  }
  .p-becc-sns__item--tw{
    width:100%;
  }
}