@charset "utf-8";
 /***************************

object/component/_list.scss

***************************/
.c-ul-type1__list{
  position:relative;
  padding-left:25px;
  margin-bottom:10px;
}

.c-ul-type1__list:last-of-type{
  margin-bottom:0;
}

.c-ul-type1__list:before{
  content:"";
  position:absolute;
  top:3px;
  left:0;
  width:14px;
  height:14px;
  background:$bunkyo_blue;
}

@include min-tab{
  .c-ul-type1__list.u-flexbox-tab__item2{
    margin-bottom:10px;
  }
  .c-ul-type1__list.u-flexbox-tab__item2:last-of-type,
  .c-ul-type1__list.u-flexbox-tab__item2:nth-last-of-type(2){
    margin-bottom:0px;
  }
}

@include max-tab{
  .c-ul-type1__list.u-flexbox-tab__item2:not(:last-of-type){
    margin-bottom:20px;
  }
}

.c-ul-check__list {
  position: relative;
  padding: 3px 3px 3px 30px;
  -webkit-transition: all .2s;
  transition: all .2s;
}
.c-ul-check__list::before,
.c-ul-check__list::after {
  position: absolute;
  content: '';
}
.c-ul-check__list::before {
  top: 6px;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #3498db;
}
.c-ul-check__list::after {
  top: 6px;
  left: 3px;
  width: 8px;
  height: 4px;
  margin-top: 4px;
  border-left: 2px solid #3498db;
  border-bottom: 2px solid #3498db;
  opacity: 1;
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.c-ol-type1{
  counter-reset: item;
}

.c-ol-type1__list{
  position:relative;
  min-height:32px;
  padding-left:45px;
  padding-top:6px;
  margin-bottom:10px;
}

.c-ol-type1__list:last-of-type{
  margin-bottom:0;
}

.c-ol-type1__list:before{
  counter-increment: item;
  content:counter(item)"";
  color:#fff;
  line-height:32px;
  text-align:center;
  position:absolute;
  top:1px;
  left:0;
  width:32px;
  height:32px;
  @include border-radius(50%);
  background:#222;
}

.c-ol-type2{
  counter-reset: item;
}

.c-ol-type2__list{
  position:relative;
  padding-left:25px;
  padding-top:6px;
  margin-bottom:5px;
}

.c-ol-type2__list:last-of-type{
  margin-bottom:0;
}

.c-ol-type2__list:before{
  counter-increment: item;
  content:"("counter(item)")";
  color:#222;
  line-height:32px;
  text-align:center;
  position:absolute;
  top:1px;
  left:0;
}

.c-ol-type3{
  counter-reset: item;
}

.c-ol-type3__list{
  position:relative;
  padding-left:16px;
  padding-top:6px;
  margin-bottom:5px;
}

.c-ol-type3__list:last-of-type{
  margin-bottom:0;
}

.c-ol-type3__list:before{
  counter-increment: item;
  content:counter(item)".";
  color:#222;
  line-height:32px;
  text-align:center;
  position:absolute;
  top:1px;
  left:0;
}

.c-flexlist-type1__list{
  padding:20px 15px;
  border:1px solid #ddd;
  background:#fff;
}

@include min-tab{
  .c-flexlist-type1{
    @include flexbox;
    @include flex-justify-start;
    @include flex-wrap;
  }

  .c-flexlist-type1__list{
    @include flex-item;
    width:20%;
  }

  .c-flexlist-type1__list:not(:nth-of-type(5n+1)){
    border-left:none;
  }

  .c-flexlist-type1__list:nth-of-type(n+6){
    border-top:none;
  }

  .c-flexlist-type1__list:last-of-type{
    border-right:1px solid #ddd;
  }
}

@include max-tab{
  .c-flexlist-type1__list:nth-of-type(n+2){
    border-top:none;
  }
}


.c-dl-type1__dt{
  font-weight:bold;
  padding:15px 0 0;
}

.c-dl-type1__dd{
  padding:15px 0 15px 15px;
  border-bottom:1px solid #ddd;
}
.c-dl-type1__dt:first-of-type{
  border-top:1px solid #ddd;
}

@include min-tab{
  .c-dl-type1{
    @include flexbox;
    @include flex-align-start;
    @include flex-justify-start;
    @include flex-wrap;
  }

  .c-dl-type1__dt{
    flex:0 0 15em;
    border-bottom:1px solid #ddd;
    padding:15px 0;
  }

  .c-dl-type1__dd{
    @include flex-item;
    width:calc(100% - 15em);
  }

  .c-dl-type1__dd:first-of-type{
    border-top:1px solid #ddd;
  }
}




.c-dl-type2__dt{
  font-weight:bold;
  padding-left:25px;
  margin-bottom:5px;
  position:relative;
}

.c-dl-type2__dt:before{
  content:"";
  position:absolute;
  top:3px;
  left:0;
  width:14px;
  height:14px;
  background:$bunkyo_blue;
}

.c-dl-type2__dd{
  padding-left:30px;
  margin-bottom:15px;
}
.c-dl-type2__dd:last-of-type{
  margin-bottom:0;
}

@include min-tab{
  .c-dl-type2{
    @include flexbox;
    @include flex-align-start;
    @include flex-justify-start;
    @include flex-wrap;
  }

  .c-dl-type2__dt{
    flex:0 0 15em;
    width:15em;
    margin-bottom:15px;
  }

  .c-dl-type2__dd{
    @include flex-item;
    width:calc(100% - 15em - 30px);
    padding-left:15px;
  }

  .c-dl-type2__dt:last-of-type{
    margin-bottom:0;
  }

}