@charset "utf-8";
 /***************************

object/component/_button.scss

***************************/
a{
  color:$bunkyo_blue;
  text-decoration:underline;
  display:inline;
}

a:hover{
  text-decoration:none;
}

.c-button-type1{
  min-width: 320px;
  width: auto;
  text-align: center;
  padding:20px 50px 20px 50px;
  font-size: 14px;
  font-weight: bold;
  position:relative;
  display:inline-block;
  color:#FFF;
  background: $bunkyo_blue;
  text-decoration:none;
}

.c-button-type1:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  width:32px;
  height:32px;
  text-align:center;
  line-height:32px;
  border: 1px solid #FFF;
  @include border-radius(50%);
  position:absolute;
  top: calc(50% - 16px);
  right: 15px;
}

.c-button-type1:hover{
  background:#143C70;
  opacity:1;
  cursor:pointer;
}

.c-button-type1.is-inactive,
.c-button-type1.is-inactive:hover{
  color:#333;
  background: #ccc;
  pointer-events:none;
}

.c-button-type1.is-inactive:after{
  color:#FFF;
}

.c-button-type2{
  min-width: 320px;
  width: auto;
  text-align: center;
  padding:20px 50px 20px 50px;
  font-size: 14px;
  font-weight: bold;
  position:relative;
  display:inline-block;
  color:#FFF;
  background: $bunkyo_blue;
  text-decoration:none;
  margin-bottom:15px;

}

.c-button-type2:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  width:32px;
  height:32px;
  text-align:center;
  line-height:32px;
  position:absolute;
  top: calc(50% - 16px);
  right: 15px;
}

.c-button-type2:hover{
  background:#143C70;
  opacity:1;
  cursor:pointer;
}

.c-button-type2.is-inactive,
.c-button-type2.is-inactive:hover{
  color:#333;
  background: #ccc;
  pointer-events:none;
}

.c-button-type2.is-inactive:after{
  color:#FFF;
}
@include min-sp{
  .c-button-type2{
    br{
      display:none;
    }
  }
}
@include max-sp{
  .c-button-type1,
  .c-button-type2{
    min-width:initial;
    min-width:auto;
    width:100%;
  }
  .c-button-type2{
    padding: 20px 30px 20px 10px;
  }
  .c-button-type2:after{
    right:3px;
  }
}
.c-button-type3{
  font-size:18px;
  color:#000;
  text-decoration:none;
  font-weight:bold;
}

.c-button-type3:hover{
  text-decoration:underline;
  color:$bunkyo_blue;
  cursor:pointer;
}

.c-button-type3:before{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  padding: 5px 9px;
  margin-right:15px;
  color:#FFF;
  background-color:$bunkyo_blue;
  text-decoration:none;
  transition: 0.2s ease;
}

.c-button-type3:hover:before{
  margin-right:10px;
  margin-left:5px;
}

.c-link-type1{
  padding-left:21px;
  position:relative;
  display:inline-block;
  width:auto;
  text-decoration:none;
  color:#000;
}

.c-link-type1:before{
  content:"";
  position:absolute;
  top:calc(50% - 3px);
  left: 5px;
  width: 4px;
  height: 4px;
  border-top:1px solid #1F1813;
  border-right:1px solid #1F1813;
  @include transform-rotate(45deg);
}

.c-link-type2{
  padding-left:21px;
  position:relative;
  display:inline-block;
  width:auto;
  text-decoration:none;
  color:#000;
}

.c-link-type2:before{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  color:$bunkyo_blue;
  position: absolute;
  top:calc(50% - 11px);
  left: 5px;
}

.c-link-type3{
  padding-left:21px;
  position:relative;
  display:inline-block;
  width:auto;
  line-height:24px;
}

.c-link-type3:before{
  font-family: Font Awesome\ 5 Free;
  content:"\f105";
  font-weight:bold;
  color:$bunkyo_blue;
  position: absolute;
  top:calc(50% - 12px);
  left: 5px;
}

.c-link-pdf{
//  margin-right:15px;
}

.c-link-pdf:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f1c1";
  font-style: normal;
  font-variant: normal;
  font-size:16px;
  color:#AA0000;
  margin-left:5px;
}

.c-link-word{
//  margin-right:15px;
}

.c-link-word:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f1c2";
  font-style: normal;
  font-variant: normal;
  font-size:16px;
  color:#2C579B;
  margin-left:5px;
}

.c-link-excel{
//  margin-right:15px;
}

.c-link-excel:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f1c3";
  font-style: normal;
  font-variant: normal;
  font-size:16px;
  color:#107c10;
  margin-left:5px;
}

.c-link-external{
  //margin-right:15px;
}

.c-link-external:after{
  font-family: Font Awesome\ 5 Free;
  content:"\f35d";
  font-style: normal;
  font-variant: normal;
  font-weight:bold;
  font-size:12px;
  color:$bunkyo_blue;
  margin-left:3px;
}

@include min-tab{
    .c-tel-link{
      pointer-events:none;
      color:inherit;
      text-decoration:none;
    }
}