@charset "utf-8";
 /***************************

object/project/_cumpasmap.scss

***************************/

.p-campusmap-ul{

}

.p-campusmap-ul__list{
  padding:15px;
  border: 1px solid #ddd;
  margin-bottom:30px;
}

.p-campusmap-ul__list__heading{
  margin-top:10px;
  margin-bottom:5px;
  color:$bunkyo_blue;
  span{
    color:#fff;
    background:$bunkyo_blue;
    padding:5px 0;
    width:36px;
    display:inline-block;
    margin-right:10px;
    text-align:center;
  }
}

@include min-tab{
  .p-campusmap-ul{
    @include flexbox;
    @include flex-justify-start;
    @include flex-wrap;
  }

  .p-campusmap-ul__list{
    @include flex-item;
    width:calc((100% - 80px) / 3);
    margin: 0 40px 40px 0;

  }

  .p-campusmap-ul__list.is-2column{
    width:calc((100% - 40px) / 2);
  }
}

@include max-tab{
  .p-campusmap-map{
    width:800px;
  }
  .p-campusmap-ul__list__heading{
    text-align:left;
    span{
      text-align:center;
    }
  }
}