@charset "utf-8";



/***************************

object/project/_mikikai.scss

***************************/

.p-mikikai-about {

    .p-mikikai-about__flexbox {
        .p-mikikai-about__flexbox__pic {
            width: 40%;
            padding-right: 5%;
            line-height: 1.4;
        }

        .p-mikikai-about__flexbox__text {
            width: 60%;
        }
    }
}

.p-mikikai-history {

    .p-mikikai-history__table {
        width: 100%;
        .p-mikikai-history__table__th {
            width: 10%;
            min-width: 150px;
            font-weight: normal;
            padding: 10px;
            border: 1px solid #ddd;
        }

        .p-mikikai-history__table__td {
            width: 90%;
            padding: 10px;
            border: 1px solid #ddd;
        }
    }
}

@media screen and (min-width: 769px) {

    .p-mikikai-history {

        .p-mikikai-history__table {

            tr {
                &:nth-child(odd) {
                    .p-mikikai-history__table__th,
                    .p-mikikai-history__table__td {
                        background-color: #eee;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {

    .p-mikikai-about {

        .p-mikikai-about__flexbox {
            display: block;

            .p-mikikai-about__flexbox__pic {
                width: 100%;
                margin: 0 auto 20px auto;
                padding-right: 0;
            }

            .p-mikikai-about__flexbox__text {
                width: 100%;
            }
        }
    }

    .p-mikikai-history {

        .p-mikikai-history__table {
            width: 100%;
            border-bottom: 1px solid #ddd;

            .p-mikikai-history__table__th {
                display: block;
                width: 100%;
                text-align: left;
                background-color: #eee;
            }

            .p-mikikai-history__table__td {
                display: block;
                width: 100%;
                border-top: none;
                border-bottom: none;
                background-color: #fff;
            }
        }
    }
}