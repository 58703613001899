@charset "utf-8";
 /***************************

object/component/_box.scss

***************************/
.c-container{
  max-width: $contents_width + 30;
  margin: 0 auto;
  padding-right:15px;
  padding-left:15px;
}

@media screen and (min-width: 1101px) and (max-width: 1460px){
  .c-container{
    padding-right:90px;
  }
}

.c-box--gray{
  padding:15px 20px;
  background:#f4f4f4;
  border:1px solid #DEDCDD;
}

.c-box--blue{
  padding:15px 20px;
  background:#EDF2F8;
  border:1px solid #CEDAE6;
}

@include max-tab{
  .c-box--gray,
  .c-box--blue{
    padding:10px;
  }
}