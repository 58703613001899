@charset "utf-8";
/***************************

foundation/base.scss

***************************/
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    word-break: break-all;
    word-wrap: break-word;
}

html {
    overflow:auto;
    font-size: 10px;
    -webkit-tap-highlight-color: transparent;
    font-family: sans-serif;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    overflow: hidden;
    font-size: 14px;
    color: #000;
    font-family: "游ゴシック", sans-serif;
    font-weight: 500;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    width: 100%;
}

a {
    color: #000;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
//    transition: 1s;
//    -moz-transition: 1s;
//    -webkit-transition: 1s;
//    -o-transition: 1s;
//    -ms-transition: 1s;

    display:block;
    width:100%;
    height:100%;
}

a:hover {
//    color: #000;
    text-decoration: none;
    opacity: 0.75;
}

a:active,
a:hover {
    outline-width: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

p {
    margin: 0;
    line-height:1.8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h3,
h4{
    text-align:center;
}

dl,
dt,
dd{
    margin: 0;
    padding: 0;
}


figure {
    margin: 0
}

figcaption,
figure,
main {
    display: block;
}

article,
aside,
footer,
header,
nav,
section {
    display: block;
}

pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

img {
    border-style: none;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

::-webkit-file-upload-button {
    -webkit-appearance: none;
    font: inherit;
}

[hidden] {
    display: none;
}

address {
    font-style: normal;
}

figure {
    margin: 0;
}

table{
    border-collapse: collapse;
}