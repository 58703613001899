@charset "utf-8";
 /***************************

object/project/_briefing.scss

***************************/

.p-briefing-button{
  border:none;
  color:#FFF;
  padding:10px 15px;
  margin:0 10px;
  background:$bunkyo_blue;
}

@include max-tab{
  .p-briefing-button{
    display:block;
    margin:10px 0;
  }
}