@charset "utf-8";



/***************************

object/project/_jinseiron.scss

***************************/

.p-jinseiron {

    .p-jinseiron__sub-title {
        font-size: 28px;
        font-weight: normal;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .p-jinseiron__table--wrap {
        overflow-x: auto;
        margin-bottom: 60px;
    }

    .p-jinseiron__table {
        width: 1100px;

        .p-jinseiron__table__times,
        .p-jinseiron__table__day,
        .p-jinseiron__table__talk {
            border: 1px solid #ddd;
            vertical-align: middle;
            padding: 15px;
        }

        .p-jinseiron__table__times {
            width: 100px;
            text-align: center;
        }

        .p-jinseiron__table__day {
            width: 205px;
            text-align: center;
        }

        .p-jinseiron__table__talk {
            width: 700px;
        }

        thead {
            background-color: #f4f4f4;

            .p-jinseiron__table__times,
            .p-jinseiron__table__day,
            .p-jinseiron__table__talk {
                padding: 10px 15px;
            }
        }
    }

}

@include min-tab{
.p-jinseiron_event.is-second{
    @include flexbox;
    @include flex-wrap;
    .p-home-event__list__item{
        @include flex-item;
        width:calc((100% - 120px) / 4);
    }

    .p-home-event__list__item:not(:nth-of-type(4n)){
        margin-right:40px;
    }
}
.p-jinseiron_event.p-home-event__list{
    width:100%;
}
}

@include max-sp{

.p-jinseiron {

    .p-jinseiron__sub-title {
        text-align: left;
        br{
            display: none;
        }
    }
}

}