@charset "utf-8";
 /***************************

layout/_flex-img-text.scss

***************************/

.l-flex-img-text{

}

.l-flex-img-text__img{
}

.l-flex-img-text__text{

}

@include min-tab{
  .l-flex-img-text{
    @include flexbox;
    @include flex-align-start;
    @include flex-justify-between;
  }
  .l-flex-img-text.is-reverse{
    @include flex-row-rev;
  }

  .l-flex-img-text__img{
    @include flex-item;
  }

  .l-flex-img-text__img.is-flex0{
    flex:0 0 auto;
  }

  .l-flex-img-text__text{
    @include flex-item;
  }
}