@charset "utf-8";
 /***************************

layout/_search.scss

***************************/

.l-lower-list__item{
  background-color:#F4F4F4;
  padding:10px 10px 15px 10px;
  img{
    border:1px solid #DEDCDD;
    margin-bottom:15px;
  }
  > a{
    text-decoration:none;
    color:inherit;
  }
  > a:hover{
    > *{
      opacity:0.75;
    }
  }
}

@include min-tab{
  .l-lower-list{
    @include flexbox;
    @include flex-justify-between;
    @include flex-wrap;
  }

  .l-lower-list__item{
    @include flex-item;
    width:calc((100% - 40px) / 2);
    margin-bottom:45px;
  }

  .l-lower-list__item:nth-of-type(2n+1){
    margin-right:40px;
  }
}

@include max-tab{
  .l-lower-list__item{
    margin-bottom:40px;
    img{
      width:100%;
    }
  }
}