@charset "utf-8";
 /***************************

object/project/_schoolbus.scss

***************************/

.p-schoolbus-normal__table__th{
  width:25%;
}
.is-6column .p-schoolbus-normal__table__th{
  width:calc(100% / 6);
}
.is-5column .p-schoolbus-normal__table__th{
  width:calc(100% / 5);
}

.p-schoolbus-normal__table2__th{
  width:50%;
}

@include min-tab{
  .p-schoolbus-normal__table2{
    width:50%;
  }
}

@include max-tab{
  .p-schoolbus-normal__table{
    width:650px;
  }

  .p-schoolbus-normal__table2{
    width:100%;
  }

}

.p-schoolbus-calendar__table{
  background: #fff;
  border-collapse: collapse;
  width: 100%;
}

.p-schoolbus-calendar__table__th{
  width:calc(100% / 7);
  background: #7d7d7d;
  font-weight: bold;
  color: #fff;
  border: 1px solid #ddd;
  padding: 15px 5px;
}

.p-schoolbus-calendar__table__td{
  border: 1px solid #ddd;
  padding: 5px 10px 10px;
  vertical-align: top;
  min-height:90px;
}

.p-schoolbus-calendar__table__td.other{
  background-color:#eee;
}

.p-schoolbus-calendar__table__td.today{
  border:2px solid $bunkyo_blue;
}

.p-schoolbus-calendar__table__td__date{
  font-weight:bold;
}

.other .p-schoolbus-calendar__table__td__date{
  color:#7d7d7d;
}

.p-schoolbus-calendar__table__td__tag{
  a{
    @include border-radius(3px);
    text-align:center;
    display:block;
    text-decoration:none;
    font-weight:bold;
  }
}

.p-schoolbus-calendar__table__td__tag.off a{
  background-color:#d1dde9;
  color:$bunkyo_blue;
  pointer-events:none;
}

.p-schoolbus-calendar__table__td__tag.special{
  a{
    background-color:$bunkyo_blue;
    color:#fff;
  }
  a:after{
    font-family: Font Awesome\ 5 Free;
    content: "\f1c1";
    font-style: normal;
    font-variant: normal;
    font-size: 14px;
    color: #fff;
    margin-left: 5px;
  }
}

.p-schoolbus-calendar__table__td__tag:nth-of-type(n+3){
  margin-top:-25px;
}
.p-schoolbus-calendar__table__td__tag + .p-schoolbus-calendar__table__td__tag:last-of-type{
  margin-bottom:0;
}

@include max-sp{
  .p-schoolbus-calendar{
    padding-left:0;
    padding-right:0;
  }

  .p-schoolbus-calendar__table__td{
    padding: 5px 2px 5px;
  }
  .p-schoolbus-calendar__table__td__tag a{
    font-size:12px;
  }
}

.p-schoolbus-calendar__button{
  text-align:right;
  margin-bottom:30px;
  margin-top:-33px;
}

.p-schoolbus-calendar__button__before,
.p-schoolbus-calendar__button__next{
  display:inline-block;
  a{
    background:#eee;
    padding:15px 20px;
    text-decoration:none;
  }
  a:before{
    font-family: Font Awesome\ 5 Free;
    font-weight: bold;
    color: #000;
    font-size:16px;
  }
}

.p-schoolbus-calendar__button__before a:before{
  content:"\f104";
}

.p-schoolbus-calendar__button__next a:before{
  content: "\f105";
}