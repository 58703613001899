@charset "utf-8";
 /***************************

layout/_news-list.scss

***************************/

.l-news-list__item{
  border:1px solid #ddd;

  a{
    @include flexbox;
    @include flex-align-start;
    @include flex-justify-start;
    text-decoration:none;
    color:#000;
    padding:20px 25px;

  }
}
.l-news-list__item:not(:last-of-type){
  border-bottom:none;
}

.l-news-list__item__cat{
  flex:0 0 135px;
  text-align:center;
  background:$bunkyo_blue;
  padding:2px;
  color:#fff;
  margin-right:35px;
  width:135px;
  font-size:12px;
}

.l-news-list__item__cat.important{
  background:#C2191F;
}
.l-news-list__item__cat.press,
.p-home-event__list__item__tag.press{
  background:#C1964E;
}
.l-news-list__item__cat.center,
.l-news-list__item__cat.career,
.l-news-list__item__cat.support,
.p-home-event__list__item__tag.center,
.p-home-event__list__item__tag.career,
.p-home-event__list__item__tag.support{
  background:#16345D;
}
.l-news-list__item__cat.current,
.p-home-event__list__item__tag.current{
  background:#C6994F;
}

.l-news-list__item__cat.education,
.l-news-list__item__cat.primary,
.l-news-list__item__cat.secondary,
.p-home-event__list__item__tag.primary,
.p-home-event__list__item__tag.secondary{
  background:#E94F50;
}

.l-news-list__item__cat.welfare,
.p-home-event__list__item__tag.welfare{
  background:#20B080;
}

.l-news-list__item__cat.psychology,
.p-home-event__list__item__tag.psychology{
  background:#8372B2;
}

.l-news-list__item__cat.nutrition,
.p-home-event__list__item__tag.nutrition{
  background:#F29019;
}

.l-news-list__item__cat.global,
.p-home-event__list__item__tag.gc,
.p-home-event__list__item__tag.global{
  background:#1393B4;
}

.l-news-list__item__cat.training,
.p-home-event__list__item__tag.training{
  background:#2F8E75;
}

.l-news-list__item__cat.becc,
.p-home-event__list__item__tag.becc,
.l-news-list__item__cat.salc,
.p-home-event__list__item__tag.salc{
  background:#EB5902;
}

.l-news-list__item__cat.jinseiron,
.p-home-event__list__item__tag.jinseiron{
  background:#34563f;
}

.l-news-list__item__cat.admission{
  background:#D2135D;
}

.l-news-list__item__cat.exam,
.p-home-event__list__item__tag.exam{
  background:#D2135D;
}

.l-news-list__item__cat.student,
.p-home-event__list__item__tag.student{
  background:#6CA82F;
}

.l-news-list__item__cat.mikikai,
.p-home-event__list__item__tag.mikikai{
  background:#417953;
}
@include max-sp{
  .l-news-list__item__cat{
    margin-right:20px;
  }
}

.l-news-list__item__date{
  flex:0 0 auto;
  color:$bunkyo_blue;
}

.l-news-list__item__ttl{
  @include flex-item;
}

@include min-tab{
  .l-news-list__item__date{
    margin-right:35px;
  }
}

@include max-tab{
  .l-news-list__item a{
    @include flex-wrap;
  }

  .l-news-list__item__ttl{
    width:100%;
  }
}

.l-news-list.is-important{
  background:#FFF7F8;
  margin-bottom:10px;
  .l-news-list__item{
    padding:0;
    border:none;
    a{
      padding:20px 25px;
    }
  }

  .l-news-list__item__date{
    color:#CF0110;
//    margin-right:35px;
  }

  .l-news-list__item__ttl{
    color:#CF0110;
  }

}