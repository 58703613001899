@charset "utf-8";
 /***************************

object/project/_organization.scss

***************************/

.p-organization-system{
  text-align:center;
}

@include max-tab{
  .p-organization-system{
    width:600px;
  }
}

.p-organization-department{

}

.p-organization-department__education,
.p-organization-department__human{
  margin-bottom:40px;
}

.p-organization-department__course__item{
  margin-bottom:20px;
}

.p-organization-department__univercity{
  margin-bottom:30px;
}

.p-organization-department__univercity__heading{
  font-size:24px;
}

.p-organization-department__department{
  font-size:20px;
  text-align:center;
  margin-bottom:15px;
}

.p-organization-department__course__heading{
  font-size:18px;
  background:#fff;
  padding:10px;
  color:$bunkyo_blue;
  margin-bottom:15px;
  text-align:center;
}

@include min-tab{
  .p-organization-department__education{
    @include flex-item;
    width:calc((100% - 40px) / 3);
    margin-right:40px;
  }
  .p-organization-department__human{
    @include flex-item;
    width:calc((100% - 40px) / 3 * 2);
  }
}

@include max-tab{
  .p-organization-department__course__heading{
  padding:10px 5px;
  }
}