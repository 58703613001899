@charset "utf-8";


/***************************

object/project/_dormitory.scss

***************************/

.p-dormitory {

    .appeal-list {
        .appeal-list__item {
            margin-bottom: 80px;
            .appeal-list__item__title {
                margin-top: 10px;
                margin-bottom: 15px;
                min-height: 90px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                font-size: 16px;
                text-align: left;

                .appeal-list__item__title__icon {
                    background-color: #1e56a3;
                    width: 60px;
                    height: 60px;
                    min-width: 60px;
                    min-height: 60px;
                    margin: 20px 40px 20px 20px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    text-align: center;
                    font-size: 16px;
                    color: #fff;
                    transform: rotate(-45deg);
                    span {
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    .p-dormitory__system-table {
        .c-table-type1__th {
            padding: 15px 5px;
        }
        .c-table-type1__td {
            padding: 15px 10px;
            text-align: left;
        }
        .c-table-type1__td.center {
            text-align: center;
        }
    }

    .p-dormitory__overview-table {
        .c-table-type1__td {
            text-align: left;
        }
    }

    .p-dormitory__voice {
        margin-bottom: 50px;

        .p-dormitory__voice__box {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 20px;
            padding: 35px 25px;
            background: #EDF2F8;
            border: 1px solid #CEDAE6;

            .p-dormitory__voice__box__pic {
                width: 9%;
                min-width: 100px;
                margin-right: 3%;
            }

            .p-dormitory__voice__box__text {
                width: 88%;
                line-height: 1.8;
                .p-dormitory__voice__box__text__title {
                    font-size: 20px;
                    text-align: left;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
@include min-tab{
    .p-dormitory__system-table{
        tr:nth-of-type(1){
            .c-table-type1__th:nth-of-type(1){
                width:18%;
            }
            .c-table-type1__th:nth-of-type(2){
                width:24%;
            }
            .c-table-type1__th:nth-of-type(3){
                width:40%;
            }
            .c-table-type1__th:nth-of-type(4){
                width:18%;
            }
        }
    }
}

@include max-tab {

    .p-dormitory {
        .appeal-list {
            margin-bottom: 100px;

            .appeal-list__item {
                .appeal-list__item__title {
                    .appeal-list__item__title__icon {
                        margin: 20px 20px 20px 20px;
                    }
                }
            }
        }

        .p-dormitory__voice {

            .p-dormitory__voice__box {
                display: block;
                .p-dormitory__voice__box__pic {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 40px;
                    text-align: center;
                }

                .p-dormitory__voice__box__text {
                    width: 100%;
                }
            }
        }
    }
}