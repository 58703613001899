@charset "utf-8";
 /***************************

layout/_overflow.scss

***************************/

@media screen and (max-width: 1450px){
  .l-scroll-x{
    overflow-x:scroll;
  }
}


@include max-pc{
  .l-scroll-x-pc{
    overflow-x:scroll;
  }
}

@include max-tab{
  .l-scroll-x-tab{
    overflow-x:scroll;
  }

  .l-scroll-x-tab__table{
    width:1100px;
  }
}

@include max-sp{
  .l-scroll-x-sp{
    overflow-x:scroll;
  }
}