@charset "utf-8";

/***************************

object/project/_admission.scss

***************************/

.p-admission {
    .p-admission__schedule {
        width: 100%;
        border-collapse: inherit;
        border-spacing: 0 20px;
        .p-admission__schedule__th,
        .p-admission__schedule__td {
            text-align: left;
        }
    }
    .p-admission__faq {
        width: 100%;
        border-top: 1px solid #ddd;
        .p-admission__faq__th,
        .p-admission__faq__td {
            border-top: none;
            width: 100%;
            text-align: left;
            display: block;
        }
    }
}

@include min-sp{
    .p-admission__schedule__th{
        width: 30%;
        min-width: 200px;
    }
}
@include max-sp {

    .p-admission {
        .p-admission__schedule {
            .p-admission__schedule__th,
            .p-admission__schedule__td {
                display: block;
                width: 100%;
            }
        }
        .p-admission__faq {
            width: 100%;
        }
    }
}