@charset "utf-8";
// background-image base-path

//accent color
$accent: #2892B7;

//bunkyo blue
$bunkyo_blue: #1e56a3;

//contents width
$contents_width: 1280px;