@charset "utf-8";
 /***************************

object/component/_checkbox.scss

***************************/

.c-checkbox01 input[type="checkbox"] {
  display: none;
}
.c-checkbox01 label {
  position: relative;
  display: inline-block;
  padding: 3px 3px 3px 22px;
  cursor: pointer;
  -webkit-transition: all .2s;
  transition: all .2s;
  margin-right:10px;
}
.c-checkbox01 label::before,
.c-checkbox01 label::after {
  position: absolute;
  content: '';
  -webkit-transition: all .2s;
  transition: all .2s;
}
.c-checkbox01 label::before {
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -8px;
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.c-checkbox01 label::after {
  opacity: 0;
  top: 50%;
  left: 3px;
  width: 8px;
  height: 4px;
  margin-top: -4px;
  border-left: 2px solid #3498db;
  border-bottom: 2px solid #3498db;
  -webkit-transform: rotate(-45deg) scale(.5);
  transform: rotate(-45deg) scale(.5);
}
.c-checkbox01 label:hover::before {
  background: #fff;
}
.c-checkbox01 input[type="checkbox"]:checked + label::before {
  background: #fff;
  border: 1px solid #3498db;
}
.c-checkbox01 input[type="checkbox"]:checked + label::after {
  opacity: 1;
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}