@charset "utf-8";

/***************************

object/project/_ttc.scss

***************************/

.p-licentiate {
    .p-licentiate__table {

        .p-licentiate__table__th--department {
//            width: 2%;
//            border: 1px solid #ddd;
            background: #7d7d7d;
            color: #fff;
            font-size: 16px;
            white-space: nowrap;

            span{
                -webkit-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                //text-orientation: upright;
            }
        }
        .p-licentiate__table__th--course {
//            width: 28%;
//            border: 1px solid #ddd;
            background: #7d7d7d;
            color: #fff;
            font-size: 16px;
        }
        .p-licentiate__table__th--qualification {
//            width: 70%;
            padding: 15px 15px;
//            border: 1px solid #ddd;
            background: #7d7d7d;
            color: #fff;
            font-size: 16px;
        }
        .p-licentiate__table__th--list {
            vertical-align: bottom;
            padding: 60px 5px 5px 5px;
//            border: 1px solid #ddd;
            text-align:center;
            white-space: nowrap;
            max-width:32px;
            min-width:32px;

            &:nth-child(odd){
            	background: #eee;
            }
            span{
                -webkit-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                //text-orientation: upright;
            }
        }

        .p-licentiate__table__td--catergory{
        	background-color: #eee;
        	font-size: 16px;
        	font-weight: bold;
            white-space: nowrap;

            span{
                -webkit-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                //text-orientation: upright;
            }
        }
        .p-licentiate__table__td--subcatergory{
//        	width: 5%;
        	font-size: 16px;
        	font-weight: bold;
            white-space: nowrap;

            span{
                -webkit-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                //text-orientation: upright;
            }
        }

        .p-licentiate__table__td--further-subcategory{
        	//width: 95%;
        	//min-width: 95%;
        	font-size: 16px;
        	font-weight: bold;
        }

        [class^="p-licentiate__table__td--check"]{
        	text-align: center;
        	vertical-align: middle;
        	color: #1e56a3;
        	padding: 5px;

        	.note{
        		font-size: 10px;
        		color: #000;
        		line-height: 1.2;
        	}
        }

        .p-licentiate__table__td--check-gray{
        	background-color: #eee;
        }

        [class^="p-licentiate__table__td--"]{
//        	border: 1px solid #ddd;
        }
    }
}



.p-licentiate__table {
    margin-bottom: 10px;
    line-height: 1.5;

    display: block;
    position: relative;
    overflow-x: scroll;
    border-collapse: separate;
    border-spacing: 0;
    width:100%;
    border-left:1px solid #ddd;
    thead {
        display: table-header-group;
    }
    tbody {
        display: block;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        display: table-row-group;
    }
    tr{
        display: table-row;
    }
}
[class^="p-licentiate__table__th--"],
[class^="p-licentiate__table__td--"]{
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.p-licentiate__table__th--department,
.p-licentiate__table__th--course,
.p-licentiate__table__td--catergory,
.p-licentiate__table__td--subcatergory,
.p-licentiate__table__td--further-subcategory{
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
}
.p-licentiate__table__th--department{
    padding: 15px 30px;
    width:85px;
    min-width:85px;
    max-width:85px;
}

.p-licentiate__table__td--catergory{
    padding: 15px 30px;
    width:85px;
    min-width:85px;
    max-width:85px;
}

.p-licentiate__table__th--course{
    padding: 15px 30px;
    width:300px;
    min-width:300px;
    left:85px;
}
.p-licentiate__table__td--subcatergory{
    padding: 15px 30px;
    width:85px;
    max-width:85px;
    left:85px;
    background:#fff;
}

.p-licentiate__table__td--further-subcategory{
    padding: 15px 30px;
    width:300px;
    min-width:300px;
    left:85px;
    background:#fff;
}

.p-licentiate__table__td--further-subcategory.is-sub{
    width:215px;
    min-width:215px;
    left:170px;
}

@include max-tab{
    .p-licentiate__table__th--department{
        padding: 15px 5px;
        width:35px;
        min-width:35px;
        max-width:35px;
    }
    .p-licentiate__table__td--catergory{
        padding: 15px 5px;
        width:35px;
        min-width:35px;
        max-width:35px;
    }

    .p-licentiate__table__th--course{
        width:130px;
        min-width:130px;
        left:35px;
    }
    .p-licentiate__table__td--subcatergory{
        padding: 15px 5px;
        width:35px;
        min-width:35px;
        max-width:35px;
        left:35px;
    }

    .p-licentiate__table__td--further-subcategory{
        width:130px;
        min-width:130px;
        left:35px;
    }

    .p-licentiate__table__td--further-subcategory.is-sub{
        width:95px;
        min-width:95px;
        left:70px;
    }
}

@include max-sp{
    .p-licentiate__table__th--course{
        padding:15px 5px;
        width:100px;
        min-width:100px;
    }

    .p-licentiate__table__td--further-subcategory{
        padding:15px 5px;
        width:100px;
        min-width:100px;
    }

    .p-licentiate__table__td--further-subcategory.is-sub{
        width:95px;
    }
}




@supports (-ms-ime-align: auto) {
  @include max-tab{
    .p-licentiate__table{
      tbody {
        width:$contents_width;
      }
    }
  }
}


@-moz-document url-prefix(){
    .p-licentiate__table__th--department {
        span{
            width:23px;
        }
    }
    .p-licentiate__table__td--catergory{
        span{
            width:23px;
        }
    }

}