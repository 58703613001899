@charset "utf-8";
 /***************************

layout/_side.scss

***************************/
.l-side-nav{
  position:fixed;
  top:calc(50% - 220px);
  right:0;
  max-width:80px;
  z-index:10;
  a{
    display:block;
  }
}

.l-side-nav__list-exam{
  margin-bottom:1px;
}

.l-side-nav__list-exam a{
  background:$bunkyo_blue;
  color:#fff;
  line-height:17px;
  padding:25px 27px;
  text-align: center;
  text-decoration:none;
}

.l-side-nav__list-exam img{
  margin-bottom:10px;
}

.l-side-nav__list-sns{
  background:#fff;
  padding:15px;
}

.l-side-nav__list-sns:nth-of-type(3){
  padding-top:30px;
}

.l-side-nav__list-sns:last-of-type{
  padding-bottom:30px;
}

.l-side-nav__list-sns a{
  text-align: center;
  font-size:24px;
}

.l-side-nav__list-sns .fa-youtube{
  color:#FF0000;
}

.l-side-nav__list-sns .fa-line{
  color:#00B900;
}

.l-side-nav__list-sns img{
  width:24px;
}

.l-side-search{
  @include flexbox;
  @include flex-align-center;
  background:#000;
  width:400px;
  position:fixed;
  top:calc(50% - 301px);
  right:0;
  transition: all 0.2s;
  transform: translate(320px);
  z-index:10;
}

  .l-side-search.open{
    transform: translate(0);
  }

.l-side-search__toggle{
  flex: 0 0 auto;
  color:#fff;
  font-size: 30px;
  background:#000;
  width:80px;
  height:80px;
  text-align:center;
  padding:15px;
  cursor:pointer;
}

.l-side-search .l-search{
  @include flex-item;
  width:100%;
}

@include max-pc{
  .l-side-nav{
    display:none;
  }
  .l-side-search{
    top:0;
    right:80px;
  }
}

@include max-tab{
  .l-side-search{
    right:60px;
    transform: translate(calc(100% - 60px));
    width:calc(100% - 60px);
  }
  .l-side-search.open{
    right:60px;
    transform: translate(0);
  }
  .l-side-search__toggle{
    width:60px;
    height:60px;
    padding:5px;
  }
  .l-side-search .l-search{
    padding:10px 15px;
    height:60px;
  }
}

@media screen and (max-height: 901px){
  .l-side-nav__list-sns{
    padding:10px;
  }
  .l-side-nav__list-sns:nth-of-type(3){
    padding-top: 15px;
  }
  .l-side-nav__list-sns:nth-of-type(5){
    padding-bottom: 15px;
  }
}

@media screen and (max-height: 818px){
  .l-side-search {
      top: 0;
      right: 80px;
  }
  .l-side-nav {
      top: 81px;
  }
}

@include max-tab{
  .l-side-search{
    right:60px;
    transform: translate(calc(100% - 60px));
    width:calc(100% - 60px);
  }
}

@media screen and (max-height: 700px){
  .l-side-nav__list-sns{
    display:none;
  }
}