@charset "utf-8";
 /***************************

object/component/_info-side.scss

***************************/

.l-info-side{
  @include flexbox;
  @include flex-align-start;
  @include flex-justify-between;
  .l-info-side__contents{
    width: 100%;
    margin-right: 30px;
    .p-home-event__list.is-second{
      width: 100%;
      .p-home-event__list__item{
        width: calc(33.3% - 27px);
        margin-right: 40px;
        &:nth-child(3n){
          margin-right: 0;
        }
      }
    }
    .p-home-report__list{
      @include flexbox;
      @include flex-wrap;
      .p-home-report__list__item{
        width: calc(33.3% - 27px);
        margin: 0 40px 40px 0;
        padding: 0;
        &:nth-child(3n){
          margin-right: 0;
        }
        .p-home-report__list__item__img{
          text-align: center;
        }
      }
    }
  }
  .l-info-side__list{
    width: 250px;
    -webkit-box-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    .l-info-side__list__item{
      margin-bottom: 4px;
      .l-info-side__list__item__link{
        display: block;
        padding: 15px 15px 15px 40px;
        background-color: #f4f4f4;
        &::before{
          left: 25px;
        }
      }
    }
    .l-info-side__list__item.is-heading{
      text-align: center;
      padding: 15px;
      font-weight: bold;
      color: #FFF;
      @include bunkyo_blue_bkg;
    }
  }
}

@include max-pc{

  .l-info-side{
    .l-info-side__contents{
      .p-home-event__list.is-second{
        .p-home-event__list__item{
          width: calc(50% - 20px);
          &:nth-child(3n){
            margin-right: 40px;
          }
          &:nth-child(2n){
            margin-right: 0;
          }
        }
      }
      .p-home-report__list{
        .p-home-report__list__item{
          width: calc(50% - 20px);
          &:nth-child(3n){
            margin-right: 40px;
          }
          &:nth-child(2n){
            margin-right: 0;
          }
        }
      }
    }
  }

}

@include max-tab{
  .l-info-side{
    display: block;
    .l-info-side__contents{
      margin-right: 0;
      .p-home-event__list.is-second{
        .p-home-event__list__item{
          width: 100%;
          margin-right: 0;
          &:nth-child(3n){
            margin-right: 0;
          }
        }
      }
      .p-home-report__list{
        .p-home-report__list__item{
          width: 100%;
          margin-right: 0;
          margin-bottom: 0;
          &:nth-child(3n){
            margin-right: 0;
          }
        }
      }
    }
    .l-info-side__list{
      width: 100%;
      margin-bottom: 50px;
      @include flexbox;
      @include flex-justify-between;
      @include flex-wrap;
      .l-info-side__list__item{
        width: calc(50% - 2px);
      }
      .l-info-side__list__item.is-heading{
        width: 100%;
      }
    }
  }
}

@include max-sp{
  .l-info-side{
    .l-info-side__list{
      .l-info-side__list__item{
        width: 100%;
      }
    }
  }
}

