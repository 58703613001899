@charset "utf-8";
 /***************************

object/project/_spirit.scss

***************************/

.p-spirit-heading{
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 40px;
  margin-top: 40px;
  text-align:center;
}

.p-spirit-text{
  font-size:16px;
  line-height:2em;
  text-align:center;
}

.p-spirit-founder{
  margin-bottom:30px;
}

@include min-tab{
  .p-spirit-founder{
    flex:0 0 400px;
    margin-right:40px;
  }
}

@include max-sp{
  .p-spirit-heading{
    text-align:left;
    br{
      display:none;
    }
  }
  .p-spirit-text{
    font-size:14px;
    text-align:left;
    margin-bottom:5px;
    br{
      display:none;
    }
  }
}