@charset "utf-8";
 /***************************

object/project/_jimisugo.scss

***************************/

.p-jimisugo-top{
  background-color:#D0D1D1;
  padding:60px 0 100px;
}

.p-jimisugo-mae,
.p-jimisugo-zai{
  background-color:#D0D1D1;
  padding-left:10px;
  padding-right:10px;
  .c-container{
    background:#fff;
    @include border-radius(30px);
  }
}

@include min-tab{
  .p-jimisugo-mae,
  .p-jimisugo-zai{
    padding-left:10px;
    padding-right:10px;
    .c-container{
      padding-left:40px;
      padding-right:40px;
    }
  }
}

.p-jimisugo__ttl-wave{
  display:inline-block;
  background-image:url(../img/common/underline_wave.png);
  background-repeat:repeat-x;
  background-position:bottom;
  padding-bottom:10px;
  margin-bottom:20px;
  font-size:32px;
}

@include max-tab{
  .p-jimisugo__ttl-wave{
    font-size:30px;
  }
}

.p-jimisugo__ttl-bg-gray{
  font-size:14px;
  font-weight: bold;
  padding:10px 15px;
  background:#f4f4f4;
  text-align:left;
}

.p-jimisugo__sugoi{
  background-image:url(../img/common/bg_dot-gray.png);
  padding:15px 20px;
  margin-bottom:40px;
}

.p-jimisugo__sugoi__l{
  @include flex-item;
  position:relative;
  font-size:18px;
  font-weight:bold;
  display:inline-block;
  img{
    position:absolute;
    bottom:0;
    margin-left:10px;

  }
}

.p-jimisugo__sugoi__r{
  @include flex-item;
  font-size:14px;
  font-weight: bold;
  padding:3px;
  background:#FFF100;
  display:inline-block;
  text-align:center;
}

@include min-tab{
  .p-jimisugo__sugoi{
    @include flexbox;
    @include flex-align-center;
    @include flex-justify-between;
  }
}

@include max-tab{
  .p-jimisugo__sugoi{
    padding:15px 10px;
  }
  .p-jimisugo__sugoi__r{
    display:block;
    margin-top:10px;
  }
}


.p-jimisugo__heading{
  display:inline;
  background-image:url(../img/common/underline_yellow.png);
  background-repeat:repeat-x;
  background-position:left 0 bottom 5px;
  font-size:50px;
  line-height:1.4;
}
@include max-tab{
  .p-jimisugo__heading{
    font-size:25px;
  }
}

@include min-tab{
  .p-jimisugo-flex__text{
    @include flex-item;
    width:710px;
    margin-right:40px;
  }

  .p-jimisugo-flex__image{
    @include flex-item;
    width:450px;
  }
}

@include max-tab{
  .p-jimisugo-flex__text{
    margin-bottom:40px;
  }
  .p-jimisugo-flex__image{
    text-align:center;
  }
}

.p-jimisugo__ol{
  counter-reset: item;
}

.p-jimisugo__ol__item{
  position:relative;
  padding:15px 0 15px 55px;
  border-bottom:1px dotted #CCCCCC;
}

.p-jimisugo__ol__item:before{
  counter-increment: item;
  content: counter(item) "";
  line-height: 45px;
  font-size:16px;
  font-weight:bold;
  text-align: center;
  position: absolute;
  top: calc(50% - 23px);
  left: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #FFF;
}

.p-jimisugo-tuition__table__school{
  width:100px;
}

.p-jimisugo-tuition__table__graph{
  width:40%;
}

[class^="p-jimisugo-tuition__table__bar"]{
  width:calc(100% - 4em);
  background:$bunkyo_blue;
  display:inline-block;
  margin-right:1em;
}

.p-jimisugo-tuition__table__bar--431{
  width:calc(100% - 4em);
}
.p-jimisugo-tuition__table__bar--420{
  width:calc((100% - 4em) * 420 / 431);
}
.p-jimisugo-tuition__table__bar--408{
  width:calc((100% - 4em) * 408 / 431);
}
.p-jimisugo-tuition__table__bar--114{
  width:calc((100% - 4em) * 114 / 431);
}
.p-jimisugo-tuition__table__bar--216{
  width:calc((100% - 4em) * 216 / 431);
}
.p-jimisugo-tuition__table__bar--318{
  width:calc((100% - 4em) * 318 / 431);
}
.p-jimisugo-tuition__table__bar--125{
  width:calc((100% - 4em) * 125 / 431);
}
.p-jimisugo-tuition__table__bar--227{
  width:calc((100% - 4em) * 227 / 431);
}
.p-jimisugo-tuition__table__bar--329{
  width:calc((100% - 4em) * 329 / 431);
}
.p-jimisugo-tuition__table__bar--240{
  width:calc((100% - 4em) * 240 / 431);
}
.p-jimisugo-tuition__table__bar--250{
  width:calc((100% - 4em) * 250 / 431);
}
.p-jimisugo-tuition__table__bar--400{
  width:calc((100% - 4em) * 400 / 431);
}


@include min-tab{
  .p-jimisugo-anchor{
    @include flexbox;
    @include flex-justify-center;
  }
  .p-jimisugo-anchor__list{
    @include flex-item;
  }
  .p-jimisugo-anchor__list:nth-of-type(1){
    margin-right:15px;
  }
}
@include max-tab{
  .p-jimisugo-anchor__list{
    margin-bottom:20px;
  }
}
.p-jimisugo-anchor{
  margin-top:30px;
}
.p-jimisugo-anchor__list{
  a{
    padding:15px 20px;
    background:#fff;
    display:block;
    color:#000;
    text-decoration:none;
    font-size:18px;
    font-weight:bold;
    @include border-radius(10px);
  }
  a:before{
    font-family: Font Awesome\ 5 Free;
    content: "\f107";
    font-weight: bold;
    margin-right:10px;
  }
}