@charset "utf-8";
 /***************************

layout/_learn-list.scss

***************************/

.l-learn-list{
  @include flexbox;
  @include flex-justify-between;
  @include flex-wrap;
}

.l-learn-list__item{
  margin-bottom:40px;
}

@include min-tab{
  .l-learn-list__item{
    @include flex-item;
    width:calc((100% - 80px) / 3);
  }

//  .l-learn-list__item:not(:nth-of-type(3n)){
//    margin-right:40px;
//  }
}

@media screen and (max-width: 780px) and (min-width: 451px){
    .l-learn-list__item{
    @include flex-item;
    width:calc((100% - 20px) / 2);
  }

//  .l-learn-list__item:not(:nth-of-type(2n)){
//    margin-right:20px;
//  }
}

.l-learn-list__item__heading{
  font-size: 16px;
  font-weight: bold;
  padding: 10px 15px 10px 25px;
  background: #f4f4f4;
  text-align: left;
  position: relative;
  margin-bottom:15px;
}

.l-learn-list__item__heading:before {
    content: "";
    width: 15px;
    height: 2px;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    background-color: #1e56a3;
}