@charset "utf-8";
 /***************************

object/project/_lecture.scss

***************************/

.p-lecture-table__no{
  width:7%;
  background:#eee;
  font-weight:bold;
}

.p-lecture-table__field{
  width:8%;
}

.p-lecture-table__name{
  width:12%;
}

.p-lecture-table__subject{
  width:10%;
}

.p-lecture-table__theme{
  width:23%;
}

.p-lecture-table__outline{
  width:40%;
  text-align:left;
}
