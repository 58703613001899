@charset "utf-8";

/***************************

object/project/_teacher-training.scss

***************************/

.p-teacher-table__post{
    width:25%;
}

.p-teacher-table__name{
    width:25%;
}

.p-teacher-table__subject{
    width:50%;
}

@include max-tab{
    .p-teacher-table{
        width:850px;
    }
}