@charset "utf-8";
// background-image base-path
@mixin bg-img($path) {
	background-image: url($img-url + "/img/"+ $path);
}

//accent color
@mixin accent_color{
  color: #2892B7;
}
@mixin accent_bkg{
  background: #2892B7;
}
@mixin accent_trans_bkg($opacity){
  background: rgba(40,146,183,$opacity);
}

//bunkyo blue
@mixin bunkyo_blue_color{
  color: $bunkyo_blue;
}
@mixin bunkyo_blue_bkg{
  background: $bunkyo_blue;
}
@mixin bunkyo_blue_trans_bkg($opacity){
  background: rgba(30,86,163,$opacity);
}

@mixin ff-rc{
font-family: 'Open Sans', sans-serif;
}

// font-weight
@mixin ft-weight($weight) {
	font-weight: $weight;
}

// font-size
$font-size-base-px: 11px;
$font-size-base-rem: 1.1rem;

@mixin fs($font-size:$font-size-base-px) {
	font-size: $font-size;
	font-size: ($font-size / 10)rem;
}
@mixin fs-base {
	font-size: $font-size-base-px;
	font-size: $font-size-base-rem;
}
@mixin fs8 {
	font-size: 8px;
	font-size: .8rem;
}
@mixin fs9 {
	font-size: 9px;
	font-size: .9rem;
}
@mixin fs10 {
	font-size: 10px;
	font-size: 1rem;
}
@mixin fs11 {
	font-size: 11px;
	font-size: 1.1rem;
}
@mixin fs12 {
	font-size: 12px;
	font-size: 1.2rem;
}
@mixin fs13 {
	font-size: 13px;
	font-size: 1.3rem;
}
@mixin fs14 {
	font-size: 14px;
	font-size: 1.4rem;
}
@mixin fs15 {
	font-size: 15px;
	font-size: 1.5rem;
}
@mixin fs16 {
	font-size: 16px;
	font-size: 1.6rem;
}
@mixin fs17 {
	font-size: 17px;
	font-size: 1.7rem;
}
@mixin fs18 {
	font-size: 18px;
	font-size: 1.8rem;
}
@mixin fs20 {
	font-size: 20px;
	font-size: 2rem;
}
@mixin fs22 {
	font-size: 22px;
	font-size: 2.2rem;
}
@mixin fs24 {
	font-size: 24px;
	font-size: 2.4rem;
}
@mixin fs25 {
	font-size: 25px;
	font-size: 2.5rem;
}
@mixin fs26 {
	font-size: 26px;
	font-size: 2.6rem;
}
@mixin fs28 {
	font-size: 28px;
	font-size: 2.8rem;
}
@mixin fs30 {
	font-size: 30px;
	font-size: 3rem;
}
@mixin fs32 {
	font-size: 32px;
	font-size: 3.2rem;
}
@mixin fs34 {
	font-size: 34px;
	font-size: 3.4rem;
}
@mixin fs35 {
	font-size: 35px;
	font-size: 3.5rem;
}
@mixin fs36 {
	font-size: 36px;
	font-size: 3.6rem;
}
@mixin fs40 {
	font-size: 40px;
	font-size: 4rem;
}
@mixin fs43 {
	font-size: 43px;
	font-size: 4.3rem;
}
@mixin fs48 {
	font-size: 48px;
	font-size: 4.8rem;
}
@mixin fs50 {
	font-size: 50px;
	font-size: 5rem;
}
@mixin fs56 {
	font-size: 56px;
	font-size: 5.6rem;
}
@mixin fs60 {
	font-size: 60px;
	font-size: 6rem;
}
@mixin fs70 {
	font-size: 70px;
	font-size: 7rem;
}

// box-shadow
@mixin box-shadow($color) {
	box-shadow: 0 1px 2px $color;
}
@mixin box-shadow-base {
	box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

//image
@mixin full_image {
	width:100%;
	height:auto;
}

@mixin hover_black {
  position: relative;
  display: inline-block;

	&:hover:before {
	  position: absolute;
	  content: '';
	  display: block;
	  z-index: 100;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  background: rgba(0, 0, 0, 0.3);
	}

  @content;
}

//border radius
@mixin border-radius($px){
border-radius: $px;
-webkit-border-radius: $px;
}

//rotate
@mixin transform-rotate($deg){
-ms-transform: rotate($deg);
-webkit-transform: rotate($deg);
transform: rotate($deg);
}

//flex
@mixin flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  }

@mixin flex-row {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row;
    flex-direction: row;
}

@mixin flex-row-rev {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

@mixin flex-col {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: col;
    flex-direction: column;
}

@mixin flex-col-rev {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: col-reverse;
    flex-direction: column-reverse;
}

@mixin flex-nowrap {
    -ms-flex-wrap: none;
    flex-wrap: nowrap;
}

@mixin flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@mixin flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
}

@mixin flex-justify-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@mixin flex-justify-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@mixin flex-justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@mixin flex-justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@mixin flex-justify-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

@mixin flex-align-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

@mixin flex-align-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

@mixin flex-align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@mixin flex-align-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

@mixin flex-item{
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}


//media queries
$pc: 1100px;
$tab: 780px;
$sp: 450px;

@mixin max-pc {
  @media screen and (max-width: ($pc)) {
    @content;
  }
}

@mixin max-tab {
  @media screen and (max-width: ($tab)) {
    @content;
  }
}

@mixin max-sp {
  @media screen and (max-width: ($sp)) {
    @content;
  }
}

@mixin min-pc {
  @media screen and (min-width: ($pc + 1)) {
    @content;
  }
}

@mixin min-tab {
  @media screen and (min-width: ($tab + 1)) {
    @content;
  }
}

@mixin min-sp {
  @media screen and (min-width: ($sp + 1)) {
    @content;
  }
}

@mixin max-width($max) {
  @media screen and (max-width: ($max+"px")) {
    @content;
  }
}

@mixin min-width($min) {
  @media screen and (min-width: ($min+"px")) {
    @content;
  }
}
