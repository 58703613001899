@charset "utf-8";
 /***************************

object/project/_employment.scss

***************************/

.p-employment-graduate{
  @include flexbox;
}
.p-employment-graduate__item{
  @include flex-item;
  width: 18%;
  margin:0 1%;
  text-align: center;
}

.p-employment-graduate__item__p{
  font-size:52px;
  letter-spacing: -1px;
  color:$bunkyo_blue;
  span{
    color:#fff;
    font-size:17px;
    font-weight:bold;
    letter-spacing: 0px;
    display: block;
    background-color: $bunkyo_blue;
  }
}

.p-employment-industry__table td{
  vertical-align: middle;
}
.p-employment-industry__table_sorting{
  width:20%;
}
.p-employment-industry__table__th{
  width:5%;
  height:300px;
}

.p-employment-industry__table{
  .c-table-type1__th,
  .c-table-type1__td{
    padding:15px 5px;
  }
}

@include min-tab{
  .p-employment-graduate__table__th{
    width:12%;
  }
}

@media screen and (max-width: 1450px){
  .p-employment-industry__table{
    //width:1280px;
  }
}

@media screen and (max-width: 1024px){

.p-employment-graduate{
@include flex-wrap;
}

.p-employment-graduate__item{
  width: 48%;
  margin:0 1% 20px 1%;
  text-align: center;
}

}

@include max-tab{
  .p-employment-graduate__item__p{
    font-size:40px;
    color:$bunkyo_blue;
    span{
      font-size:16px;
      font-weight:bold;
    }
  }
}