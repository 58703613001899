@charset "UTF-8";
/***************************

foundation/base.scss

***************************/
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  word-break: break-all;
  word-wrap: break-word; }

html {
  overflow: auto;
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  overflow: hidden;
  font-size: 14px;
  color: #000;
  font-family: "游ゴシック", sans-serif;
  font-weight: 500;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  width: 100%; }

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  display: block;
  width: 100%;
  height: 100%; }

a:hover {
  text-decoration: none;
  opacity: 0.75; }

a:active,
a:hover {
  outline-width: 0; }

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none; }

p {
  margin: 0;
  line-height: 1.8; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

h3,
h4 {
  text-align: center; }

dl,
dt,
dd {
  margin: 0;
  padding: 0; }

figure {
  margin: 0; }

figcaption,
figure,
main {
  display: block; }

article,
aside,
footer,
header,
nav,
section {
  display: block; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

img {
  border-style: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  height: auto;
  max-width: 100%;
  vertical-align: middle; }

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

::-webkit-file-upload-button {
  -webkit-appearance: none;
  font: inherit; }

[hidden] {
  display: none; }

address {
  font-style: normal; }

figure {
  margin: 0; }

table {
  border-collapse: collapse; }

/***************************

layout/_header.scss

***************************/
header {
  position: relative; }
  header a {
    display: block; }

.top-head .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 110px 15px 15px;
  max-width: 1280px;
  margin: 0 auto;
  height: 80px; }

@media screen and (max-width: 780px) {
  .top-head .inner {
    height: 60px; } }

.top-head__contact-menu {
  margin-left: auto; }

.top-head__contact-menu__list {
  display: inline-block;
  position: relative;
  margin-right: 20px; }

.top-head__contact-menu__list a {
  position: relative;
  padding-left: 22px;
  text-decoration: none;
  color: #000; }

.top-head__contact-menu__list a:before {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  color: #1e56a3;
  position: absolute;
  top: calc(50% - 11px);
  left: 9px; }

.top-head__portal-menu {
  border: 1px solid #ddd;
  padding: 10px 0; }

.top-head__portal-menu__list {
  display: inline-block;
  padding: 0 15px; }
  .top-head__portal-menu__list a {
    text-decoration: none;
    color: #000; }

.top-head__portal-menu__list:nth-of-type(n+2) {
  border-left: 1px solid #ddd; }

@media screen and (max-width: 1100px) {
  .top-head__portal-menu,
  .top-head__contact-menu,
  .global-nav {
    display: none; } }

.global-nav__target-cover {
  background: #EDF2F8; }

.global-nav__target {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1280px;
  margin: 0 auto; }

.global-nav__target__list {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: calc(100% / 5);
  text-align: center;
  position: relative; }

.global-nav__target__list.is-acticve,
.global-nav__target__list:hover {
  background: #D9E3F0; }

.global-nav__target__list:after {
  content: "";
  width: 1px;
  height: 12px;
  background: #1e56a3;
  position: absolute;
  bottom: 7px;
  right: 0; }

.global-nav__target__list:first-of-type:before {
  content: "";
  width: 1px;
  height: 12px;
  background: #1e56a3;
  position: absolute;
  bottom: 7px;
  left: 0; }

.global-nav__target__list a {
  padding: 15px;
  text-decoration: none;
  color: #000; }

.global-nav__menu-cover {
  background: #1e56a3; }

.global-nav__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1280px;
  margin: 0 auto; }

.global-nav__menu__list {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: calc(100% / 5);
  color: #FFF;
  text-align: center;
  border-right: 1px solid #1A65AF;
  position: relative; }

.global-nav__menu__list.is-active,
.global-nav__menu__list:hover {
  background: #3977B9; }

.global-nav__menu__list:first-of-type {
  border-left: 1px solid #1A65AF; }

.global-nav__menu__list a {
  color: #FFF;
  padding: 20px 15px;
  text-decoration: none; }

@media screen and (max-width: 1460px) {
  .global-nav__target,
  .global-nav__menu {
    padding-right: 90px; } }

.nav-toggle {
  background: #1e56a3;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: -80px;
  padding: 30px 28px;
  cursor: pointer; }

.nav-toggle span {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.nav-toggle span, .nav-toggle span::before, .nav-toggle span::after {
  display: block;
  width: 24px;
  height: 2px;
  background-color: #fff;
  outline: 1px solid transparent;
  -webkit-transition-property: background-color, -webkit-transform;
  transition-property: background-color, -webkit-transform;
  transition-property: background-color, transform;
  transition-property: background-color, transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s; }

.nav-toggle span::before, .nav-toggle span::after {
  position: absolute;
  content: ""; }

.nav-toggle span::before {
  top: -8px; }

.nav-toggle span::after {
  top: 8px; }

.open .nav-toggle span {
  background-color: transparent; }

.open .nav-toggle span::before {
  -webkit-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg); }

.open .nav-toggle span::after {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg); }

.folding-cover {
  display: none; }

.open + .folding-cover {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: .35;
  z-index: 900; }

.folding-nav {
  width: 312px;
  height: 100%;
  transition: all 0.2s;
  transform: translate(312px);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: #FFF; }

.folding-nav.open {
  transform: translate(17px); }

.folding-nav__scroll {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.folding-nav__menu__list {
  border-bottom: 1px solid #DDD; }

.folding-nav__menu__list > a {
  padding: 15px 50px 15px 15px;
  background: #FFF;
  position: relative;
  text-decoration: none;
  color: #000; }

.folding-nav__menu__list > a:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  color: #1e56a3;
  position: absolute;
  top: calc(50% - 11px);
  right: 16px; }

.folding-nav__menu__list.is-active > a {
  background: #1e56a3;
  color: #FFF; }

.folding-nav__menu__list.is-active > a:after {
  color: #FFF; }

.folding-nav__menu__list:not(.is-active) .folding-nav__menu__list__lower {
  display: none; }

.folding-nav__menu__list.is-open .folding-nav__menu__list__lower {
  display: block; }

.folding-nav__menu__list__lower__list {
  border-bottom: 1px solid #FFF; }

.folding-nav__menu__list__lower__list:last-of-type {
  border-bottom: none; }

.folding-nav__menu__list__lower__list > a {
  padding: 10px 50px 10px 30px;
  background: #f4f4f4;
  position: relative;
  text-decoration: none;
  color: #000; }

.folding-nav__menu__list__lower__list > a:after {
  content: "";
  position: absolute;
  top: calc(50% - 4px);
  right: 20px;
  width: 4px;
  height: 4px;
  border-top: 1px solid #1e56a3;
  border-right: 1px solid #1e56a3;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.folding-nav__menu__list__lower__list.is-active > a {
  background: #CEDAE6; }

.folding-nav__menu__list__lower__list__lower__list {
  border-top: 1px solid #fff; }
  .folding-nav__menu__list__lower__list__lower__list > a {
    padding: 10px 10px 10px 45px;
    text-decoration: none;
    color: #000;
    background-color: #f4f4f4; }

.folding-nav__menu__list__lower__list__lower__list:last-of-type {
  border-bottom: none; }

.folding-nav__menu__list__lower__list__lower__list__lower__list > a {
  background-color: #f4f4f4;
  padding: 10px 10px 10px 60px;
  text-decoration: none;
  color: #000; }

.folding-nav__menu__list__lower__list__lower__list.is-active > a,
.folding-nav__menu__list__lower__list__lower__list__lower__list.is-active > a {
  background-color: #EDF2F8; }

.folding-nav__target {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.folding-nav__target__list {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 50%;
  border-bottom: 1px solid #DDD; }

.folding-nav__target__list:nth-of-type(2n+1) {
  border-right: 1px solid #DDD; }

.folding-nav__target__list a {
  padding: 15px;
  background: #EEE;
  text-decoration: none;
  color: #000; }

.folding-nav__contact-menu__list a {
  padding: 15px 50px 15px 15px;
  background: #2892B7;
  position: relative;
  color: #FFF;
  text-decoration: none; }

.folding-nav__contact-menu__list a:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  position: absolute;
  top: calc(50% - 11px);
  right: 16px; }

.folding-nav__contact-menu__list i {
  margin-right: 15px; }

.folding-nav__portal-menu__list {
  border-bottom: 1px solid #DDD; }

.folding-nav__portal-menu__list > a {
  padding: 15px 50px 15px 15px;
  background: #FFF;
  position: relative;
  text-decoration: none;
  color: #000; }

.folding-nav__portal-menu__list > a:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  color: #1e56a3;
  position: absolute;
  top: calc(50% - 11px);
  right: 16px; }

.folding-nav__examl__list {
  border-bottom: 1px solid #FFF; }

.folding-nav__exam__list > a {
  padding: 15px 50px 15px 15px;
  background: #1e56a3;
  position: relative;
  color: #FFF;
  text-decoration: none; }

.folding-nav__exam__list > a:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  position: absolute;
  top: calc(50% - 11px);
  right: 16px; }

.folding-nav__exam__list > a img {
  margin-right: 10px; }

.folding-nav__sns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.folding-nav__sns__list {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  margin: 0 25px;
  padding: 13px 0; }

.folding-nav__sns__list a {
  font-size: 30px; }

.folding-nav__sns__list .fa-youtube {
  color: #FF0000; }

.folding-nav__sns__list .fa-line {
  color: #00B900; }

@media screen and (max-width: 780px) {
  .nav-toggle {
    width: 60px;
    height: 60px;
    left: -60px;
    padding: 21px 19px; }
  .folding-nav {
    width: calc(100% - 43px);
    height: 100%;
    transition: all 0.2s;
    transform: translate(100%); } }

@media screen and (max-width: 450px) {
  .folding-nav__menu__list > a:after,
  .folding-nav__contact-menu__list a:after,
  .folding-nav__portal-menu__list > a:after,
  .folding-nav__exam__list > a:after {
    right: 26px; }
  .folding-nav__menu__list__lower__list > a:after {
    right: 30px; } }

/***************************

layout/_footer.scss

***************************/
.footer__outline {
  margin-bottom: 50px;
  padding-top: 40px; }

.footer__outline__attached__list {
  border: 1px solid #ddd; }
  .footer__outline__attached__list a {
    display: block;
    padding: 15px;
    text-align: center; }

.footer__link {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  margin-bottom: 25px; }

.footer__link__privacy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.footer__link__privacy__list {
  margin-right: 15px; }

.footer__link__sns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.footer__link__sns__list {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  margin: 0 12px;
  padding: 13px 0; }

.footer__link__sns__list a {
  font-size: 30px; }

.footer__link__sns__list .fa-youtube {
  color: #FF0000; }

.footer__link__sns__list .fa-line {
  color: #00B900; }

@media screen and (min-width: 1099px) {
  .footer__outline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .footer__outline__logo {
    margin-right: 5px;
    flex: 0 0 auto; }
  .footer__outline__add {
    margin-top: 5px;
    margin-right: 15px;
    margin-left: 10px;
    font-size: 12px; }
  .footer__outline__attached {
    margin-top: 15px;
    margin-bottom: 10px; }
  .footer__outline__attached__list {
    margin-left: 10px; }
    .footer__outline__attached__list a {
      padding: 10px; } }

@media screen and (max-width: 1100px) {
  .footer__outline__attached__list {
    margin-right: 10px; }
  .footer__outline__add {
    margin-top: 15px;
    margin-bottom: 15px; } }

@media screen and (min-width: 781px) {
  .footer__outline__attached {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .footer__outline__attached__list {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    min-width: 190px; }
  .footer__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; } }

@media screen and (max-width: 780px) {
  .footer__outline__logo {
    margin-bottom: 15px; }
  .footer__outline__attached__list {
    margin-bottom: 10px; } }

footer .c-flexlist-type1__list {
  padding: 0; }
  footer .c-flexlist-type1__list a {
    display: block;
    padding: 20px 15px 20px 35px; }
  footer .c-flexlist-type1__list .c-link-type1:before {
    left: 20px; }

.to-top {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background: #1e56a3; }

.to-top:before {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

@media screen and (max-width: 780px) {
  .to-top {
    width: 60px;
    height: 60px; } }

/***************************

layout/_slick.scss

***************************/
/* Slick Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }

.slick-track:before, .slick-track:after {
  content: "";
  display: table; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }

.slick-slide img {
  width: 100%;
  height: auto; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./img/common/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick/slick.eot");
  src: url("./fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick/slick.woff") format("woff"), url("./fonts/slick/slick.ttf") format("truetype"), url("./fonts/slick/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  z-index: 1;
  display: block;
  width: 16px;
  height: 31px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  margin-top: -15px;
  padding: 0;
  border: none;
  outline: none; }

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  opacity: 0.65;
  color: transparent; }

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.25; }

.slick-prev {
  left: 30px;
  background: url(img/common/btn-prev.png) no-repeat center center; }

.slick-next {
  right: 30px;
  background: url(img/common/btn-next.png) no-repeat center center; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 0; }

.slick-dots {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer; }

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-size: 30px;
  line-height: 20px;
  text-align: center;
  color: #AAA;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  color: #000;
  opacity: 0.75; }

.slick-archive {
  padding-top: 10px;
  margin: 0 auto;
  width: calc(100% - 50px); }

.slick-archive__item {
  padding: 5px; }

.slick-archive__item a {
  display: block;
  max-width: 350px;
  margin: auto;
  color: #231815;
  text-decoration: none;
  position: relative; }

.slick-archive__item a:hover {
  opacity: 0.65; }

.slick-archive__date {
  margin-top: 5px;
  display: block; }

.slick-archive__title {
  display: block;
  font-weight: bold; }

.slick-archive__label {
  background-color: #464a4c;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: .2em 2em; }

@media screen and (max-width: 560px) {
  .slick-archive__date {
    font-size: 1rem; }
  .slick-archive__title {
    font-size: 1.2rem; }
  .slick-archive__label {
    font-size: 1rem; } }

/***************************

layout/_pager.scss

***************************/
.wp-pagenavi {
  margin: 20px 0 100px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.wp-pagenavi a, .wp-pagenavi span {
  color: #1f1f1f;
  font-size: 14px;
  margin: 5px;
  width: 3.5em;
  height: 3.5em;
  line-height: 3.5;
  display: block;
  text-decoration: none;
  padding: 0;
  background: #f4f4f4;
  opacity: 1; }

.wp-pagenavi .previouspostslink,
.wp-pagenavi .nextpostslink {
  line-height: 3.5; }

.wp-pagenavi span.current {
  color: #fff;
  background: #1e56a3;
  cursor: default; }

.wp-pagenavi a:hover {
  opacity: 0.8; }

@media screen and (max-width: 780px) {
  .wp-pagenavi .extend {
    display: none; }
    .wp-pagenavi .extend + .larger {
      display: none; } }

@media screen and (max-width: 450px) {
  .wp-pagenavi a, .wp-pagenavi span {
    width: 2.5em; } }

.pager {
  margin: 20px 0 100px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center; }

.pager a {
  color: #1f1f1f;
  font-size: 14px;
  margin: 5px;
  width: 3.5em;
  height: 3.5em;
  line-height: 3.5;
  display: block;
  text-decoration: none;
  padding: 0;
  background: #f4f4f4;
  opacity: 1; }

.pager .back a {
  width: 200px;
  padding: 0 20px;
  font-weight: bold;
  color: #FFF;
  background: #1e56a3; }

@media screen and (max-width: 450px) {
  .pager .back a {
    width: auto; } }

/***************************

layout/_page-title.scss

***************************/
.l-page-title {
  background: url(../img/common/lower_pagetitle_other.jpg) no-repeat center;
  background-size: cover;
  position: relative; }

.l-page-title.is-about {
  background-image: url(../img/common/lower_pagetitle_about.jpg); }

.l-page-title.is-academic {
  background-image: url(../img/common/lower_pagetitle_academic.jpg); }

.l-page-title.is-admission {
  background-image: url(../img/common/lower_pagetitle_admission.jpg); }

.l-page-title.is-campuslife {
  background-image: url(../img/common/lower_pagetitle_campuslife.jpg); }

.l-page-title.is-career {
  background-image: url(../img/common/lower_pagetitle_career.jpg); }

.l-page-title.is-exam {
  background-image: url(../img/common/lower_pagetitle_exam.jpg); }

.l-page-title.is-alum {
  background-image: url(../img/common/lower_pagetitle_alum.jpg); }

.l-page-title.is-current {
  background-image: url(../img/common/lower_pagetitle_current.jpg); }

.l-page-title.is-highschool {
  background-image: url(../img/common/lower_pagetitle_highschool.jpg); }

.l-page-title.is-parent {
  background-image: url(../img/common/lower_pagetitle_parent.jpg); }

.l-page-title.is-region {
  background-image: url(../img/common/lower_pagetitle_region.jpg); }

.l-page-title__title {
  color: #fff; }

.l-lower-menu__list {
  position: relative; }

@media screen and (min-width: 781px) {
  .l-page-title {
    height: 250px; }
  .l-page-title__title {
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%); } }

@media screen and (max-width: 780px) {
  .l-page-title {
    padding: 50px 0; }
  .l-page-title__title {
    font-size: 30px;
    text-align: center; } }

.l-lower-menu {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #f4f4f4;
  margin-top: 70px; }

.l-lower-menu.is-3column {
  max-width: 776px;
  margin-left: auto;
  margin-right: auto; }

.l-lower-menu.is-4column {
  max-width: 1028px;
  margin-left: auto;
  margin-right: auto; }

@media screen and (max-width: 1100px) {
  .l-lower-menu {
    display: none; } }

.l-lower-menu__list {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: calc(100% / 5);
  border: 1px solid #ddd;
  background: #fff; }

.l-lower-menu__list a {
  padding: 20px 20px 20px 10px;
  font-size: 14px;
  color: #202020;
  position: relative;
  text-decoration: none;
  color: #000;
  display: block; }

.l-lower-menu__list a:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  color: #1e56a3;
  position: absolute;
  top: calc(50% - 11px);
  right: 8px; }

.l-lower-menu__list:nth-of-type(n+6) {
  border-top: none; }

.l-lower-menu__list:nth-of-type(n+2):not(:nth-of-type(5n+1)) {
  border-left: none; }

.l-lower-menu__list:hover {
  background: #E1E9F0; }
  .l-lower-menu__list:hover > a {
    color: #1e56a3;
    opacity: 1; }

.l-lower-menu__list.is-active {
  background: #1e56a3; }

.l-lower-menu__list.is-active:hover > a {
  opacity: 0.5; }

.l-lower-menu__list.is-active > a,
.l-lower-menu__list.is-active > a:after {
  color: #FFF; }

.l-lower-menu__list__lower {
  position: absolute;
  top: 62px;
  visibility: hidden;
  opacity: 0;
  z-index: 6;
  width: 100%;
  -webkit-transition: all .5s ease;
  transition: all .5s ease; }

.l-lower-menu__list__lower__list {
  border-top: 1px solid #ddd; }
  .l-lower-menu__list__lower__list a {
    background: #f4f4f4;
    display: block;
    color: #000;
    padding: 15px 15px; }
  .l-lower-menu__list__lower__list a:hover {
    background: #1e56a3;
    color: #FFF;
    opacity: 1; }

.l-lower-menu__list__lower__list__lower__list {
  border-top: 1px solid #ddd; }
  .l-lower-menu__list__lower__list__lower__list a {
    padding-left: 30px;
    position: relative; }
  .l-lower-menu__list__lower__list__lower__list a:before {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: 10px;
    width: 10px;
    height: 2px;
    background: #1e56a3; }

.l-lower-menu__list__lower__list.is-active > a,
.l-lower-menu__list__lower__list__lower__list.is-active > a {
  background: #1e56a3;
  color: #FFF;
  opacity: 1; }

.l-lower-menu__list__lower__list.is-active > a:after,
.l-lower-menu__list__lower__list__lower__list.is-active > a:after {
  color: #fff; }

.l-lower-menu__list__lower__list > a:hover:after,
.l-lower-menu__list__lower__list__lower__list > a:hover:after {
  color: #fff; }

.l-lower-menu__list:hover .l-lower-menu__list__lower {
  visibility: visible;
  opacity: 1; }

/***************************

layout/_search.scss

***************************/
.l-search {
  background: #000;
  padding: 13px 15px; }

.l-search__form {
  position: relative; }

.l-search__sbox {
  padding: 10px;
  border: none;
  border-radius: 30px;
  outline: 0;
  background: #fff;
  width: 100%; }

.l-search__sbtn {
  height: 50px;
  position: absolute;
  right: 8px;
  top: -7px;
  background: none;
  color: #666;
  border: none;
  font-size: 20px; }

.l-search__sbtn:hover {
  color: #ccc; }

/***************************

layout/_side.scss

***************************/
.l-side-nav {
  position: fixed;
  top: calc(50% - 220px);
  right: 0;
  max-width: 80px;
  z-index: 10; }
  .l-side-nav a {
    display: block; }

.l-side-nav__list-exam {
  margin-bottom: 1px; }

.l-side-nav__list-exam a {
  background: #1e56a3;
  color: #fff;
  line-height: 17px;
  padding: 25px 27px;
  text-align: center;
  text-decoration: none; }

.l-side-nav__list-exam img {
  margin-bottom: 10px; }

.l-side-nav__list-sns {
  background: #fff;
  padding: 15px; }

.l-side-nav__list-sns:nth-of-type(3) {
  padding-top: 30px; }

.l-side-nav__list-sns:last-of-type {
  padding-bottom: 30px; }

.l-side-nav__list-sns a {
  text-align: center;
  font-size: 24px; }

.l-side-nav__list-sns .fa-youtube {
  color: #FF0000; }

.l-side-nav__list-sns .fa-line {
  color: #00B900; }

.l-side-nav__list-sns img {
  width: 24px; }

.l-side-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
  width: 400px;
  position: fixed;
  top: calc(50% - 301px);
  right: 0;
  transition: all 0.2s;
  transform: translate(320px);
  z-index: 10; }

.l-side-search.open {
  transform: translate(0); }

.l-side-search__toggle {
  flex: 0 0 auto;
  color: #fff;
  font-size: 30px;
  background: #000;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 15px;
  cursor: pointer; }

.l-side-search .l-search {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 100%; }

@media screen and (max-width: 1100px) {
  .l-side-nav {
    display: none; }
  .l-side-search {
    top: 0;
    right: 80px; } }

@media screen and (max-width: 780px) {
  .l-side-search {
    right: 60px;
    transform: translate(calc(100% - 60px));
    width: calc(100% - 60px); }
  .l-side-search.open {
    right: 60px;
    transform: translate(0); }
  .l-side-search__toggle {
    width: 60px;
    height: 60px;
    padding: 5px; }
  .l-side-search .l-search {
    padding: 10px 15px;
    height: 60px; } }

@media screen and (max-height: 901px) {
  .l-side-nav__list-sns {
    padding: 10px; }
  .l-side-nav__list-sns:nth-of-type(3) {
    padding-top: 15px; }
  .l-side-nav__list-sns:nth-of-type(5) {
    padding-bottom: 15px; } }

@media screen and (max-height: 818px) {
  .l-side-search {
    top: 0;
    right: 80px; }
  .l-side-nav {
    top: 81px; } }

@media screen and (max-width: 780px) {
  .l-side-search {
    right: 60px;
    transform: translate(calc(100% - 60px));
    width: calc(100% - 60px); } }

@media screen and (max-height: 700px) {
  .l-side-nav__list-sns {
    display: none; } }

/***************************

layout/_breadcrumbs.scss

***************************/
.breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .breadcrumbs .current-item a {
    pointer-events: none;
    text-decoration: none; }
  .breadcrumbs a {
    color: #000;
    display: block; }

.l-page-title {
  margin-bottom: 44px; }

.l-page-title .breadcrumbs {
  position: absolute;
  bottom: -44px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px; }
  .l-page-title .breadcrumbs .home {
    width: 44px; }
    .l-page-title .breadcrumbs .home a {
      text-align: center;
      color: #1e56a3;
      padding: 15px 14px;
      text-decoration: none; }
  .l-page-title .breadcrumbs li:nth-of-type(n+2) {
    padding: 16px 0 11px 36px;
    position: relative; }
  .l-page-title .breadcrumbs li:nth-of-type(n+2):before {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    left: 15px;
    width: 4px;
    height: 4px;
    border-top: 1px solid #222;
    border-right: 1px solid #222;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
  .l-page-title .breadcrumbs li:last-of-type {
    padding-right: 20px; }

footer .breadcrumbs {
  padding: 15px 0; }
  footer .breadcrumbs .home a i {
    color: #1e56a3; }
  footer .breadcrumbs li:nth-of-type(n+2) {
    padding-left: 36px;
    position: relative; }
  footer .breadcrumbs li:nth-of-type(n+2):before {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    left: 15px;
    width: 4px;
    height: 4px;
    border-top: 1px solid #222;
    border-right: 1px solid #222;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }

@media screen and (max-width: 780px) {
  .l-page-title {
    margin-bottom: 30px; }
    .l-page-title .breadcrumbs {
      bottom: -30px;
      background-color: #fff; }
  .l-page-title .breadcrumbs .home {
    width: auto; }
  .l-page-title .breadcrumbs .home a {
    padding: 10px 0 10px 9px; }
  .l-page-title .breadcrumbs li:nth-of-type(n+2) {
    padding: 10px 0 7px 20px; }
  .l-page-title .breadcrumbs li:nth-of-type(n+2):before {
    top: calc(50% - 4px);
    left: 7px; }
  .l-page-title .breadcrumbs li:last-of-type {
    padding-right: 10px; }
  .breadcrumbs a {
    font-size: 11px; }
  footer .breadcrumbs .home a {
    padding: 10px 0px; }
  footer .breadcrumbs li:nth-of-type(n+2) {
    padding: 10px 0 7px 20px; }
  footer .breadcrumbs li:nth-of-type(n+2):before {
    top: calc(50% - 4px);
    left: 7px; }
  footer .breadcrumbs li:last-of-type {
    padding-right: 10px; } }

@media screen and (max-width: 450px) {
  footer .breadcrumbs li:nth-of-type(n+2) {
    padding: 7px 0 7px 14px; }
  footer .breadcrumbs li:nth-of-type(n+2):before {
    top: calc(50% - 3px);
    left: 4px; } }

/***************************

layout/_search.scss

***************************/
.l-lower-list__item {
  background-color: #F4F4F4;
  padding: 10px 10px 15px 10px; }
  .l-lower-list__item img {
    border: 1px solid #DEDCDD;
    margin-bottom: 15px; }
  .l-lower-list__item > a {
    text-decoration: none;
    color: inherit; }
  .l-lower-list__item > a:hover > * {
    opacity: 0.75; }

@media screen and (min-width: 781px) {
  .l-lower-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .l-lower-list__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 40px) / 2);
    margin-bottom: 45px; }
  .l-lower-list__item:nth-of-type(2n+1) {
    margin-right: 40px; } }

@media screen and (max-width: 780px) {
  .l-lower-list__item {
    margin-bottom: 40px; }
    .l-lower-list__item img {
      width: 100%; } }

/***************************

layout/_news-list.scss

***************************/
.l-news-list__item {
  border: 1px solid #ddd; }
  .l-news-list__item a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-decoration: none;
    color: #000;
    padding: 20px 25px; }

.l-news-list__item:not(:last-of-type) {
  border-bottom: none; }

.l-news-list__item__cat {
  flex: 0 0 135px;
  text-align: center;
  background: #1e56a3;
  padding: 2px;
  color: #fff;
  margin-right: 35px;
  width: 135px;
  font-size: 12px; }

.l-news-list__item__cat.important {
  background: #C2191F; }

.l-news-list__item__cat.press,
.p-home-event__list__item__tag.press {
  background: #C1964E; }

.l-news-list__item__cat.center,
.l-news-list__item__cat.career,
.l-news-list__item__cat.support,
.p-home-event__list__item__tag.center,
.p-home-event__list__item__tag.career,
.p-home-event__list__item__tag.support {
  background: #16345D; }

.l-news-list__item__cat.current,
.p-home-event__list__item__tag.current {
  background: #C6994F; }

.l-news-list__item__cat.education,
.l-news-list__item__cat.primary,
.l-news-list__item__cat.secondary,
.p-home-event__list__item__tag.primary,
.p-home-event__list__item__tag.secondary {
  background: #E94F50; }

.l-news-list__item__cat.welfare,
.p-home-event__list__item__tag.welfare {
  background: #20B080; }

.l-news-list__item__cat.psychology,
.p-home-event__list__item__tag.psychology {
  background: #8372B2; }

.l-news-list__item__cat.nutrition,
.p-home-event__list__item__tag.nutrition {
  background: #F29019; }

.l-news-list__item__cat.global,
.p-home-event__list__item__tag.gc,
.p-home-event__list__item__tag.global {
  background: #1393B4; }

.l-news-list__item__cat.training,
.p-home-event__list__item__tag.training {
  background: #2F8E75; }

.l-news-list__item__cat.becc,
.p-home-event__list__item__tag.becc,
.l-news-list__item__cat.salc,
.p-home-event__list__item__tag.salc {
  background: #EB5902; }

.l-news-list__item__cat.jinseiron,
.p-home-event__list__item__tag.jinseiron {
  background: #34563f; }

.l-news-list__item__cat.admission {
  background: #D2135D; }

.l-news-list__item__cat.exam,
.p-home-event__list__item__tag.exam {
  background: #D2135D; }

.l-news-list__item__cat.student,
.p-home-event__list__item__tag.student {
  background: #6CA82F; }

.l-news-list__item__cat.mikikai,
.p-home-event__list__item__tag.mikikai {
  background: #417953; }

@media screen and (max-width: 450px) {
  .l-news-list__item__cat {
    margin-right: 20px; } }

.l-news-list__item__date {
  flex: 0 0 auto;
  color: #1e56a3; }

.l-news-list__item__ttl {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

@media screen and (min-width: 781px) {
  .l-news-list__item__date {
    margin-right: 35px; } }

@media screen and (max-width: 780px) {
  .l-news-list__item a {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .l-news-list__item__ttl {
    width: 100%; } }

.l-news-list.is-important {
  background: #FFF7F8;
  margin-bottom: 10px; }
  .l-news-list.is-important .l-news-list__item {
    padding: 0;
    border: none; }
    .l-news-list.is-important .l-news-list__item a {
      padding: 20px 25px; }
  .l-news-list.is-important .l-news-list__item__date {
    color: #CF0110; }
  .l-news-list.is-important .l-news-list__item__ttl {
    color: #CF0110; }

/***************************

layout/_flex-img-text.scss

***************************/
@media screen and (min-width: 781px) {
  .l-flex-img-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .l-flex-img-text.is-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .l-flex-img-text__img {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
  .l-flex-img-text__img.is-flex0 {
    flex: 0 0 auto; }
  .l-flex-img-text__text {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; } }

/***************************

layout/_anchor-link.scss

***************************/
.l-anchor-link__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  border: 1px solid #1e56a3;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 10px; }
  .l-anchor-link__item a {
    color: #1e56a3;
    font-weight: bold;
    text-align: center;
    padding: 15px 30px 15px 15px;
    position: relative;
    display: table;
    text-decoration: none; }
    .l-anchor-link__item a span {
      display: table-cell;
      vertical-align: middle; }

.l-anchor-link__item a:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f107";
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: calc(50% - 10px); }

.l-anchor-link__item:hover {
  background: #1e56a3; }
  .l-anchor-link__item:hover a {
    color: #fff;
    opacity: 1; }

@media screen and (min-width: 781px) {
  .l-anchor-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .l-anchor-link.is-3column {
    max-width: 930px;
    margin-left: auto;
    margin-right: auto; }
    .l-anchor-link.is-3column .l-anchor-link__item {
      width: calc((100% - 30px) / 3); }
    .l-anchor-link.is-3column .l-anchor-link__item:nth-of-type(3n) {
      margin-right: 0; }
  .l-anchor-link.is-4column {
    margin-left: auto;
    margin-right: auto; }
    .l-anchor-link.is-4column .l-anchor-link__item {
      width: calc((100% - 45px) / 4); }
    .l-anchor-link.is-4column .l-anchor-link__item:nth-of-type(4n) {
      margin-right: 0; }
  .l-anchor-link.is-5column {
    margin-left: auto;
    margin-right: auto; }
    .l-anchor-link.is-5column .l-anchor-link__item {
      width: calc((100% - 60px) / 5); }
    .l-anchor-link.is-5column .l-anchor-link__item:nth-of-type(5n) {
      margin-right: 0; } }

@media screen and (max-width: 780px) {
  .l-anchor-link__item a {
    padding: 10px 30px 10px 15px; } }

/***************************

layout/_overflow.scss

***************************/
@media screen and (max-width: 1450px) {
  .l-scroll-x {
    overflow-x: scroll; } }

@media screen and (max-width: 1100px) {
  .l-scroll-x-pc {
    overflow-x: scroll; } }

@media screen and (max-width: 780px) {
  .l-scroll-x-tab {
    overflow-x: scroll; }
  .l-scroll-x-tab__table {
    width: 1100px; } }

@media screen and (max-width: 450px) {
  .l-scroll-x-sp {
    overflow-x: scroll; } }

/***************************

object/component/_info-side.scss

***************************/
.l-info-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .l-info-side .l-info-side__contents {
    width: 100%;
    margin-right: 30px; }
    .l-info-side .l-info-side__contents .p-home-event__list.is-second {
      width: 100%; }
      .l-info-side .l-info-side__contents .p-home-event__list.is-second .p-home-event__list__item {
        width: calc(33.3% - 27px);
        margin-right: 40px; }
        .l-info-side .l-info-side__contents .p-home-event__list.is-second .p-home-event__list__item:nth-child(3n) {
          margin-right: 0; }
    .l-info-side .l-info-side__contents .p-home-report__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .l-info-side .l-info-side__contents .p-home-report__list .p-home-report__list__item {
        width: calc(33.3% - 27px);
        margin: 0 40px 40px 0;
        padding: 0; }
        .l-info-side .l-info-side__contents .p-home-report__list .p-home-report__list__item:nth-child(3n) {
          margin-right: 0; }
        .l-info-side .l-info-side__contents .p-home-report__list .p-home-report__list__item .p-home-report__list__item__img {
          text-align: center; }
  .l-info-side .l-info-side__list {
    width: 250px;
    -webkit-box-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }
    .l-info-side .l-info-side__list .l-info-side__list__item {
      margin-bottom: 4px; }
      .l-info-side .l-info-side__list .l-info-side__list__item .l-info-side__list__item__link {
        display: block;
        padding: 15px 15px 15px 40px;
        background-color: #f4f4f4; }
        .l-info-side .l-info-side__list .l-info-side__list__item .l-info-side__list__item__link::before {
          left: 25px; }
    .l-info-side .l-info-side__list .l-info-side__list__item.is-heading {
      text-align: center;
      padding: 15px;
      font-weight: bold;
      color: #FFF;
      background: #1e56a3; }

@media screen and (max-width: 1100px) {
  .l-info-side .l-info-side__contents .p-home-event__list.is-second .p-home-event__list__item {
    width: calc(50% - 20px); }
    .l-info-side .l-info-side__contents .p-home-event__list.is-second .p-home-event__list__item:nth-child(3n) {
      margin-right: 40px; }
    .l-info-side .l-info-side__contents .p-home-event__list.is-second .p-home-event__list__item:nth-child(2n) {
      margin-right: 0; }
  .l-info-side .l-info-side__contents .p-home-report__list .p-home-report__list__item {
    width: calc(50% - 20px); }
    .l-info-side .l-info-side__contents .p-home-report__list .p-home-report__list__item:nth-child(3n) {
      margin-right: 40px; }
    .l-info-side .l-info-side__contents .p-home-report__list .p-home-report__list__item:nth-child(2n) {
      margin-right: 0; } }

@media screen and (max-width: 780px) {
  .l-info-side {
    display: block; }
    .l-info-side .l-info-side__contents {
      margin-right: 0; }
      .l-info-side .l-info-side__contents .p-home-event__list.is-second .p-home-event__list__item {
        width: 100%;
        margin-right: 0; }
        .l-info-side .l-info-side__contents .p-home-event__list.is-second .p-home-event__list__item:nth-child(3n) {
          margin-right: 0; }
      .l-info-side .l-info-side__contents .p-home-report__list .p-home-report__list__item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0; }
        .l-info-side .l-info-side__contents .p-home-report__list .p-home-report__list__item:nth-child(3n) {
          margin-right: 0; }
    .l-info-side .l-info-side__list {
      width: 100%;
      margin-bottom: 50px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .l-info-side .l-info-side__list .l-info-side__list__item {
        width: calc(50% - 2px); }
      .l-info-side .l-info-side__list .l-info-side__list__item.is-heading {
        width: 100%; } }

@media screen and (max-width: 450px) {
  .l-info-side .l-info-side__list .l-info-side__list__item {
    width: 100%; } }

/***************************

layout/_depart.scss

***************************/
.l-depart-top__hedding {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 25px;
  margin-top: 60px;
  text-align: center; }

.l-depart-top__message {
  text-align: center;
  margin-bottom: 30px; }

.l-depart-course__item {
  margin-bottom: 40px;
  border-top: 3px solid #1e56a3;
  background-color: #f4f4f4;
  padding-bottom: 30px; }
  .l-depart-course__item a {
    text-decoration: none;
    color: inherit; }
  .l-depart-course__item a:hover > * {
    opacity: 0.75; }

.l-depart-course__item__heading {
  font-size: 20px;
  padding: 20px 0;
  text-align: center; }

.l-depart-course__item__img {
  padding: 0 10px; }

.l-depart-course__item__text {
  text-align: center;
  padding: 0 25px; }
  .l-depart-course__item__text h3 {
    font-size: 24px;
    font-weight: normal;
    padding: 35px 0 30px;
    border-bottom: 1px solid #E0E0E0;
    color: #1e56a3;
    margin-bottom: 24px; }

.l-depart__flow__item .c-title-type4 {
  border: 1px solid #1e56a3;
  padding: 3px 10px;
  margin-bottom: 10px; }

.l-depart-flow__heading {
  font-size: 24px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 20px; }

.l-depart-curriculum__heading {
  font-size: 24px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 20px; }

@media screen and (min-width: 781px) {
  .l-depart-course {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .l-depart-course__item {
    width: calc(50% - 20px); }
  .l-depart-course__item:nth-of-type(2n+1) {
    margin-right: 40px; }
  .l-depart__flow__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 80px) / 2);
    margin-bottom: 90px; }
  .l-depart__flow__item:nth-of-type(2n+1) {
    margin-right: 80px; }
  .is-reverse .l-depart__flow__item:nth-of-type(2n+1) {
    margin-right: 0px; }
  .is-reverse .l-depart__flow__item:nth-of-type(2n) {
    margin-right: 80px; } }

@media screen and (max-width: 780px) {
  .l-depart__flow__item:nth-of-type(2n) {
    margin-bottom: 70px; }
  .l-depart__flow__item .c-title-type4 {
    margin-top: 15px; } }

@media screen and (max-width: 450px) {
  .l-depart-top__hedding {
    text-align: left;
    font-size: 20px; }
    .l-depart-top__hedding br {
      display: none; }
  .l-depart-top__message {
    text-align: left; }
  .l-depart-course__item__text {
    padding: 0 15px; }
  .l-depart-course__item__text {
    text-align: left; }
    .l-depart-course__item__text h4 {
      text-align: left;
      font-size: 20px; }
    .l-depart-course__item__text br {
      display: none; }
  .l-depart-flow__heading {
    font-size: 22px; }
  .l-depart-flow__heading {
    font-size: 20px; }
    .l-depart-flow__heading br {
      display: none; }
  .l-depart-curriculum__heading {
    font-size: 20px; } }

/***************************

layout/_learn-list.scss

***************************/
.l-learn-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.l-learn-list__item {
  margin-bottom: 40px; }

@media screen and (min-width: 781px) {
  .l-learn-list__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 80px) / 3); } }

@media screen and (max-width: 780px) and (min-width: 451px) {
  .l-learn-list__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 20px) / 2); } }

.l-learn-list__item__heading {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 15px 10px 25px;
  background: #f4f4f4;
  text-align: left;
  position: relative;
  margin-bottom: 15px; }

.l-learn-list__item__heading:before {
  content: "";
  width: 15px;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  background-color: #1e56a3; }

/***************************

object/component/_heading.scss

***************************/
.c-title-type1 {
  font-size: 32px;
  font-weight: normal;
  position: relative;
  border-top: 2px solid #1f1f1f;
  border-bottom: 1px solid #ddd;
  padding: 30px 2px;
  text-align: left; }
  .c-title-type1 a {
    display: block;
    text-decoration: none;
    color: inherit; }

.c-title-type1:before {
  content: "";
  width: 30vw;
  max-width: 128px;
  height: 2px;
  background: #1e56a3;
  position: absolute;
  top: -2px;
  left: 0; }

@media screen and (min-width: 451px) {
  .c-title-type1 br {
    display: none; } }

@media screen and (max-width: 450px) {
  .c-title-type1 {
    font-size: 26px; } }

.c-title-type2 {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  border-left: 4px solid #1e56a3;
  padding: 10px 15px;
  text-align: left; }
  .c-title-type2 a {
    display: block;
    text-decoration: none;
    color: inherit; }

.c-title-type3 {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 15px 10px 25px;
  background: #f4f4f4;
  text-align: left;
  position: relative; }
  .c-title-type3 a {
    display: block;
    text-decoration: none;
    color: inherit; }

.c-title-type3:before {
  content: "";
  width: 15px;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  background-color: #1e56a3; }

.c-title-type4 {
  font-size: 16px;
  font-weight: bold;
  padding: 3px;
  color: #1e56a3;
  display: inline-block;
  text-align: left; }

.c-title-type5 {
  font-size: 18px;
  font-weight: bold;
  text-align: left; }

.c-title-type5:before {
  content: "―";
  margin-right: 5px; }

.c-first-letter--bunkyo_blue:first-letter {
  color: #1e56a3;
  display: inline-block; }

/***************************

object/component/_box.scss

***************************/
.c-container {
  max-width: 1310px;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px; }

@media screen and (min-width: 1101px) and (max-width: 1460px) {
  .c-container {
    padding-right: 90px; } }

.c-box--gray {
  padding: 15px 20px;
  background: #f4f4f4;
  border: 1px solid #DEDCDD; }

.c-box--blue {
  padding: 15px 20px;
  background: #EDF2F8;
  border: 1px solid #CEDAE6; }

@media screen and (max-width: 780px) {
  .c-box--gray,
  .c-box--blue {
    padding: 10px; } }

/***************************

object/component/_button.scss

***************************/
a {
  color: #1e56a3;
  text-decoration: underline;
  display: inline; }

a:hover {
  text-decoration: none; }

.c-button-type1 {
  min-width: 320px;
  width: auto;
  text-align: center;
  padding: 20px 50px 20px 50px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  color: #FFF;
  background: #1e56a3;
  text-decoration: none; }

.c-button-type1:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border: 1px solid #FFF;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: absolute;
  top: calc(50% - 16px);
  right: 15px; }

.c-button-type1:hover {
  background: #143C70;
  opacity: 1;
  cursor: pointer; }

.c-button-type1.is-inactive,
.c-button-type1.is-inactive:hover {
  color: #333;
  background: #ccc;
  pointer-events: none; }

.c-button-type1.is-inactive:after {
  color: #FFF; }

.c-button-type2 {
  min-width: 320px;
  width: auto;
  text-align: center;
  padding: 20px 50px 20px 50px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  color: #FFF;
  background: #1e56a3;
  text-decoration: none;
  margin-bottom: 15px; }

.c-button-type2:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  position: absolute;
  top: calc(50% - 16px);
  right: 15px; }

.c-button-type2:hover {
  background: #143C70;
  opacity: 1;
  cursor: pointer; }

.c-button-type2.is-inactive,
.c-button-type2.is-inactive:hover {
  color: #333;
  background: #ccc;
  pointer-events: none; }

.c-button-type2.is-inactive:after {
  color: #FFF; }

@media screen and (min-width: 451px) {
  .c-button-type2 br {
    display: none; } }

@media screen and (max-width: 450px) {
  .c-button-type1,
  .c-button-type2 {
    min-width: initial;
    min-width: auto;
    width: 100%; }
  .c-button-type2 {
    padding: 20px 30px 20px 10px; }
  .c-button-type2:after {
    right: 3px; } }

.c-button-type3 {
  font-size: 18px;
  color: #000;
  text-decoration: none;
  font-weight: bold; }

.c-button-type3:hover {
  text-decoration: underline;
  color: #1e56a3;
  cursor: pointer; }

.c-button-type3:before {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  padding: 5px 9px;
  margin-right: 15px;
  color: #FFF;
  background-color: #1e56a3;
  text-decoration: none;
  transition: 0.2s ease; }

.c-button-type3:hover:before {
  margin-right: 10px;
  margin-left: 5px; }

.c-link-type1 {
  padding-left: 21px;
  position: relative;
  display: inline-block;
  width: auto;
  text-decoration: none;
  color: #000; }

.c-link-type1:before {
  content: "";
  position: absolute;
  top: calc(50% - 3px);
  left: 5px;
  width: 4px;
  height: 4px;
  border-top: 1px solid #1F1813;
  border-right: 1px solid #1F1813;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.c-link-type2 {
  padding-left: 21px;
  position: relative;
  display: inline-block;
  width: auto;
  text-decoration: none;
  color: #000; }

.c-link-type2:before {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  color: #1e56a3;
  position: absolute;
  top: calc(50% - 11px);
  left: 5px; }

.c-link-type3 {
  padding-left: 21px;
  position: relative;
  display: inline-block;
  width: auto;
  line-height: 24px; }

.c-link-type3:before {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  color: #1e56a3;
  position: absolute;
  top: calc(50% - 12px);
  left: 5px; }

.c-link-pdf:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f1c1";
  font-style: normal;
  font-variant: normal;
  font-size: 16px;
  color: #AA0000;
  margin-left: 5px; }

.c-link-word:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f1c2";
  font-style: normal;
  font-variant: normal;
  font-size: 16px;
  color: #2C579B;
  margin-left: 5px; }

.c-link-excel:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f1c3";
  font-style: normal;
  font-variant: normal;
  font-size: 16px;
  color: #107c10;
  margin-left: 5px; }

.c-link-external:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f35d";
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 12px;
  color: #1e56a3;
  margin-left: 3px; }

@media screen and (min-width: 781px) {
  .c-tel-link {
    pointer-events: none;
    color: inherit;
    text-decoration: none; } }

/***************************

object/component/_list.scss

***************************/
.c-ul-type1__list {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px; }

.c-ul-type1__list:last-of-type {
  margin-bottom: 0; }

.c-ul-type1__list:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 14px;
  height: 14px;
  background: #1e56a3; }

@media screen and (min-width: 781px) {
  .c-ul-type1__list.u-flexbox-tab__item2 {
    margin-bottom: 10px; }
  .c-ul-type1__list.u-flexbox-tab__item2:last-of-type,
  .c-ul-type1__list.u-flexbox-tab__item2:nth-last-of-type(2) {
    margin-bottom: 0px; } }

@media screen and (max-width: 780px) {
  .c-ul-type1__list.u-flexbox-tab__item2:not(:last-of-type) {
    margin-bottom: 20px; } }

.c-ul-check__list {
  position: relative;
  padding: 3px 3px 3px 30px;
  -webkit-transition: all .2s;
  transition: all .2s; }

.c-ul-check__list::before,
.c-ul-check__list::after {
  position: absolute;
  content: ''; }

.c-ul-check__list::before {
  top: 6px;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #3498db; }

.c-ul-check__list::after {
  top: 6px;
  left: 3px;
  width: 8px;
  height: 4px;
  margin-top: 4px;
  border-left: 2px solid #3498db;
  border-bottom: 2px solid #3498db;
  opacity: 1;
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1); }

.c-ol-type1 {
  counter-reset: item; }

.c-ol-type1__list {
  position: relative;
  min-height: 32px;
  padding-left: 45px;
  padding-top: 6px;
  margin-bottom: 10px; }

.c-ol-type1__list:last-of-type {
  margin-bottom: 0; }

.c-ol-type1__list:before {
  counter-increment: item;
  content: counter(item) "";
  color: #fff;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 1px;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #222; }

.c-ol-type2 {
  counter-reset: item; }

.c-ol-type2__list {
  position: relative;
  padding-left: 25px;
  padding-top: 6px;
  margin-bottom: 5px; }

.c-ol-type2__list:last-of-type {
  margin-bottom: 0; }

.c-ol-type2__list:before {
  counter-increment: item;
  content: "(" counter(item) ")";
  color: #222;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 1px;
  left: 0; }

.c-ol-type3 {
  counter-reset: item; }

.c-ol-type3__list {
  position: relative;
  padding-left: 16px;
  padding-top: 6px;
  margin-bottom: 5px; }

.c-ol-type3__list:last-of-type {
  margin-bottom: 0; }

.c-ol-type3__list:before {
  counter-increment: item;
  content: counter(item) ".";
  color: #222;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 1px;
  left: 0; }

.c-flexlist-type1__list {
  padding: 20px 15px;
  border: 1px solid #ddd;
  background: #fff; }

@media screen and (min-width: 781px) {
  .c-flexlist-type1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .c-flexlist-type1__list {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 20%; }
  .c-flexlist-type1__list:not(:nth-of-type(5n+1)) {
    border-left: none; }
  .c-flexlist-type1__list:nth-of-type(n+6) {
    border-top: none; }
  .c-flexlist-type1__list:last-of-type {
    border-right: 1px solid #ddd; } }

@media screen and (max-width: 780px) {
  .c-flexlist-type1__list:nth-of-type(n+2) {
    border-top: none; } }

.c-dl-type1__dt {
  font-weight: bold;
  padding: 15px 0 0; }

.c-dl-type1__dd {
  padding: 15px 0 15px 15px;
  border-bottom: 1px solid #ddd; }

.c-dl-type1__dt:first-of-type {
  border-top: 1px solid #ddd; }

@media screen and (min-width: 781px) {
  .c-dl-type1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .c-dl-type1__dt {
    flex: 0 0 15em;
    border-bottom: 1px solid #ddd;
    padding: 15px 0; }
  .c-dl-type1__dd {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc(100% - 15em); }
  .c-dl-type1__dd:first-of-type {
    border-top: 1px solid #ddd; } }

.c-dl-type2__dt {
  font-weight: bold;
  padding-left: 25px;
  margin-bottom: 5px;
  position: relative; }

.c-dl-type2__dt:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 14px;
  height: 14px;
  background: #1e56a3; }

.c-dl-type2__dd {
  padding-left: 30px;
  margin-bottom: 15px; }

.c-dl-type2__dd:last-of-type {
  margin-bottom: 0; }

@media screen and (min-width: 781px) {
  .c-dl-type2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .c-dl-type2__dt {
    flex: 0 0 15em;
    width: 15em;
    margin-bottom: 15px; }
  .c-dl-type2__dd {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc(100% - 15em - 30px);
    padding-left: 15px; }
  .c-dl-type2__dt:last-of-type {
    margin-bottom: 0; } }

/***************************

object/component/_table.scss

***************************/
.c-table-type1,
.c-table-type2 {
  background: #fff;
  border-collapse: collapse;
  width: 100%; }

.c-table-type1__th,
.c-table-type2__th {
  background: #7d7d7d;
  font-weight: bold;
  color: #fff;
  border: 1px solid #ddd;
  padding: 15px 20px; }

.c-table-type1__td,
.c-table-type2__td {
  border: 1px solid #ddd;
  padding: 15px 20px;
  text-align: center;
  vertical-align: top; }

.c-table--scroll {
  overflow: auto;
  white-space: nowrap; }

@media screen and (max-width: 780px) {
  .c-table-type1 {
    width: 1280px; }
  .c-table-type1.is-half {
    width: 640px; }
  .c-table-type1.is-fit {
    width: 100%; }
    .c-table-type1.is-fit .c-table-type1__th,
    .c-table-type1.is-fit .c-table-type1__td {
      padding: 15px 10px; }
  .c-table-type2__th,
  .c-table-type2__td {
    display: block;
    width: 100%; }
  .c-table-type2__th,
  .c-table-type2__td {
    border-bottom: none; }
  tr:last-of-type .c-table-type2__td {
    border-bottom: 1px solid #ddd; }
  .c-table-type1.is-fix--x {
    display: block;
    position: relative;
    overflow-x: scroll;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border-left: 1px solid #ddd; }
    .c-table-type1.is-fix--x tbody {
      display: block;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
    .c-table-type1.is-fix--x .c-table-type1__th,
    .c-table-type1.is-fix--x .c-table-type1__td {
      border-left: none;
      border-bottom: none; }
    .c-table-type1.is-fix--x tr:last-of-type .c-table-type1__th,
    .c-table-type1.is-fix--x tr:last-of-type .c-table-type1__td {
      border-bottom: 1px solid #ddd; }
    .c-table-type1.is-fix--x .c-table-type2__th,
    .c-table-type1.is-fix--x .c-table-type2__td {
      display: inline-block; }
  .c-table-type1__th.is-fix--x,
  .c-table-type1__td.is-fix--x {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
    border-left: none; } }

@media screen and (max-width: 450px) {
  .c-table-type1__th.is-fix--x,
  .c-table-type1__td.is-fix--x {
    width: 100px; } }

@supports (-ms-ime-align: auto) {
  @media screen and (max-width: 780px) {
    .c-table-type1.is-fix--x tbody {
      width: 1280px; } } }

/***************************

object/component/_checkbox.scss

***************************/
.c-checkbox01 input[type="checkbox"] {
  display: none; }

.c-checkbox01 label {
  position: relative;
  display: inline-block;
  padding: 3px 3px 3px 22px;
  cursor: pointer;
  -webkit-transition: all .2s;
  transition: all .2s;
  margin-right: 10px; }

.c-checkbox01 label::before,
.c-checkbox01 label::after {
  position: absolute;
  content: '';
  -webkit-transition: all .2s;
  transition: all .2s; }

.c-checkbox01 label::before {
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -8px;
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 3px; }

.c-checkbox01 label::after {
  opacity: 0;
  top: 50%;
  left: 3px;
  width: 8px;
  height: 4px;
  margin-top: -4px;
  border-left: 2px solid #3498db;
  border-bottom: 2px solid #3498db;
  -webkit-transform: rotate(-45deg) scale(0.5);
  transform: rotate(-45deg) scale(0.5); }

.c-checkbox01 label:hover::before {
  background: #fff; }

.c-checkbox01 input[type="checkbox"]:checked + label::before {
  background: #fff;
  border: 1px solid #3498db; }

.c-checkbox01 input[type="checkbox"]:checked + label::after {
  opacity: 1;
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1); }

/***************************

object/project/_home.scss

***************************/
.p-home-heading--type1 {
  font-family: 'Open Sans', sans-serif;
  color: #1e56a3;
  font-size: 40px;
  text-align: center; }
  .p-home-heading--type1 span {
    display: block;
    color: #221815;
    font-size: 13px;
    margin-top: -7px; }

@media screen and (max-width: 450px) {
  .p-home-heading--type1 {
    font-size: 36px; } }

.p-home-heading--type2 {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 40px;
  text-align: center;
  margin-bottom: 30px; }
  .p-home-heading--type2 span {
    display: block;
    font-size: 13px;
    margin-top: -7px; }

.p-home-heading--type3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  border-bottom: 2px solid #201813;
  color: #201813;
  display: inline-block; }

.p-home-main-visual {
  position: relative; }
  .p-home-main-visual .slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    right: 110px;
    counter-reset: item; }
    .p-home-main-visual .slick-dots li {
      flex: 1 1 auto;
      position: relative; }
      .p-home-main-visual .slick-dots li button {
        width: 100%; }
      .p-home-main-visual .slick-dots li button:before {
        content: "";
        background-color: #FFF;
        height: 1px;
        width: 100%; }
    .p-home-main-visual .slick-dots li.slick-active button:before {
      background-color: #1e56a3;
      opacity: 1; }
    .p-home-main-visual .slick-dots li:before {
      counter-increment: item;
      content: counter(item) "";
      color: #fff;
      opacity: 0; }
    .p-home-main-visual .slick-dots li:first-of-type:before {
      position: absolute;
      top: -10px;
      left: -1em;
      opacity: 1; }
    .p-home-main-visual .slick-dots li:last-of-type:before {
      position: absolute;
      top: -10px;
      right: -1em;
      opacity: 1; }

.p-home-main-visual__message {
  position: absolute;
  top: calc(50% - 150px);
  left: 100px;
  z-index: 5; }

.p-home-main-visual__btn-scroll {
  position: absolute;
  bottom: 52px;
  left: calc(50% - 15px);
  z-index: 6;
  height: auto;
  width: 80px; }

.p-home-main-visual__slide__item {
  height: calc(100vh - 246px); }
  .p-home-main-visual__slide__item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover;"; }

.p-home-main-visual__news {
  background: #1e56a3; }

.p-home-main-visual__news__heading {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  background: #000;
  color: #fff;
  font-size: 14px;
  padding: 15px 50px;
  text-align: center; }

.p-home-main-visual__news__slide {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  background: #1e56a3;
  padding: 0 30px; }
  .p-home-main-visual__news__slide a {
    color: #fff;
    font-weight: bold;
    text-decoration: none; }
  .p-home-main-visual__news__slide span {
    color: #8EABD1;
    margin-right: 15px; }

@media screen and (min-width: 781px) {
  .p-home-main-visual__news__slide {
    width: calc(100% - 198px); } }

@media screen and (max-width: 1100px) {
  .p-home-main-visual__slide__item {
    height: calc(100vh - 132px); } }

@media screen and (max-width: 780px) {
  .p-home-main-visual__slide__item {
    height: calc(100vh - 157px); }
  .p-home-main-visual__news__slide {
    padding: 10px 20px; }
  .p-home-main-visual__message {
    left: 10%;
    margin-right: 20%; }
  .p-home-main-visual__btn-scroll {
    display: none; } }

.p-home-pickup {
  padding: 80px 0 60px; }

.p-home-pickup__list__item__img img {
  object-fit: cover;
  font-family: "object-fit: cover;"; }

@media screen and (min-width: 1461px) {
  .p-home-pickup__list__item__img {
    height: calc((1280px / 4 - 40px) / 325 * 217); }
    .p-home-pickup__list__item__img img {
      height: calc((1280px / 4 - 40px) / 325 * 217); } }

@media screen and (min-width: 1311px) and (max-width: 1460px) {
  .p-home-pickup__list__item__img {
    height: calc((1205px / 4 - 40px) / 325 * 217); }
    .p-home-pickup__list__item__img img {
      height: calc((1205px / 4 - 40px) / 325 * 217); } }

@media screen and (min-width: 1101px) and (max-width: 1310px) {
  .p-home-pickup__list__item__img {
    height: calc(((100vw - 107px) / 4 - 40px) / 325 * 217); }
    .p-home-pickup__list__item__img img {
      height: calc(((100vw - 107px) / 4 - 40px) / 325 * 217); } }

@media screen and (max-width: 1100px) {
  .p-home-pickup__list__item__img {
    height: calc((100vw / 4 - 40px) / 325 * 217); }
    .p-home-pickup__list__item__img img {
      height: calc((100vw / 4 - 40px) / 325 * 217); } }

@media screen and (max-width: 780px) {
  .p-home-pickup__list__item__img {
    height: calc(((100vw - 60px) / 2 - 40px) / 325 * 217); }
    .p-home-pickup__list__item__img img {
      height: calc(((100vw - 60px) / 2 - 40px) / 325 * 217); } }

@media screen and (max-width: 450px) {
  .p-home-pickup__list__item__img {
    height: calc((100vw - 40px) / 325 * 217); }
    .p-home-pickup__list__item__img img {
      height: calc((100vw - 40px) / 325 * 217); } }

@media screen and (min-width: 1101px) and (max-width: 1460px) {
  .p-home-pickup__list {
    padding-right: 90px; } }

@media screen and (max-width: 780px) {
  .p-home-pickup {
    padding: 30px 0; } }

.p-home-pickup__list {
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 35px; }

.p-home-pickup__list__item {
  padding: 20px; }
  .p-home-pickup__list__item a {
    text-decoration: none;
    color: #000; }

@media screen and (min-width: 451px) {
  .p-home-pickup__list__item {
    max-width: 365px; } }

.p-home-event__list__item {
  position: relative;
  margin-bottom: 50px; }
  .p-home-event__list__item a {
    text-decoration: none;
    display: block; }

.p-home-pickup .slick-dots li {
  padding: 0 12px; }
  .p-home-pickup .slick-dots li button:before {
    color: #D1DDE8; }

.p-home-pickup .slick-dots li.slick-active button:before {
  color: #1e56a3;
  opacity: 1; }

.p-home-event__list__item__date {
  background: #1e56a3;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  width: 80px;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 5; }
  .p-home-event__list__item__date span {
    display: block;
    margin-top: 5px;
    padding: 10px 5px;
    background: #D1DDE8;
    color: #000;
    font-weight: bold;
    font-size: 28px; }

.p-home-event__list__item__date.is-end {
  background: #666;
  color: #999;
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  line-height: 86px; }

.p-home-event__list__item__img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background: #fff;
  position: relative;
  text-align: center; }

.p-home-event__list__item__tag {
  color: #fff;
  background: #1e56a3;
  padding: 2px 5px;
  width: 135px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px; }

.p-home-event__list__item__tag.is-end {
  color: #000;
  background: #ddd;
  font-weight: bold;
  display: none; }

.p-home-event__list__item__heading {
  color: #1e56a3;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.p-home-event__list__item__sche {
  color: #000; }

@media screen and (min-width: 781px) {
  .p-home-event__list {
    width: calc(50% - 20px); }
  .p-home-event__list.is-first {
    margin-right: 40px;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
    .p-home-event__list.is-first .p-home-event__list__item__heading {
      font-size: 28px; }
    .p-home-event__list.is-first .p-home-event__list__item__date {
      padding-top: 5px;
      width: 80px;
      font-size: 12px; }
      .p-home-event__list.is-first .p-home-event__list__item__date span {
        margin-top: 5px;
        padding: 10px 5px;
        font-size: 28px; }
    .p-home-event__list.is-first .p-home-event__list__item__date.is-end {
      padding: 27px 0;
      font-size: 24px; }
  .p-home-event__list.is-first .p-home-event__list__item__img {
    height: 540px; }
    .p-home-event__list.is-first .p-home-event__list__item__img img {
      object-fit: contain;
      height: 540px;
      width: 100%;
      font-family: "object-fit: contain;"; }
  .p-home-event__list.is-second {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .p-home-event__list.is-second .p-home-event__list__item__img {
      height: 270px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .p-home-event__list.is-second .p-home-event__list__item__img img {
        object-fit: contain;
        height: 270px;
        width: 100%;
        font-family: "object-fit: contain;"; }
    .p-home-event__list.is-second .p-home-event__list__item {
      width: calc(50% - 20px); }
    .p-home-event__list.is-second .p-home-event__list__item:nth-of-type(2n-1) {
      margin-right: 40px; } }

@media screen and (max-width: 780px) {
  .p-home-event__list.is-first .p-home-event__list__item__img {
    height: calc(100vw - 15px); }
    .p-home-event__list.is-first .p-home-event__list__item__img img {
      object-fit: contain;
      height: calc(100vw - 15px);
      width: 100%;
      font-family: "object-fit: contain;"; }
  .is-second .p-home-event__list__item__img {
    display: none; }
  .is-second .p-home-event__list__item {
    background: #fff;
    margin-bottom: 20px; }
    .is-second .p-home-event__list__item a {
      padding: 25px 10px 25px 85px; }
  .is-second .p-home-event__list__item__date {
    top: 0;
    left: 0;
    height: 100%; }
    .is-second .p-home-event__list__item__date span {
      height: calc(100% - 26px); }
  .is-second .p-home-event__list__item__tag {
    position: static;
    margin-bottom: 10px; } }

.p-home-report {
  background: #fff;
  padding: 40px 60px; }

.p-home-report__heading {
  font-family: 'Open Sans', sans-serif;
  color: #1e56a3;
  font-size: 28px;
  text-align: center;
  position: relative;
  margin-bottom: 25px;
  text-decoration: none; }
  .p-home-report__heading span {
    display: block;
    color: #221815;
    font-size: 13px;
    margin-top: -7px; }

.p-home-report__list__item {
  padding: 0 20px; }
  .p-home-report__list__item a {
    text-decoration: none; }

.p-home-report__list__item__img {
  background: #EDF2F8;
  margin-bottom: 10px;
  position: relative;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .p-home-report__list__item__img img {
    object-fit: contain;
    height: 270px;
    width: 100%;
    font-family: "object-fit: contain;"; }

.p-home-report__list__heading {
  font-weight: bold;
  color: #1e56a3;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@media screen and (min-width: 781px) {
  .p-home-report__list__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 25%; }
  .p-home-report__heading:before {
    content: "";
    width: 1px;
    height: 60px;
    background: #1e56a3;
    position: absolute;
    top: -70px;
    left: 50%; } }

@media screen and (max-width: 780px) {
  .p-home-report {
    padding: 30px 20px; }
  .p-home-report__list__item {
    border-top: 1px dotted #ccc;
    padding: 0; }
    .p-home-report__list__item a {
      padding: 20px 15px;
      display: block; }
  .p-home-report__list__item:last-of-type {
    border-bottom: 1px dotted #ccc; }
  .p-home-report__list__item__img {
    display: none; } }

@media screen and (max-width: 450px) {
  .p-home-report {
    padding: 30px 10px; }
  .p-home-report__list__item a {
    padding: 20px 5px; } }

.p-home-admission {
  background: url(../img/common/bg_classroom.jpg) no-repeat center;
  background-size: cover;
  padding: 80px 0 90px; }

@media screen and (min-width: 781px) {
  .p-home-admission-list {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc(50% - 20px);
    margin-right: 40px; } }

@media screen and (max-width: 780px) {
  .p-home-admission {
    padding: 40px 0; }
  .p-home-admission-list {
    margin-bottom: 30px; } }

.p-home-admission-list__item {
  margin-bottom: 3px; }
  .p-home-admission-list__item a {
    padding: 25px 20px;
    background: #fff;
    position: relative;
    text-decoration: none;
    color: #000;
    display: block; }
  .p-home-admission-list__item a:after {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 25px;
    width: 4px;
    height: 4px;
    border-top: 1px solid #1F1813;
    border-right: 1px solid #1F1813;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }

.p-home-admission-list2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

@media screen and (min-width: 781px) {
  .p-home-admission-list2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc(50% - 20px); } }

.p-home-admission-list2__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  margin-bottom: 20px; }

.p-home-admission-list2__item.is-2column {
  width: calc(50% - 10px);
  max-width: 300px; }

.p-home-admission-list2__item.is-2column:nth-of-type(2n-1) {
  margin-right: 20px; }

@media screen and (max-width: 780px) {
  .p-home-admission-list2__item {
    margin-bottom: 10px; } }

.p-home-course {
  padding: 75px 0 100px; }

.p-home-course__list {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.p-home-course__list__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  position: relative;
  margin-bottom: 80px; }
  .p-home-course__list__item a {
    text-decoration: none; }

.p-home-course__list__item:hover {
  opacity: 0.5; }

@media screen and (min-width: 781px) {
  .p-home-course__list__item {
    width: calc((100% - 80px) / 3); } }

@media screen and (max-width: 780px) and (min-width: 451px) {
  .p-home-course__list__item {
    width: calc((100% - 10px) / 2); }
  .p-home-course__list__item:nth-of-type(2n-1) {
    margin-right: 10px; } }

@media screen and (max-width: 450px) {
  .p-home-course__list__item {
    margin-bottom: 50px; } }

.p-home-course__list__item__headding {
  text-align: center;
  font-weight: bold;
  width: 100%; }

.p-home-course__list__item__heading__depart {
  background: rgba(25, 55, 96, 0.9);
  color: #fff;
  font-size: 16px;
  padding: 15px 5px;
  display: block; }

.p-home-course__list__item__heading__depart.primary,
.p-home-course__list__item__heading__depart.secondary {
  background: rgba(233, 79, 80, 0.9); }

.p-home-course__list__item__heading__course {
  background: rgba(255, 255, 255, 0.9);
  padding: 25px 5px;
  display: block;
  color: #000; }

@media screen and (min-width: 781px) {
  .p-home-course__list__item__headding {
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translate(-50%);
    max-width: 300px; } }

.p-home-course__list__item.is-human .p-home-course__list__item__heading__depart {
  background: rgba(33, 104, 154, 0.9); }

.p-home-course__list__item.is-human .p-home-course__list__item__heading__depart.welfare {
  background: rgba(32, 176, 128, 0.9); }

.p-home-course__list__item.is-human .p-home-course__list__item__heading__depart.psychology {
  background: rgba(131, 114, 178, 0.9); }

.p-home-course__list__item.is-human .p-home-course__list__item__heading__depart.nutrition {
  background: rgba(242, 144, 25, 0.9); }

.p-home-course__list__item.is-human .p-home-course__list__item__heading__depart.global {
  background: rgba(19, 147, 180, 0.9); }

.p-home-course__list__item.is-human .p-home-course__list__item__heading__course {
  background: #FFF; }

@media screen and (max-width: 780px) {
  .p-home-course__list__item__heading__depart {
    background: #193760; }
  .p-home-course__list__item__heading__course {
    background: white; }
  .p-home-course__list__item.is-human .p-home-course__list__item__heading__depart {
    background: rgba(33, 104, 154, 0.9); } }

.p-home-special {
  background: url(../img/common/bg_logo.png) no-repeat top right;
  padding: 75px 0 60px; }

.p-home-special__list__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  position: relative;
  margin-bottom: 40px; }

@media screen and (min-width: 781px) {
  .p-home-special__list__item {
    width: calc((100% - 80px) / 3); }
  .p-home-special__list__item:not(:nth-of-type(3n)) {
    margin-right: 40px; } }

@media screen and (max-width: 780px) and (min-width: 451px) {
  .p-home-special__list__item {
    width: calc((100% - 10px) / 2); }
  .p-home-special__list__item:nth-of-type(2n-1) {
    margin-right: 10px; } }

.p-home-movie {
  padding: 70px 0 75px; }

.p-home-movie__list {
  padding: 70px 0 75px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.p-home-special__list__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  position: relative; }

.p-home-movie__list__item {
  max-width: 290px; }

@media screen and (min-width: 781px) {
  .p-home-movie__list__item {
    width: calc((100% - 120px) / 4); }
  .p-home-movie__list__item:not(:nth-of-type(4n)) {
    margin-right: 40px; }
  .p-home-movie__list__item:nth-of-type(n+5) {
    margin-top: 40px; } }

@media screen and (max-width: 780px) and (min-width: 451px) {
  .p-home-movie__list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .p-home-movie__list__item {
    width: calc((100% - 10px) / 2);
    margin-bottom: 20px; }
  .p-home-movie__list__item:nth-of-type(2n-1) {
    margin-right: 10px; } }

@media screen and (max-width: 450px) {
  .p-home-movie__list__item {
    margin: 0 auto 40px; } }

.p-home-movie__item__heading {
  color: #fff;
  background: #1e56a3;
  font-size: 14px;
  padding: 15px 5px; }

/***************************

object/project/_lecture.scss

***************************/
.p-lecture-table__no {
  width: 7%;
  background: #eee;
  font-weight: bold; }

.p-lecture-table__field {
  width: 8%; }

.p-lecture-table__name {
  width: 12%; }

.p-lecture-table__subject {
  width: 10%; }

.p-lecture-table__theme {
  width: 23%; }

.p-lecture-table__outline {
  width: 40%;
  text-align: left; }

/***************************

object/project/_brief.scss

***************************/
.p-brief-people-table__sorting {
  width: 200px; }

.p-brief-people-table__academic {
  width: 12%; }

.p-brief-schedule-table {
  width: 100%; }

.p-brief-schedule-table__th {
  text-align: center;
  width: 150px; }

.p-brief-schedule-table__td {
  text-align: left; }

@media screen and (min-width: 781px) {
  .p-brief-schedule-table__th {
    width: 260px; } }

@media screen and (max-width: 780px) {
  .p-brief-schedule-table__th {
    width: 100%; }
  .p-brief-people-table .c-table-type1__th,
  .p-brief-people-table .c-table-type1__td {
    width: 100px; }
  .p-brief-people-table .c-table-type1__th.is-left,
  .p-brief-people-table .c-table-type1__td.is-left {
    left: 100px;
    background: #fff; } }

@media screen and (max-width: 450px) {
  .p-brief-people-table .c-table-type1__th.is-fix--x,
  .p-brief-people-table .c-table-type1__td.is-fix--x {
    width: 80px; }
  .p-brief-people-table .c-table-type1__th.is-left,
  .p-brief-people-table .c-table-type1__td.is-left {
    left: 80px; } }

/***************************

object/project/_cumpasmap.scss

***************************/
.p-campusmap-ul__list {
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: 30px; }

.p-campusmap-ul__list__heading {
  margin-top: 10px;
  margin-bottom: 5px;
  color: #1e56a3; }
  .p-campusmap-ul__list__heading span {
    color: #fff;
    background: #1e56a3;
    padding: 5px 0;
    width: 36px;
    display: inline-block;
    margin-right: 10px;
    text-align: center; }

@media screen and (min-width: 781px) {
  .p-campusmap-ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .p-campusmap-ul__list {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 80px) / 3);
    margin: 0 40px 40px 0; }
  .p-campusmap-ul__list.is-2column {
    width: calc((100% - 40px) / 2); } }

@media screen and (max-width: 780px) {
  .p-campusmap-map {
    width: 800px; }
  .p-campusmap-ul__list__heading {
    text-align: left; }
    .p-campusmap-ul__list__heading span {
      text-align: center; } }

/***************************

object/project/_training.scss

***************************/
.p-training-period {
  color: #1e56a3;
  font-weight: bold;
  font-size: 16px;
  text-align: left; }

.p-training-exp__img {
  flex: 0 0 auto; }

.p-training-exp__name {
  font-size: 18px;
  font-weight: bold; }

.p-training-exp__depart {
  margin-bottom: 5px; }

.p-training-exp__heading {
  color: #1e56a3;
  font-size: 20px;
  margin-bottom: 5px;
  text-align: left; }

@media screen and (min-width: 781px) {
  .p-training-exp__img {
    margin-right: 40px; } }

@media screen and (max-width: 780px) {
  .p-training-exp__img {
    margin-bottom: 20px;
    text-align: center; } }

/***************************

object/project/_campus-event.scss

***************************/
.p-campus-event {
  margin-bottom: 50px; }

.p-campus-event__img {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.p-campus-event__img__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: calc((100% - 15px) / 2);
  margin-bottom: 15px; }

@media screen and (min-width: 781px) {
  .p-campus-event__img__item {
    width: calc((100% - 30px) / 2);
    margin-bottom: 30px; }
  .p-campus-event__img__item:not(:nth-of-type(2n)) {
    margin-right: 30px; } }

@media screen and (max-width: 780px) {
  .p-campus-event__img__item:not(:nth-of-type(2n)) {
    margin-right: 15px; } }

@media screen and (min-width: 451px) {
  .p-campus-event {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .p-campus-event__list {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 40px) / 3);
    margin-right: 40px; }
  .p-campus-event__img {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 40px) / 3 * 2); } }

@media screen and (max-width: 450px) {
  .p-campus-event__list {
    margin-bottom: 30px; } }

/***************************

object/project/_becc.scss

***************************/
.p-becc-floor__list__item {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 15px; }

.p-becc-floor__list__item.is-floor3 {
  background-image: url(../img/campuslife/becc/bg_floor3.png); }

.p-becc-floor__list__item.is-floor2 {
  background-image: url(../img/campuslife/becc/bg_floor2.png); }

.p-becc-floor__list__item.is-floor1 {
  background-image: url(../img/campuslife/becc/bg_floor1.png); }

.p-becc-floor__list__item__box {
  padding: 20px;
  background: rgba(255, 255, 255, 0.9); }

@media screen and (min-width: 781px) {
  .p-becc-floor__list__item__box {
    width: 50%;
    padding: 30px; } }

@media screen and (max-width: 780px) {
  .p-becc-floor__list__item__box {
    position: relative;
    bottom: -250px; }
  .p-becc-floor__list__item {
    margin-bottom: 230px; } }

.p-becc-floor__list__item.is-floor2 .p-becc-floor__list__item__box {
  margin-left: auto; }

.p-becc-salc__slide {
  background: #f4f4f4; }

.p-becc-salc__slide__item {
  position: relative; }

.p-becc-salc__slide__item__text {
  background: rgba(255, 255, 255, 0.9);
  padding-top: 20px;
  padding-bottom: 20px;
  width: calc(100% - 60px); }

@media screen and (min-width: 781px) {
  .p-becc-salc__slide__item__text {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%); } }

@media screen and (max-width: 780px) {
  .p-becc-salc__slide__item__text {
    position: relative;
    top: -50px; } }

.p-becc-salc__slide__item__text__heading {
  font-size: 24px;
  color: #1e56a3; }
  .p-becc-salc__slide__item__text__heading span {
    font-size: 18px;
    display: inline-block; }

.p-becc-salc__heading {
  font-size: 20px;
  margin-bottom: 10px; }
  .p-becc-salc__heading span {
    font-size: 16px;
    display: inline-block;
    margin-left: 15px; }

@media screen and (max-width: 780px) {
  .p-becc-salc__heading {
    margin-left: 0px;
    margin-top: 15px; } }

.p-becc-movie__box {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }

.p-becc-movie__box iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.p-becc-sns__item--insta {
  width: calc((100% - 420px) / 2);
  margin-right: 40px; }

.p-becc-sns__item--fb {
  margin-right: 40px; }

.p-becc-sns__item--tw {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: calc((100% - 420px) / 2);
  height: 500px;
  overflow-y: scroll;
  border: 1px solid #ddd; }

@media screen and (min-width: 781px) {
  .p-becc-sns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .p-becc-sns__item--insta {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
  .p-becc-sns__item--fb {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 340px; }
  .p-becc-sns__item--tw {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; } }

@media screen and (max-width: 900px) {
  .p-becc-sns {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .p-becc-sns__item--insta {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 40px; }
  .p-becc-sns__item--tw {
    width: calc(100% - 380px); } }

@media screen and (max-width: 780px) {
  .p-becc-sns__item--insta {
    width: 100%; }
  .p-becc-sns__item--fb {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 40px;
    text-align: center; }
  .p-becc-sns__item--tw {
    width: 100%; } }

/***************************

object/project/_ttc.scss

***************************/
.p-ttc-subject-table__th {
  width: calc(100% / 6); }

.p-ttc-idea__box {
  padding: 20px; }

@media screen and (max-width: 450px) {
  .p-ttc-idea__box {
    padding: 20px 0; }
    .p-ttc-idea__box br {
      display: none; } }

.p-ttc-idea__list {
  background-color: #FFF;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto; }

@media screen and (min-width: 451px) {
  .p-ttc-idea__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .p-ttc-idea__list__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc(50% - 20px); }
  .p-ttc-idea__list__item:nth-of-type(2n+1) {
    margin-right: 40px; }
  .p-ttc-idea__list__item:nth-last-of-type(2) {
    margin-bottom: 0; } }

.p-ttc-gudance__grade {
  width: 125px; }

@media screen and (max-width: 780px) {
  .p-ttc-gudance__table {
    width: 100%; } }

@media screen and (max-width: 450px) {
  .p-ttc-gudance__grade {
    width: 80px; } }

.p-ttc-practice__list__item {
  background-image: url(../img/common/arrow02.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  padding: 0 40px 20px 15px;
  width: 200px; }

.p-ttc-practice__list__item:nth-of-type(2) {
  background-image: url(../img/common/arrow03.png); }

.p-ttc-practice__list__item:nth-of-type(3) {
  background-image: url(../img/common/arrow04.png); }

@media screen and (min-width: 781px) {
  .p-ttc-practice__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .p-ttc-practice__list__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin-right: 20px; } }

@media screen and (max-width: 780px) {
  .p-ttc-practice__list__item {
    margin: 0 auto 30px; } }

.p-ttc-voice {
  border: 1px solid #ccc;
  padding: 25px; }

.p-ttc-voice__heading {
  font-size: 20px;
  background-image: url(../img/common/underline_yellow-thin.png);
  background-repeat: repeat-x;
  background-position: left bottom;
  padding-bottom: 20px;
  text-align: center;
  margin-bottom: 20px; }

.p-ttc-voice__heading:before {
  content: url(../img/common/ico_voice.png);
  margin-right: 10px; }

.p-ttc-voice__text {
  background-color: #f4f4f4;
  padding: 20px; }

.p-ttc-voice__text:not(:last-of-type) {
  margin-bottom: 15px; }

.p-ttc-voice--flex {
  border: 1px solid #ccc;
  padding: 25px; }

.p-ttc-voice--flex__heading {
  font-size: 20px; }

.p-ttc-voice--flex__heading:before {
  content: url(../img/common/ico_voice.png);
  margin-right: 10px; }

@media screen and (min-width: 781px) {
  .p-ttc-voice--flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .p-ttc-voice--flex__heading {
    flex: 0 0 auto;
    padding-right: 25px; }
  .p-ttc-voice__text-wrap {
    flex: 1 1 auto;
    background-image: url(../img/common/vartical-line_yerrow.png);
    background-repeat: repeat-y;
    background-position: left;
    padding-left: 25px; } }

@media screen and (max-width: 780px) {
  .p-ttc-voice--flex__heading {
    background-image: url(../img/common/underline_yellow-thin.png);
    background-repeat: repeat-x;
    background-position: left bottom;
    padding-bottom: 20px;
    text-align: center;
    margin-bottom: 20px; } }

/***************************

object/project/_advance_consulting.scss

***************************/
.p-advance_consulting-calendar__month {
  background-color: #eee;
  width: 15%; }

.p-advance_consulting-calendar__day {
  text-align: left;
  width: 35%; }

/***************************

object/project/_feature.scss

***************************/
.p-feature__rate {
  text-align: center;
  color: #1e56a3;
  font-size: 30px;
  font-weight: bold; }

@media screen and (max-width: 780px) {
  .p-feature__rate {
    font-size: 18px; }
  .p-feature__support-img {
    width: 1000px; } }

/***************************

object/project/_detail.scss

***************************/
.p-detail {
  padding-bottom: 100px; }
  .p-detail .p-detail__img {
    margin-top: 50px;
    text-align: center; }
  .p-detail .p-detail__txt {
    margin-top: 50px; }
  .p-detail .p-detail__table {
    margin-top: 50px; }
    .p-detail .p-detail__table .c-table-type1__th {
      text-align: center;
      white-space: nowrap; }
    .p-detail .p-detail__table .c-table-type1__td {
      text-align: left; }
  .p-detail .l-news-list__item__cat {
    margin: 0 0 0 10px; }

@media screen and (max-width: 780px) {
  .p-detail .p-detail__table {
    width: 100%; } }

@media screen and (max-width: 450px) {
  .p-detail .p-detail__table .c-table-type1__th {
    display: block;
    border: none; }
  .p-detail .p-detail__table .c-table-type1__td {
    display: block; } }

.p-detail__txt h1 {
  font-size: 32px;
  font-weight: normal;
  position: relative;
  border-top: 2px solid #1f1f1f;
  border-bottom: 1px solid #ddd;
  padding: 30px 2px;
  text-align: left;
  margin-bottom: 50px; }

.p-detail__txt h1:before {
  content: "";
  width: 30vw;
  max-width: 128px;
  height: 2px;
  background: #1e56a3;
  position: absolute;
  top: -2px;
  left: 0; }

.p-detail__txt h2 {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  border-left: 4px solid #1e56a3;
  padding: 10px 15px;
  text-align: left;
  margin-bottom: 30px; }
  .p-detail__txt h2 a {
    display: block;
    text-decoration: none;
    color: inherit; }

.p-detail__txt h3 {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 15px 10px 25px;
  background: #f4f4f4;
  text-align: left;
  position: relative;
  margin-bottom: 30px; }
  .p-detail__txt h3 a {
    display: block;
    text-decoration: none;
    color: inherit; }

.p-detail__txt h3:before {
  content: "";
  width: 15px;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  background-color: #1e56a3; }

.p-detail__txt h4 {
  font-size: 16px;
  font-weight: bold;
  padding: 3px;
  color: #1e56a3;
  display: inline-block;
  text-align: left;
  margin-bottom: 20px; }

.p-detail__txt h5 {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px; }

.p-detail__txt h5:before {
  content: "―";
  margin-right: 5px; }

.p-detail__txt h6 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px; }

.p-detail__txt h6:before {
  content: "・";
  margin-right: 5px; }

.p-detail__txt strong {
  font-size: 24px; }

.p-detail__txt small {
  font-size: 12px; }

.p-detail__txt a {
  padding-left: 21px;
  position: relative;
  display: inline-block;
  width: auto;
  line-height: 24px; }

.p-detail__txt a:before {
  font-family: Font Awesome\ 5 Free;
  content: "\f105";
  font-weight: bold;
  color: #1e56a3;
  position: absolute;
  top: calc(50% - 12px);
  left: 5px; }

.p-detail__txt ul {
  padding: 15px 0; }
  .p-detail__txt ul li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px; }
  .p-detail__txt ul li:last-of-type {
    margin-bottom: 0; }
  .p-detail__txt ul li:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 0;
    width: 14px;
    height: 14px;
    background: #1e56a3; }

.p-detail__txt ol {
  padding: 15px 0;
  counter-reset: item; }
  .p-detail__txt ol li {
    position: relative;
    min-height: 32px;
    padding-left: 45px;
    padding-top: 6px;
    margin-bottom: 10px; }
  .p-detail__txt ol li:last-of-type {
    margin-bottom: 0; }
  .p-detail__txt ol li:before {
    counter-increment: item;
    content: counter(item) "";
    color: #fff;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 1px;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background: #222; }

.p-detail__txt dl {
  margin: 15px 0; }

.p-detail__txt dt {
  font-weight: bold;
  padding: 15px 0 0; }

.p-detail__txt dd {
  padding: 15px 0 15px 15px;
  border-bottom: 1px solid #ddd; }

.p-detail__txt dt:first-of-type {
  border-top: 1px solid #ddd; }

.p-detail__txt table {
  background: #fff;
  border-collapse: collapse;
  width: 100%;
  margin: 15px 0; }

.p-detail__txt th {
  background: #7d7d7d;
  font-weight: bold;
  color: #fff;
  border: 1px solid #ddd;
  padding: 15px 20px; }

.p-detail__txt td {
  border: 1px solid #ddd;
  padding: 15px 20px;
  text-align: center;
  vertical-align: top; }

.p-detail__txt blockquote {
  border-left: 6px solid #D1DDE8;
  background-color: #EDF2F8;
  background-image: url(../img/common/quote01.png), url(../img/common/quote01.png);
  background-repeat: no-repeat;
  background-position: top 23px left 18px, bottom 25px right 20px;
  padding: 20px 40px 20px 45px;
  margin: 15px 0; }

@media screen and (min-width: 781px) {
  .p-detail__txt .dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .p-detail__txt .dt {
    flex: 0 0 15em;
    border-bottom: 1px solid #ddd;
    padding: 15px 0; }
  .p-detail__txt .dd {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc(100% - 15em); }
  .p-detail__txt .dd:first-of-type {
    border-top: 1px solid #ddd; } }

@media screen and (max-width: 780px) {
  .p-detail__txt table {
    overflow-x: scroll; }
  .p-detail__txt tbody {
    width: 1280px; } }

@media screen and (min-width: 451px) {
  .p-detail__txt h1 br {
    display: none; } }

@media screen and (max-width: 450px) {
  .p-detail__txt h1 {
    font-size: 26px; }
  .p-detail__txt blockquote {
    background-position: top 23px left 5px, bottom 25px right 10px;
    padding: 20px 35px 20px 30px; } }

/***************************

object/project/_expense.scss

***************************/
.p-expense-table {
  table-layout: fixed; }

.p-expense-table--A {
  table-layout: fixed; }
  .p-expense-table--A .c-table-type1__th:first-child {
    width: 100px; }
  .p-expense-table--A .c-table-type1__td {
    vertical-align: middle; }

/***************************

object/project/_lectureship.scss

***************************/
.p-lectureship .p-lectureship__recruitment {
  text-align: center;
  color: #1e56a3;
  font-size: 30px;
  font-weight: bold; }

/***************************

object/project/_jinseiron.scss

***************************/
.p-jinseiron .p-jinseiron__sub-title {
  font-size: 28px;
  font-weight: normal;
  margin-top: 50px;
  margin-bottom: 50px; }

.p-jinseiron .p-jinseiron__table--wrap {
  overflow-x: auto;
  margin-bottom: 60px; }

.p-jinseiron .p-jinseiron__table {
  width: 1100px; }
  .p-jinseiron .p-jinseiron__table .p-jinseiron__table__times,
  .p-jinseiron .p-jinseiron__table .p-jinseiron__table__day,
  .p-jinseiron .p-jinseiron__table .p-jinseiron__table__talk {
    border: 1px solid #ddd;
    vertical-align: middle;
    padding: 15px; }
  .p-jinseiron .p-jinseiron__table .p-jinseiron__table__times {
    width: 100px;
    text-align: center; }
  .p-jinseiron .p-jinseiron__table .p-jinseiron__table__day {
    width: 205px;
    text-align: center; }
  .p-jinseiron .p-jinseiron__table .p-jinseiron__table__talk {
    width: 700px; }
  .p-jinseiron .p-jinseiron__table thead {
    background-color: #f4f4f4; }
    .p-jinseiron .p-jinseiron__table thead .p-jinseiron__table__times,
    .p-jinseiron .p-jinseiron__table thead .p-jinseiron__table__day,
    .p-jinseiron .p-jinseiron__table thead .p-jinseiron__table__talk {
      padding: 10px 15px; }

@media screen and (min-width: 781px) {
  .p-jinseiron_event.is-second {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .p-jinseiron_event.is-second .p-home-event__list__item {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: calc((100% - 120px) / 4); }
    .p-jinseiron_event.is-second .p-home-event__list__item:not(:nth-of-type(4n)) {
      margin-right: 40px; }
  .p-jinseiron_event.p-home-event__list {
    width: 100%; } }

@media screen and (max-width: 450px) {
  .p-jinseiron .p-jinseiron__sub-title {
    text-align: left; }
    .p-jinseiron .p-jinseiron__sub-title br {
      display: none; } }

/***************************

object/project/_schoolbus.scss

***************************/
.p-schoolbus-normal__table__th {
  width: 25%; }

.is-6column .p-schoolbus-normal__table__th {
  width: calc(100% / 6); }

.is-5column .p-schoolbus-normal__table__th {
  width: calc(100% / 5); }

.p-schoolbus-normal__table2__th {
  width: 50%; }

@media screen and (min-width: 781px) {
  .p-schoolbus-normal__table2 {
    width: 50%; } }

@media screen and (max-width: 780px) {
  .p-schoolbus-normal__table {
    width: 650px; }
  .p-schoolbus-normal__table2 {
    width: 100%; } }

.p-schoolbus-calendar__table {
  background: #fff;
  border-collapse: collapse;
  width: 100%; }

.p-schoolbus-calendar__table__th {
  width: calc(100% / 7);
  background: #7d7d7d;
  font-weight: bold;
  color: #fff;
  border: 1px solid #ddd;
  padding: 15px 5px; }

.p-schoolbus-calendar__table__td {
  border: 1px solid #ddd;
  padding: 5px 10px 10px;
  vertical-align: top;
  min-height: 90px; }

.p-schoolbus-calendar__table__td.other {
  background-color: #eee; }

.p-schoolbus-calendar__table__td.today {
  border: 2px solid #1e56a3; }

.p-schoolbus-calendar__table__td__date {
  font-weight: bold; }

.other .p-schoolbus-calendar__table__td__date {
  color: #7d7d7d; }

.p-schoolbus-calendar__table__td__tag a {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  text-align: center;
  display: block;
  text-decoration: none;
  font-weight: bold; }

.p-schoolbus-calendar__table__td__tag.off a {
  background-color: #d1dde9;
  color: #1e56a3;
  pointer-events: none; }

.p-schoolbus-calendar__table__td__tag.special a {
  background-color: #1e56a3;
  color: #fff; }

.p-schoolbus-calendar__table__td__tag.special a:after {
  font-family: Font Awesome\ 5 Free;
  content: "\f1c1";
  font-style: normal;
  font-variant: normal;
  font-size: 14px;
  color: #fff;
  margin-left: 5px; }

.p-schoolbus-calendar__table__td__tag:nth-of-type(n+3) {
  margin-top: -25px; }

.p-schoolbus-calendar__table__td__tag + .p-schoolbus-calendar__table__td__tag:last-of-type {
  margin-bottom: 0; }

@media screen and (max-width: 450px) {
  .p-schoolbus-calendar {
    padding-left: 0;
    padding-right: 0; }
  .p-schoolbus-calendar__table__td {
    padding: 5px 2px 5px; }
  .p-schoolbus-calendar__table__td__tag a {
    font-size: 12px; } }

.p-schoolbus-calendar__button {
  text-align: right;
  margin-bottom: 30px;
  margin-top: -33px; }

.p-schoolbus-calendar__button__before,
.p-schoolbus-calendar__button__next {
  display: inline-block; }
  .p-schoolbus-calendar__button__before a,
  .p-schoolbus-calendar__button__next a {
    background: #eee;
    padding: 15px 20px;
    text-decoration: none; }
  .p-schoolbus-calendar__button__before a:before,
  .p-schoolbus-calendar__button__next a:before {
    font-family: Font Awesome\ 5 Free;
    font-weight: bold;
    color: #000;
    font-size: 16px; }

.p-schoolbus-calendar__button__before a:before {
  content: "\f104"; }

.p-schoolbus-calendar__button__next a:before {
  content: "\f105"; }

/***************************

object/project/_favorable.scss

***************************/
.p-favorable-priority__table__th1 {
  width: 20%; }

.p-favorable-priority__table__th2 {
  width: 40%; }

.p-favorable-exellence__table__th1 {
  width: 20%; }

.p-favorable-exellence__table__th2 {
  width: calc(80% / 3); }

/***************************

object/project/_opencampus.scss

***************************/
.p-opencampus-event__list__item {
  position: relative;
  margin-bottom: 50px; }
  .p-opencampus-event__list__item a {
    text-decoration: none;
    display: block; }
  @media screen and (max-width: 780px) {
    .p-opencampus-event__list__item > a {
      padding: 25px 10px 25px 85px; } }
  .p-opencampus-event__list__item .c-link-pdf {
    text-decoration: underline; }
  .p-opencampus-event__list__item .c-link-pdf:hover {
    text-decoration: none; }

.p-opencampus-event__list__item__date {
  background: #1e56a3;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  width: 72px;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 5; }
  .p-opencampus-event__list__item__date span {
    display: block;
    margin-top: 5px;
    padding: 10px 5px;
    background: #D1DDE8;
    color: #000;
    font-weight: bold;
    font-size: 28px; }

.p-opencampus-event__list__item__date.is-end {
  background: #666;
  color: #999;
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  line-height: 86px; }

.p-opencampus-event__list__item__img {
  margin-bottom: 10px;
  background: #fff;
  position: relative;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .p-opencampus-event__list__item__img img {
    object-fit: contain;
    height: 270px;
    width: 100%;
    font-family: "object-fit: contain;"; }

.p-opencampus-event__list__item__tag {
  color: #fff;
  background: #000;
  padding: 2px 5px;
  width: 95px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0; }

.p-opencampus-event__list__item__tag.is-end {
  color: #000;
  background: #ddd;
  font-weight: bold;
  display: none; }

.p-opencampus-event__list__item__heading {
  color: #1e56a3;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.p-opencampus-event__list__item__sche {
  color: #000; }

@media screen and (min-width: 781px) {
  .p-opencampus-event__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .p-opencampus-event__list__item {
    width: calc((100% - 120px) / 4); }
  .p-opencampus-event__list__item:not(:nth-of-type(4n)) {
    margin-right: 40px; } }

@media screen and (max-width: 780px) {
  .p-opencampus-event__list {
    margin-bottom: 65px; }
  .p-opencampus-event__list__item__img {
    display: none; }
  .p-opencampus-event__list__item {
    background: #fff;
    margin-bottom: 20px;
    min-height: 100px; }
    .p-opencampus-event__list__item a {
      padding-left: 85px; }
  .p-opencampus-event__list__item__date {
    top: 0;
    left: 0;
    height: 100%; }
    .p-opencampus-event__list__item__date span {
      height: calc(100% - 26px); }
  .p-opencampus-event__list__item__tag {
    position: static;
    margin-bottom: 10px; }
  .p-opencampus-transport__table__aid {
    width: 140px; } }

.p-opencampus-caution__item {
  position: relative;
  font-size: 16px;
  margin-bottom: 5px; }

.p-opencampus-caution__item:last-of-type {
  margin-bottom: 0; }

@media screen and (max-width: 780px) {
  .p-opencampus-buslink a:not(:last-of-type) {
    margin-bottom: 15px; }
  .p-opencampus-transport__table {
    width: 750px; }
  .p-opencampus-lodge__table {
    width: 750px; } }

/***************************

object/project/_employment.scss

***************************/
.p-employment-graduate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.p-employment-graduate__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 18%;
  margin: 0 1%;
  text-align: center; }

.p-employment-graduate__item__p {
  font-size: 52px;
  letter-spacing: -1px;
  color: #1e56a3; }
  .p-employment-graduate__item__p span {
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0px;
    display: block;
    background-color: #1e56a3; }

.p-employment-industry__table td {
  vertical-align: middle; }

.p-employment-industry__table_sorting {
  width: 20%; }

.p-employment-industry__table__th {
  width: 5%;
  height: 300px; }

.p-employment-industry__table .c-table-type1__th,
.p-employment-industry__table .c-table-type1__td {
  padding: 15px 5px; }

@media screen and (min-width: 781px) {
  .p-employment-graduate__table__th {
    width: 12%; } }

@media screen and (max-width: 1024px) {
  .p-employment-graduate {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .p-employment-graduate__item {
    width: 48%;
    margin: 0 1% 20px 1%;
    text-align: center; } }

@media screen and (max-width: 780px) {
  .p-employment-graduate__item__p {
    font-size: 40px;
    color: #1e56a3; }
    .p-employment-graduate__item__p span {
      font-size: 16px;
      font-weight: bold; } }

/***************************

object/project/_organization.scss

***************************/
.p-organization-system {
  text-align: center; }

@media screen and (max-width: 780px) {
  .p-organization-system {
    width: 600px; } }

.p-organization-department__education,
.p-organization-department__human {
  margin-bottom: 40px; }

.p-organization-department__course__item {
  margin-bottom: 20px; }

.p-organization-department__univercity {
  margin-bottom: 30px; }

.p-organization-department__univercity__heading {
  font-size: 24px; }

.p-organization-department__department {
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px; }

.p-organization-department__course__heading {
  font-size: 18px;
  background: #fff;
  padding: 10px;
  color: #1e56a3;
  margin-bottom: 15px;
  text-align: center; }

@media screen and (min-width: 781px) {
  .p-organization-department__education {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 40px) / 3);
    margin-right: 40px; }
  .p-organization-department__human {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 40px) / 3 * 2); } }

@media screen and (max-width: 780px) {
  .p-organization-department__course__heading {
    padding: 10px 5px; } }

/***************************

object/project/_jimisugo.scss

***************************/
.p-jimisugo-top {
  background-color: #D0D1D1;
  padding: 60px 0 100px; }

.p-jimisugo-mae,
.p-jimisugo-zai {
  background-color: #D0D1D1;
  padding-left: 10px;
  padding-right: 10px; }
  .p-jimisugo-mae .c-container,
  .p-jimisugo-zai .c-container {
    background: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px; }

@media screen and (min-width: 781px) {
  .p-jimisugo-mae,
  .p-jimisugo-zai {
    padding-left: 10px;
    padding-right: 10px; }
    .p-jimisugo-mae .c-container,
    .p-jimisugo-zai .c-container {
      padding-left: 40px;
      padding-right: 40px; } }

.p-jimisugo__ttl-wave {
  display: inline-block;
  background-image: url(../img/common/underline_wave.png);
  background-repeat: repeat-x;
  background-position: bottom;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 32px; }

@media screen and (max-width: 780px) {
  .p-jimisugo__ttl-wave {
    font-size: 30px; } }

.p-jimisugo__ttl-bg-gray {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  background: #f4f4f4;
  text-align: left; }

.p-jimisugo__sugoi {
  background-image: url(../img/common/bg_dot-gray.png);
  padding: 15px 20px;
  margin-bottom: 40px; }

.p-jimisugo__sugoi__l {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  display: inline-block; }
  .p-jimisugo__sugoi__l img {
    position: absolute;
    bottom: 0;
    margin-left: 10px; }

.p-jimisugo__sugoi__r {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  font-size: 14px;
  font-weight: bold;
  padding: 3px;
  background: #FFF100;
  display: inline-block;
  text-align: center; }

@media screen and (min-width: 781px) {
  .p-jimisugo__sugoi {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; } }

@media screen and (max-width: 780px) {
  .p-jimisugo__sugoi {
    padding: 15px 10px; }
  .p-jimisugo__sugoi__r {
    display: block;
    margin-top: 10px; } }

.p-jimisugo__heading {
  display: inline;
  background-image: url(../img/common/underline_yellow.png);
  background-repeat: repeat-x;
  background-position: left 0 bottom 5px;
  font-size: 50px;
  line-height: 1.4; }

@media screen and (max-width: 780px) {
  .p-jimisugo__heading {
    font-size: 25px; } }

@media screen and (min-width: 781px) {
  .p-jimisugo-flex__text {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 710px;
    margin-right: 40px; }
  .p-jimisugo-flex__image {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 450px; } }

@media screen and (max-width: 780px) {
  .p-jimisugo-flex__text {
    margin-bottom: 40px; }
  .p-jimisugo-flex__image {
    text-align: center; } }

.p-jimisugo__ol {
  counter-reset: item; }

.p-jimisugo__ol__item {
  position: relative;
  padding: 15px 0 15px 55px;
  border-bottom: 1px dotted #CCCCCC; }

.p-jimisugo__ol__item:before {
  counter-increment: item;
  content: counter(item) "";
  line-height: 45px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: calc(50% - 23px);
  left: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #FFF; }

.p-jimisugo-tuition__table__school {
  width: 100px; }

.p-jimisugo-tuition__table__graph {
  width: 40%; }

[class^="p-jimisugo-tuition__table__bar"] {
  width: calc(100% - 4em);
  background: #1e56a3;
  display: inline-block;
  margin-right: 1em; }

.p-jimisugo-tuition__table__bar--431 {
  width: calc(100% - 4em); }

.p-jimisugo-tuition__table__bar--420 {
  width: calc((100% - 4em) * 420 / 431); }

.p-jimisugo-tuition__table__bar--408 {
  width: calc((100% - 4em) * 408 / 431); }

.p-jimisugo-tuition__table__bar--114 {
  width: calc((100% - 4em) * 114 / 431); }

.p-jimisugo-tuition__table__bar--216 {
  width: calc((100% - 4em) * 216 / 431); }

.p-jimisugo-tuition__table__bar--318 {
  width: calc((100% - 4em) * 318 / 431); }

.p-jimisugo-tuition__table__bar--125 {
  width: calc((100% - 4em) * 125 / 431); }

.p-jimisugo-tuition__table__bar--227 {
  width: calc((100% - 4em) * 227 / 431); }

.p-jimisugo-tuition__table__bar--329 {
  width: calc((100% - 4em) * 329 / 431); }

.p-jimisugo-tuition__table__bar--240 {
  width: calc((100% - 4em) * 240 / 431); }

.p-jimisugo-tuition__table__bar--250 {
  width: calc((100% - 4em) * 250 / 431); }

.p-jimisugo-tuition__table__bar--400 {
  width: calc((100% - 4em) * 400 / 431); }

@media screen and (min-width: 781px) {
  .p-jimisugo-anchor {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .p-jimisugo-anchor__list {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
  .p-jimisugo-anchor__list:nth-of-type(1) {
    margin-right: 15px; } }

@media screen and (max-width: 780px) {
  .p-jimisugo-anchor__list {
    margin-bottom: 20px; } }

.p-jimisugo-anchor {
  margin-top: 30px; }

.p-jimisugo-anchor__list a {
  padding: 15px 20px;
  background: #fff;
  display: block;
  color: #000;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  -webkit-border-radius: 10px; }

.p-jimisugo-anchor__list a:before {
  font-family: Font Awesome\ 5 Free;
  content: "\f107";
  font-weight: bold;
  margin-right: 10px; }

/***************************

object/project/_circle.scss

***************************/
.p-circle .club-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .p-circle .club-list .club-list__item {
    width: 15%;
    margin: 0 2% 15px 0;
    padding: 15px 5px;
    background-color: #f4f4f4;
    text-align: center; }
    .p-circle .club-list .club-list__item:nth-child(6n) {
      margin: 0 0 15px 0; }

.p-circle .u-flexbox-tab .u-flexbox-tab__item4 {
  padding: 25px 15px;
  background-color: #f4f4f4; }

@media screen and (max-width: 1100px) {
  .p-circle .club-list .club-list__item {
    width: 49%;
    margin: 0 2% 10px 0; }
    .p-circle .club-list .club-list__item:nth-child(2n), .p-circle .club-list .club-list__item:nth-child(6n) {
      margin: 0 0 10px 0; } }

@media screen and (max-width: 768px) {
  .p-circle .club-list .club-list__item {
    width: 100%;
    margin: 0 0 10px 0; }
    .p-circle .club-list .club-list__item:nth-child(2n), .p-circle .club-list .club-list__item:nth-child(6n) {
      margin: 0 0 10px 0; } }

/***************************

object/project/_mikikai.scss

***************************/
.p-mikikai-about .p-mikikai-about__flexbox .p-mikikai-about__flexbox__pic {
  width: 40%;
  padding-right: 5%;
  line-height: 1.4; }

.p-mikikai-about .p-mikikai-about__flexbox .p-mikikai-about__flexbox__text {
  width: 60%; }

.p-mikikai-history .p-mikikai-history__table {
  width: 100%; }
  .p-mikikai-history .p-mikikai-history__table .p-mikikai-history__table__th {
    width: 10%;
    min-width: 150px;
    font-weight: normal;
    padding: 10px;
    border: 1px solid #ddd; }
  .p-mikikai-history .p-mikikai-history__table .p-mikikai-history__table__td {
    width: 90%;
    padding: 10px;
    border: 1px solid #ddd; }

@media screen and (min-width: 769px) {
  .p-mikikai-history .p-mikikai-history__table tr:nth-child(odd) .p-mikikai-history__table__th,
  .p-mikikai-history .p-mikikai-history__table tr:nth-child(odd) .p-mikikai-history__table__td {
    background-color: #eee; } }

@media screen and (max-width: 768px) {
  .p-mikikai-about .p-mikikai-about__flexbox {
    display: block; }
    .p-mikikai-about .p-mikikai-about__flexbox .p-mikikai-about__flexbox__pic {
      width: 100%;
      margin: 0 auto 20px auto;
      padding-right: 0; }
    .p-mikikai-about .p-mikikai-about__flexbox .p-mikikai-about__flexbox__text {
      width: 100%; }
  .p-mikikai-history .p-mikikai-history__table {
    width: 100%;
    border-bottom: 1px solid #ddd; }
    .p-mikikai-history .p-mikikai-history__table .p-mikikai-history__table__th {
      display: block;
      width: 100%;
      text-align: left;
      background-color: #eee; }
    .p-mikikai-history .p-mikikai-history__table .p-mikikai-history__table__td {
      display: block;
      width: 100%;
      border-top: none;
      border-bottom: none;
      background-color: #fff; } }

/***************************

object/project/_dormitory.scss

***************************/
.p-dormitory .appeal-list .appeal-list__item {
  margin-bottom: 80px; }
  .p-dormitory .appeal-list .appeal-list__item .appeal-list__item__title {
    margin-top: 10px;
    margin-bottom: 15px;
    min-height: 90px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    text-align: left; }
    .p-dormitory .appeal-list .appeal-list__item .appeal-list__item__title .appeal-list__item__title__icon {
      background-color: #1e56a3;
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;
      margin: 20px 40px 20px 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
      font-size: 16px;
      color: #fff;
      transform: rotate(-45deg); }
      .p-dormitory .appeal-list .appeal-list__item .appeal-list__item__title .appeal-list__item__title__icon span {
        transform: rotate(45deg); }

.p-dormitory .p-dormitory__system-table .c-table-type1__th {
  padding: 15px 5px; }

.p-dormitory .p-dormitory__system-table .c-table-type1__td {
  padding: 15px 10px;
  text-align: left; }

.p-dormitory .p-dormitory__system-table .c-table-type1__td.center {
  text-align: center; }

.p-dormitory .p-dormitory__overview-table .c-table-type1__td {
  text-align: left; }

.p-dormitory .p-dormitory__voice {
  margin-bottom: 50px; }
  .p-dormitory .p-dormitory__voice .p-dormitory__voice__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    padding: 35px 25px;
    background: #EDF2F8;
    border: 1px solid #CEDAE6; }
    .p-dormitory .p-dormitory__voice .p-dormitory__voice__box .p-dormitory__voice__box__pic {
      width: 9%;
      min-width: 100px;
      margin-right: 3%; }
    .p-dormitory .p-dormitory__voice .p-dormitory__voice__box .p-dormitory__voice__box__text {
      width: 88%;
      line-height: 1.8; }
      .p-dormitory .p-dormitory__voice .p-dormitory__voice__box .p-dormitory__voice__box__text .p-dormitory__voice__box__text__title {
        font-size: 20px;
        text-align: left;
        margin-bottom: 20px; }

@media screen and (min-width: 781px) {
  .p-dormitory__system-table tr:nth-of-type(1) .c-table-type1__th:nth-of-type(1) {
    width: 18%; }
  .p-dormitory__system-table tr:nth-of-type(1) .c-table-type1__th:nth-of-type(2) {
    width: 24%; }
  .p-dormitory__system-table tr:nth-of-type(1) .c-table-type1__th:nth-of-type(3) {
    width: 40%; }
  .p-dormitory__system-table tr:nth-of-type(1) .c-table-type1__th:nth-of-type(4) {
    width: 18%; } }

@media screen and (max-width: 780px) {
  .p-dormitory .appeal-list {
    margin-bottom: 100px; }
    .p-dormitory .appeal-list .appeal-list__item .appeal-list__item__title .appeal-list__item__title__icon {
      margin: 20px 20px 20px 20px; }
  .p-dormitory .p-dormitory__voice .p-dormitory__voice__box {
    display: block; }
    .p-dormitory .p-dormitory__voice .p-dormitory__voice__box .p-dormitory__voice__box__pic {
      width: 100%;
      margin-right: 0;
      margin-bottom: 40px;
      text-align: center; }
    .p-dormitory .p-dormitory__voice .p-dormitory__voice__box .p-dormitory__voice__box__text {
      width: 100%; } }

/***************************

object/project/_teacher-training.scss

***************************/
.p-teacher-training .p-teacher-training__box .p-teacher-training__box__img {
  width: 30%;
  margin-right: 4%; }

.p-teacher-training .p-teacher-training__box .p-teacher-training__box__text {
  width: 66%; }

@media screen and (max-width: 768px) {
  .p-teacher-training .p-teacher-training__box .p-teacher-training__box__img {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 25px;
    text-align: center; }
  .p-teacher-training .p-teacher-training__box .p-teacher-training__box__text {
    width: 100%; } }

/***************************

object/project/_admission.scss

***************************/
.p-admission .p-admission__schedule {
  width: 100%;
  border-collapse: inherit;
  border-spacing: 0 20px; }
  .p-admission .p-admission__schedule .p-admission__schedule__th,
  .p-admission .p-admission__schedule .p-admission__schedule__td {
    text-align: left; }

.p-admission .p-admission__faq {
  width: 100%;
  border-top: 1px solid #ddd; }
  .p-admission .p-admission__faq .p-admission__faq__th,
  .p-admission .p-admission__faq .p-admission__faq__td {
    border-top: none;
    width: 100%;
    text-align: left;
    display: block; }

@media screen and (min-width: 451px) {
  .p-admission__schedule__th {
    width: 30%;
    min-width: 200px; } }

@media screen and (max-width: 450px) {
  .p-admission .p-admission__schedule .p-admission__schedule__th,
  .p-admission .p-admission__schedule .p-admission__schedule__td {
    display: block;
    width: 100%; }
  .p-admission .p-admission__faq {
    width: 100%; } }

/***************************

object/project/_ttc.scss

***************************/
.p-licentiate .p-licentiate__table .p-licentiate__table__th--department {
  background: #7d7d7d;
  color: #fff;
  font-size: 16px;
  white-space: nowrap; }
  .p-licentiate .p-licentiate__table .p-licentiate__table__th--department span {
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl; }

.p-licentiate .p-licentiate__table .p-licentiate__table__th--course {
  background: #7d7d7d;
  color: #fff;
  font-size: 16px; }

.p-licentiate .p-licentiate__table .p-licentiate__table__th--qualification {
  padding: 15px 15px;
  background: #7d7d7d;
  color: #fff;
  font-size: 16px; }

.p-licentiate .p-licentiate__table .p-licentiate__table__th--list {
  vertical-align: bottom;
  padding: 60px 5px 5px 5px;
  text-align: center;
  white-space: nowrap;
  max-width: 32px;
  min-width: 32px; }
  .p-licentiate .p-licentiate__table .p-licentiate__table__th--list:nth-child(odd) {
    background: #eee; }
  .p-licentiate .p-licentiate__table .p-licentiate__table__th--list span {
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl; }

.p-licentiate .p-licentiate__table .p-licentiate__table__td--catergory {
  background-color: #eee;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap; }
  .p-licentiate .p-licentiate__table .p-licentiate__table__td--catergory span {
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl; }

.p-licentiate .p-licentiate__table .p-licentiate__table__td--subcatergory {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap; }
  .p-licentiate .p-licentiate__table .p-licentiate__table__td--subcatergory span {
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl; }

.p-licentiate .p-licentiate__table .p-licentiate__table__td--further-subcategory {
  font-size: 16px;
  font-weight: bold; }

.p-licentiate .p-licentiate__table [class^="p-licentiate__table__td--check"] {
  text-align: center;
  vertical-align: middle;
  color: #1e56a3;
  padding: 5px; }
  .p-licentiate .p-licentiate__table [class^="p-licentiate__table__td--check"] .note {
    font-size: 10px;
    color: #000;
    line-height: 1.2; }

.p-licentiate .p-licentiate__table .p-licentiate__table__td--check-gray {
  background-color: #eee; }

.p-licentiate__table {
  margin-bottom: 10px;
  line-height: 1.5;
  display: block;
  position: relative;
  overflow-x: scroll;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-left: 1px solid #ddd; }
  .p-licentiate__table thead {
    display: table-header-group; }
  .p-licentiate__table tbody {
    display: block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: table-row-group; }
  .p-licentiate__table tr {
    display: table-row; }

[class^="p-licentiate__table__th--"],
[class^="p-licentiate__table__td--"] {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }

.p-licentiate__table__th--department,
.p-licentiate__table__th--course,
.p-licentiate__table__td--catergory,
.p-licentiate__table__td--subcatergory,
.p-licentiate__table__td--further-subcategory {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1; }

.p-licentiate__table__th--department {
  padding: 15px 30px;
  width: 85px;
  min-width: 85px;
  max-width: 85px; }

.p-licentiate__table__td--catergory {
  padding: 15px 30px;
  width: 85px;
  min-width: 85px;
  max-width: 85px; }

.p-licentiate__table__th--course {
  padding: 15px 30px;
  width: 300px;
  min-width: 300px;
  left: 85px; }

.p-licentiate__table__td--subcatergory {
  padding: 15px 30px;
  width: 85px;
  max-width: 85px;
  left: 85px;
  background: #fff; }

.p-licentiate__table__td--further-subcategory {
  padding: 15px 30px;
  width: 300px;
  min-width: 300px;
  left: 85px;
  background: #fff; }

.p-licentiate__table__td--further-subcategory.is-sub {
  width: 215px;
  min-width: 215px;
  left: 170px; }

@media screen and (max-width: 780px) {
  .p-licentiate__table__th--department {
    padding: 15px 5px;
    width: 35px;
    min-width: 35px;
    max-width: 35px; }
  .p-licentiate__table__td--catergory {
    padding: 15px 5px;
    width: 35px;
    min-width: 35px;
    max-width: 35px; }
  .p-licentiate__table__th--course {
    width: 130px;
    min-width: 130px;
    left: 35px; }
  .p-licentiate__table__td--subcatergory {
    padding: 15px 5px;
    width: 35px;
    min-width: 35px;
    max-width: 35px;
    left: 35px; }
  .p-licentiate__table__td--further-subcategory {
    width: 130px;
    min-width: 130px;
    left: 35px; }
  .p-licentiate__table__td--further-subcategory.is-sub {
    width: 95px;
    min-width: 95px;
    left: 70px; } }

@media screen and (max-width: 450px) {
  .p-licentiate__table__th--course {
    padding: 15px 5px;
    width: 100px;
    min-width: 100px; }
  .p-licentiate__table__td--further-subcategory {
    padding: 15px 5px;
    width: 100px;
    min-width: 100px; }
  .p-licentiate__table__td--further-subcategory.is-sub {
    width: 95px; } }

@supports (-ms-ime-align: auto) {
  @media screen and (max-width: 780px) {
    .p-licentiate__table tbody {
      width: 1280px; } } }

@-moz-document url-prefix() {
  .p-licentiate__table__th--department span {
    width: 23px; }
  .p-licentiate__table__td--catergory span {
    width: 23px; } }

/***************************

object/project/_graduate.scss

***************************/
@media screen and (min-width: 781px) {
  .p-graduate__center-margin {
    margin-right: 25px; } }

@media screen and (max-width: 780px) {
  .p-graduate__center-margin {
    margin-bottom: 25px; } }

/***************************

object/project/_greeting.scss

***************************/
.p-greeting-heading {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 40px;
  margin-top: 40px; }

@media screen and (min-width: 781px) {
  .p-greeting-president {
    flex: 0 0 400px;
    margin-left: 40px; } }

@media screen and (max-width: 450px) {
  .p-greeting-heading {
    font-size: 20px;
    text-align: left; }
    .p-greeting-heading br {
      display: none; } }

/***************************

object/project/_spirit.scss

***************************/
.p-spirit-heading {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 40px;
  margin-top: 40px;
  text-align: center; }

.p-spirit-text {
  font-size: 16px;
  line-height: 2em;
  text-align: center; }

.p-spirit-founder {
  margin-bottom: 30px; }

@media screen and (min-width: 781px) {
  .p-spirit-founder {
    flex: 0 0 400px;
    margin-right: 40px; } }

@media screen and (max-width: 450px) {
  .p-spirit-heading {
    text-align: left; }
    .p-spirit-heading br {
      display: none; }
  .p-spirit-text {
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px; }
    .p-spirit-text br {
      display: none; } }

/***************************

object/project/_teacher-training.scss

***************************/
.p-teacher-training .p-teacher-training__box .p-teacher-training__box__img {
  width: 30%;
  margin-right: 4%; }

.p-teacher-training .p-teacher-training__box .p-teacher-training__box__text {
  width: 66%; }

@media screen and (max-width: 768px) {
  .p-teacher-training .p-teacher-training__box .p-teacher-training__box__img {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 25px;
    text-align: center; }
  .p-teacher-training .p-teacher-training__box .p-teacher-training__box__text {
    width: 100%; } }

/***************************

object/project/_briefing.scss

***************************/
.p-briefing-button {
  border: none;
  color: #FFF;
  padding: 10px 15px;
  margin: 0 10px;
  background: #1e56a3; }

@media screen and (max-width: 780px) {
  .p-briefing-button {
    display: block;
    margin: 10px 0; } }

/***************************

object/project/_circle.scss

***************************/
@media screen and (max-width: 780px) {
  .p-certification-table {
    width: 100%; } }

@media screen and (max-width: 450px) {
  .p-certification-table .c-table-type1__th,
  .p-certification-table .c-table-type1__td {
    padding: 15px 10px; }
  .p-certification-table__th {
    width: 150px; } }

/***************************

object/project/_support.scss

***************************/
.p-support-table__th {
  width: 28%; }

@media screen and (max-width: 780px) {
  .p-support-table__th {
    width: auto; } }

/***************************

object/project/_teacher-training.scss

***************************/
.p-teacher-table__post {
  width: 25%; }

.p-teacher-table__name {
  width: 25%; }

.p-teacher-table__subject {
  width: 50%; }

@media screen and (max-width: 780px) {
  .p-teacher-table {
    width: 850px; } }

/***************************

object/project/_access.scss

***************************/
@media screen and (max-width: 780px) {
  .p-access-map {
    width: 1100px; } }

/***************************

object/utility/display.scss

***************************/
.u-relative {
  position: relative; }

.u-inline {
  display: inline; }

.u-inline-block {
  display: inline-block; }

.u-block {
  display: block; }

.u-none {
  display: none; }

.u-flexbox-tab__item1,
.u-flexbox-tab__item2,
.u-flexbox-tab__item3,
.u-flexbox-tab__item4 {
  margin-bottom: 40px; }

@media screen and (min-width: 781px) {
  .u-flexbox-tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .u-flexbox-tab__item1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 100%;
    margin-bottom: 40px; }
  .u-flexbox-tab__item2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 40px) / 2);
    margin-bottom: 40px; }
  .u-flexbox-tab__item2:nth-of-type(2n+1) {
    margin-right: 40px; }
  .is-reverse .u-flexbox-tab__item2:nth-of-type(2n+1) {
    margin-right: 0px; }
  .is-reverse .u-flexbox-tab__item2:nth-of-type(2n) {
    margin-right: 40px; }
  .u-flex-row-rev .u-flexbox-tab__item2:nth-of-type(2n+1) {
    margin-right: 0; }
  .u-flex-row-rev .u-flexbox-tab__item2:nth-of-type(2n) {
    margin-right: 40px; }
  .u-flexbox-tab__item3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 80px) / 3);
    margin-bottom: 40px; }
  .u-flexbox-tab__item3:not(:nth-of-type(3n)) {
    margin-right: 40px; }
  .u-flexbox-tab__item4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 120px) / 4);
    margin-bottom: 40px; }
  .u-flexbox-tab__item4:not(:nth-of-type(4n)) {
    margin-right: 40px; } }

@media screen and (min-width: 451px) {
  .u-flexbox-sp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .u-flexbox-sp__item2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: calc((100% - 20px) / 2);
    margin-bottom: 20px; }
  .u-flexbox-sp__item2:nth-of-type(2n+1) {
    margin-right: 20px; } }

.u-flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.u-flex-row {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row; }

.u-flex-row-rev {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.u-flex-col {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: col;
  flex-direction: column; }

.u-flex-col-rev {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: col-reverse;
  flex-direction: column-reverse; }

.u-flex-nowrap {
  -ms-flex-wrap: none;
  flex-wrap: nowrap; }

.u-flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.u-flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse; }

.u-flex-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.u-flex-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.u-flex-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.u-flex-justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.c-flex-justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.u-flex-align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.u-flex-align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end; }

.u-flex-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.u-flex-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline; }

.u-flex-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

.u-flex-item0 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

@media screen and (min-width: 781px) {
  .sp {
    display: none; } }

@media screen and (max-width: 780px) {
  .pc {
    display: none; } }

/***************************

object/utility/_background.scss

***************************/
.u-bg--white {
  background-color: #FFF; }

.u-bg--gray {
  background-color: #f4f4f4; }

.u-bg--gray2 {
  background-color: #7d7d7d; }

.u-bg--gray3 {
  background-color: #eee; }

.u-bg--graph {
  background-image: url(../img/common/bg_graph.png); }

.u-bg--bunkyo_blue {
  background-color: #1e56a3; }

.u-bg--blue01 {
  background-color: #EDF2F8; }

/***************************

object/utility/_link.scss

***************************/
.u-link--under-line {
  text-decoration: underline; }

.u-link--under-line:hover {
  text-decoration: none; }

/***************************

object/utility/_text.scss

***************************/
.u-fv {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  letter-spacing: 3px; }

.u-tac {
  text-align: center; }

.u-tal {
  text-align: left; }

.u-tar {
  text-align: right; }

.u-vat {
  vertical-align: top; }

.u-vam {
  vertical-align: middle; }

.u-vab {
  vertical-align: bottom; }

.u-fs-10 {
  font-size: 10px; }

.u-fs-11 {
  font-size: 11px; }

.u-fs-12 {
  font-size: 12px; }

.u-fs-13 {
  font-size: 13px; }

.u-fs-14 {
  font-size: 14px; }

.u-fs-15 {
  font-size: 15px; }

.u-fs-16 {
  font-size: 16px; }

.u-fs-17 {
  font-size: 17px; }

.u-fs-18 {
  font-size: 18px; }

.u-fs-19 {
  font-size: 19px; }

.u-fs-20 {
  font-size: 20px; }

.u-fs-21 {
  font-size: 21px; }

.u-fs-22 {
  font-size: 22px; }

.u-fs-23 {
  font-size: 23px; }

.u-fs-24 {
  font-size: 24px; }

.u-fs-25 {
  font-size: 25px; }

.u-fs-26 {
  font-size: 26px; }

.u-fs-27 {
  font-size: 27px; }

.u-fs-28 {
  font-size: 28px; }

.u-fs-29 {
  font-size: 29px; }

.u-fs-30 {
  font-size: 30px; }

.u-fs-31 {
  font-size: 31px; }

.u-fs-32 {
  font-size: 32px; }

.u-fs-33 {
  font-size: 33px; }

.u-fs-34 {
  font-size: 34px; }

.u-fs-35 {
  font-size: 35px; }

.u-fs-36 {
  font-size: 36px; }

.u-fwb {
  font-weight: bold; }

.u-fwn {
  font-weight: normal; }

.u-indent--1 {
  text-indent: -1em;
  padding-left: 1em; }

.u-indent--2 {
  text-indent: -2em;
  padding-left: 2em; }

.u-indent--3 {
  text-indent: -3em;
  padding-left: 3em; }

.u-indent--4 {
  text-indent: -4em;
  padding-left: 4em; }

.u-indent--5 {
  text-indent: -5em;
  padding-left: 5em; }

.u-fc--white {
  color: #fff; }

.u-fc--black {
  color: #000; }

.u-fc--red {
  color: red; }

.u-fc--bunkyo_blue {
  color: #1e56a3; }

.u-txtdeco-none {
  text-decoration: none; }

/***************************

object/utility/spases.scss

***************************/
.u-m-0 {
  margin: 0; }

.u-mt-0 {
  margin-top: 0px; }

.u-mt-5 {
  margin-top: 5px; }

.u-mt-10 {
  margin-top: 10px; }

.u-mt-15 {
  margin-top: 15px; }

.u-mt-20 {
  margin-top: 20px; }

.u-mt-25 {
  margin-top: 25px; }

.u-mt-30 {
  margin-top: 30px; }

.u-mt-35 {
  margin-top: 35px; }

.u-mt-40 {
  margin-top: 40px; }

.u-mt-45 {
  margin-top: 45px; }

.u-mt-50 {
  margin-top: 50px; }

.u-mt-55 {
  margin-top: 55px; }

.u-mt-60 {
  margin-top: 60px; }

.u-mt-65 {
  margin-top: 65px; }

.u-mt-70 {
  margin-top: 70px; }

.u-mt-75 {
  margin-top: 75px; }

.u-mt-80 {
  margin-top: 80px; }

.u-mt-85 {
  margin-top: 85px; }

.u-mt-90 {
  margin-top: 90px; }

.u-mt-95 {
  margin-top: 95px; }

.u-mt-100 {
  margin-top: 100px; }

.u-mb-0 {
  margin-bottom: 0px; }

.u-mb-5 {
  margin-bottom: 5px; }

.u-mb-10 {
  margin-bottom: 10px; }

.u-mb-15 {
  margin-bottom: 15px; }

.u-mb-20 {
  margin-bottom: 20px; }

.u-mb-25 {
  margin-bottom: 25px; }

.u-mb-30 {
  margin-bottom: 30px; }

.u-mb-35 {
  margin-bottom: 35px; }

.u-mb-40 {
  margin-bottom: 40px; }

.u-mb-45 {
  margin-bottom: 45px; }

.u-mb-50 {
  margin-bottom: 50px; }

.u-mb-55 {
  margin-bottom: 55px; }

.u-mb-60 {
  margin-bottom: 60px; }

.u-mb-65 {
  margin-bottom: 65px; }

.u-mb-70 {
  margin-bottom: 70px; }

.u-mb-75 {
  margin-bottom: 75px; }

.u-mb-80 {
  margin-bottom: 80px; }

.u-mb-85 {
  margin-bottom: 85px; }

.u-mb-90 {
  margin-bottom: 90px; }

.u-mb-95 {
  margin-bottom: 95px; }

.u-mb-100 {
  margin-bottom: 100px; }

.u-mr-0 {
  margin-right: 0px; }

.u-mr-5 {
  margin-right: 5px; }

.u-mr-10 {
  margin-right: 10px; }

.u-mr-15 {
  margin-right: 15px; }

.u-mr-20 {
  margin-right: 20px; }

.u-mr-25 {
  margin-right: 25px; }

.u-mr-30 {
  margin-right: 30px; }

.u-mr-35 {
  margin-right: 35px; }

.u-mr-40 {
  margin-right: 40px; }

.u-mr-45 {
  margin-right: 45px; }

.u-mr-50 {
  margin-right: 50px; }

.u-mr-55 {
  margin-right: 55px; }

.u-mr-60 {
  margin-right: 60px; }

.u-mr-65 {
  margin-right: 65px; }

.u-mr-70 {
  margin-right: 70px; }

.u-mr-75 {
  margin-right: 75px; }

.u-mr-80 {
  margin-right: 80px; }

.u-mr-85 {
  margin-right: 85px; }

.u-mr-90 {
  margin-right: 90px; }

.u-mr-95 {
  margin-right: 95px; }

.u-mr-100 {
  margin-right: 100px; }

.u-ml-0 {
  margin-left: 0px; }

.u-ml-5 {
  margin-left: 5px; }

.u-ml-10 {
  margin-left: 10px; }

.u-ml-15 {
  margin-left: 15px; }

.u-ml-20 {
  margin-left: 20px; }

.u-ml-25 {
  margin-left: 25px; }

.u-ml-30 {
  margin-left: 30px; }

.u-ml-35 {
  margin-left: 35px; }

.u-ml-40 {
  margin-left: 40px; }

.u-ml-45 {
  margin-left: 45px; }

.u-ml-50 {
  margin-left: 50px; }

.u-ml-55 {
  margin-left: 55px; }

.u-ml-60 {
  margin-left: 60px; }

.u-ml-65 {
  margin-left: 65px; }

.u-ml-70 {
  margin-left: 70px; }

.u-ml-75 {
  margin-left: 75px; }

.u-ml-80 {
  margin-left: 80px; }

.u-ml-85 {
  margin-left: 85px; }

.u-ml-90 {
  margin-left: 90px; }

.u-ml-95 {
  margin-left: 95px; }

.u-ml-100 {
  margin-left: 100px; }

.u-p-0 {
  padding: 0; }

.u-pt-0 {
  padding-top: 0px; }

.u-pt-5 {
  padding-top: 5px; }

.u-pt-10 {
  padding-top: 10px; }

.u-pt-15 {
  padding-top: 15px; }

.u-pt-20 {
  padding-top: 20px; }

.u-pt-25 {
  padding-top: 25px; }

.u-pt-30 {
  padding-top: 30px; }

.u-pt-35 {
  padding-top: 35px; }

.u-pt-40 {
  padding-top: 40px; }

.u-pt-45 {
  padding-top: 45px; }

.u-pt-50 {
  padding-top: 50px; }

.u-pt-55 {
  padding-top: 55px; }

.u-pt-60 {
  padding-top: 60px; }

.u-pt-65 {
  padding-top: 65px; }

.u-pt-70 {
  padding-top: 70px; }

.u-pt-75 {
  padding-top: 75px; }

.u-pt-80 {
  padding-top: 80px; }

.u-pt-85 {
  padding-top: 85px; }

.u-pt-90 {
  padding-top: 90px; }

.u-pt-95 {
  padding-top: 95px; }

.u-pt-100 {
  padding-top: 100px; }

.u-pb-0 {
  padding-bottom: 0px; }

.u-pb-5 {
  padding-bottom: 5px; }

.u-pb-10 {
  padding-bottom: 10px; }

.u-pb-15 {
  padding-bottom: 15px; }

.u-pb-20 {
  padding-bottom: 20px; }

.u-pb-25 {
  padding-bottom: 25px; }

.u-pb-30 {
  padding-bottom: 30px; }

.u-pb-35 {
  padding-bottom: 35px; }

.u-pb-40 {
  padding-bottom: 40px; }

.u-pb-45 {
  padding-bottom: 45px; }

.u-pb-50 {
  padding-bottom: 50px; }

.u-pb-55 {
  padding-bottom: 55px; }

.u-pb-60 {
  padding-bottom: 60px; }

.u-pb-65 {
  padding-bottom: 65px; }

.u-pb-70 {
  padding-bottom: 70px; }

.u-pb-75 {
  padding-bottom: 75px; }

.u-pb-80 {
  padding-bottom: 80px; }

.u-pb-85 {
  padding-bottom: 85px; }

.u-pb-90 {
  padding-bottom: 90px; }

.u-pb-95 {
  padding-bottom: 95px; }

.u-pb-100 {
  padding-bottom: 100px; }

.u-pr-0 {
  padding-right: 0px; }

.u-pr-5 {
  padding-right: 5px; }

.u-pr-10 {
  padding-right: 10px; }

.u-pr-15 {
  padding-right: 15px; }

.u-pr-20 {
  padding-right: 20px; }

.u-pr-25 {
  padding-right: 25px; }

.u-pr-30 {
  padding-right: 30px; }

.u-pr-35 {
  padding-right: 35px; }

.u-pr-40 {
  padding-right: 40px; }

.u-pr-45 {
  padding-right: 45px; }

.u-pr-50 {
  padding-right: 50px; }

.u-pr-55 {
  padding-right: 55px; }

.u-pr-60 {
  padding-right: 60px; }

.u-pr-65 {
  padding-right: 65px; }

.u-pr-70 {
  padding-right: 70px; }

.u-pr-75 {
  padding-right: 75px; }

.u-pr-80 {
  padding-right: 80px; }

.u-pr-85 {
  padding-right: 85px; }

.u-pr-90 {
  padding-right: 90px; }

.u-pr-95 {
  padding-right: 95px; }

.u-pr-100 {
  padding-right: 100px; }

.u-pl-0 {
  padding-left: 0px; }

.u-pl-5 {
  padding-left: 5px; }

.u-pl-10 {
  padding-left: 10px; }

.u-pl-15 {
  padding-left: 15px; }

.u-pl-20 {
  padding-left: 20px; }

.u-pl-25 {
  padding-left: 25px; }

.u-pl-30 {
  padding-left: 30px; }

.u-pl-35 {
  padding-left: 35px; }

.u-pl-40 {
  padding-left: 40px; }

.u-pl-45 {
  padding-left: 45px; }

.u-pl-50 {
  padding-left: 50px; }

.u-pl-55 {
  padding-left: 55px; }

.u-pl-60 {
  padding-left: 60px; }

.u-pl-65 {
  padding-left: 65px; }

.u-pl-70 {
  padding-left: 70px; }

.u-pl-75 {
  padding-left: 75px; }

.u-pl-80 {
  padding-left: 80px; }

.u-pl-85 {
  padding-left: 85px; }

.u-pl-90 {
  padding-left: 90px; }

.u-pl-95 {
  padding-left: 95px; }

.u-pl-100 {
  padding-left: 100px; }
