@charset "utf-8";



/***************************

object/project/_circle.scss

***************************/

@include max-tab{
    .p-certification-table{
        width:100%;
    }
}

@include max-sp{
    .p-certification-table{
        .c-table-type1__th,
        .c-table-type1__td{
            padding:15px 10px;
        }
    }
    .p-certification-table__th{
        width:150px;
    }
}