@charset "utf-8";
 /***************************

object/project/_opencampus.scss

***************************/

.p-opencampus-event__list__item{
  position:relative;
  margin-bottom:50px;
  a{
    text-decoration:none;
    display:block;
  }
  >a{
    @include max-tab{
      padding: 25px 10px 25px 85px;
    }
  }
  .c-link-pdf{
    text-decoration:underline;
  }
  .c-link-pdf:hover{
    text-decoration:none;
  }
}

.p-opencampus-event__list__item__date{
  background:$bunkyo_blue;
  color:#fff;
  font-size:12px;
  text-align:center;
  padding-top:5px;
  width:72px;
  position:absolute;
  top:-10px;
  left:-10px;
  z-index:5;

  span{
    display:block;
    margin-top:5px;
    padding:10px 5px;
    background:#D1DDE8;
    color:#000;
    font-weight:bold;
    font-size:28px;
  }
}

.p-opencampus-event__list__item__date.is-end{
  background:#666;
  color:#999;
  font-size:20px;
  font-weight:bold;
  padding:0;
  line-height:86px;
}

.p-opencampus-event__list__item__img{
  margin-bottom:10px;
  background:#fff;
  position:relative;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    object-fit: contain;
    height: 270px;
    width: 100%;
    font-family: 'object-fit: contain;'/*IE対策*/
  }
}

.p-opencampus-event__list__item__tag{
  color:#fff;
  background:#000;
  padding:2px 5px;
  width:95px;
  text-align:center;
  position:absolute;
  bottom:0;
  left:0;
}

.p-opencampus-event__list__item__tag.is-end{
  color:#000;
  background:#ddd;
  font-weight:bold;
  display:none;
}

.p-opencampus-event__list__item__heading{
  color:$bunkyo_blue;
  font-size: 16px;
  text-align:left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.p-opencampus-event__list__item__sche{
  color:#000;
}

@include min-tab{
  .p-opencampus-event__list{
    @include flexbox;
    @include flex-align-start;
    @include flex-wrap;
  }
  .p-opencampus-event__list__item{
    width:calc((100% - 120px) / 4);
  }

  .p-opencampus-event__list__item:not(:nth-of-type(4n)){
    margin-right:40px;
  }
}


@include max-tab{
  .p-opencampus-event__list{
    margin-bottom:65px;
  }
  .p-opencampus-event__list__item__img{
    display:none;
  }
  .p-opencampus-event__list__item{
    background:#fff;
    margin-bottom:20px;
    min-height:100px;
    a{
      padding-left: 85px ;
    }
  }

  .p-opencampus-event__list__item__date{
    top:0;
    left:0;
    height:100%;
    span{
      height:calc(100% - 26px);
    }
  }
  .p-opencampus-event__list__item__tag{
    position:static;
    margin-bottom:10px;
  }

  .p-opencampus-transport__table__aid{
    width:140px;
  }
}

.p-opencampus-caution__item{
//  padding-left: calc(1em + 7px);
  position: relative;
  font-size:16px;
  margin-bottom:5px;
}
.p-opencampus-caution__item:last-of-type{
  margin-bottom:0;
}
//.p-opencampus-caution__item:before{
//  content: "※";
//  position: absolute;
//  left: 0;
//  top: 0;
//}

@include max-tab{
  .p-opencampus-buslink{
    a:not(:last-of-type){
      margin-bottom:15px;
    }
  }
  .p-opencampus-transport__table{
    width:750px;
  }
  .p-opencampus-lodge__table{
    width:750px;
  }
}