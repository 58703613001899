@charset "utf-8";
 /***************************

object/project/_graduate.scss

***************************/

@include min-tab{
  .p-graduate__center-margin{
    margin-right:25px;
  }
}

@include max-tab{
  .p-graduate__center-margin{
    margin-bottom:25px;
  }
}