@charset "utf-8";
 /***************************

object/project/_support.scss

***************************/

.p-support-table__th{
  width:28%;
}

@include max-tab{
  .p-support-table__th{
    width:auto;
  }
}

@include max-sp{
  .p-support-table{
//    width:600px;
  }
}

//@include max-sp{
//  .p-support-table{
//    .c-table-type1__th,
//    .c-table-type1__td{
//      padding:15px 10px;
//    }
//  }
//}