@charset "utf-8";
 /***************************

layout/_depart.scss

***************************/

.l-depart-top__hedding{
  font-size:24px;
  font-weight:normal;
  margin-bottom:25px;
  margin-top:60px;
  text-align:center;
}

.l-depart-top__message{
  text-align:center;
  margin-bottom:30px;
}

.l-depart-course__item{
  margin-bottom:40px;
  border-top:3px solid $bunkyo_blue;
  background-color:#f4f4f4;
  padding-bottom:30px;
  a{
    text-decoration:none;
    color:inherit;
  }
  a:hover{
    > *{
      opacity:0.75;
    }
  }
}
.l-depart-course__item__heading{
  font-size:20px;
  padding:20px 0;
  text-align:center;
}
.l-depart-course__item__img{
  padding:0 10px;
}
.l-depart-course__item__text{
  text-align:center;
  padding:0 25px;
  h3{
    font-size:24px;
    font-weight:normal;
    padding:35px 0 30px;
    border-bottom:1px solid #E0E0E0;
    color:$bunkyo_blue;
    margin-bottom:24px;
  }
}
.l-depart__flow__item{
  .c-title-type4{
    border: 1px solid $bunkyo_blue;
    padding: 3px 10px;
    margin-bottom: 10px;
  }
}

.l-depart-flow__heading{
  font-size:24px;
  font-weight:normal;
  text-align:left;
  margin-bottom:20px;
}

.l-depart-curriculum__heading{
  font-size:24px;
  font-weight:normal;
  text-align:left;
  margin-bottom:20px;
}

@include min-tab{
  .l-depart-course{
    @include flexbox;
    @include flex-justify-between;
    @include flex-wrap;
  }
  .l-depart-course__item{
    width:calc(50% - 20px);
  }
  .l-depart-course__item:nth-of-type(2n+1){
    margin-right:40px;
  }

  .l-depart__flow__item{
        @include flex-item;
        width:calc((100% - 80px) / 2);
        margin-bottom:90px;
    }
    .l-depart__flow__item:nth-of-type(2n+1){
        margin-right:80px;
    }

  .is-reverse{
      .l-depart__flow__item:nth-of-type(2n+1){
          margin-right:0px;
      }
      .l-depart__flow__item:nth-of-type(2n){
          margin-right:80px;
      }
  }
}

@include max-tab{
  .l-depart__flow__item:nth-of-type(2n){
    margin-bottom:70px;
  }
  .l-depart__flow__item{
    .c-title-type4{
      margin-top: 15px;
    }
  }
}

@include max-sp{
  .l-depart-top__hedding{
    text-align:left;
    font-size:20px;
    br{
      display:none;
    }
  }
  .l-depart-top__message{
    text-align:left;
  }
  .l-depart-course__item__text{
    padding:0 15px;
  }
  .l-depart-course__item__text{
    text-align:left;
    h4{
      text-align:left;
      font-size:20px;
    }
    br{
      display:none;
    }
  }
  .l-depart-flow__heading{
    font-size:22px;
  }
  .l-depart-flow__heading{
    font-size:20px;
    br{
      display:none;
    }
  }
  .l-depart-curriculum__heading{
    font-size:20px;
  }
}