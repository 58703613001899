@charset "utf-8";
 /***************************

object/project/_training.scss

***************************/

.p-training-period{
  color:$bunkyo_blue;
  font-weight:bold;
  font-size:16px;
  text-align:left;
}

.p-training-exp__img{
  flex:0 0 auto;
}

.p-training-exp__name{
  font-size:18px;
  font-weight:bold;
}

.p-training-exp__depart{
  margin-bottom:5px;
}
.p-training-exp__heading{
  color:$bunkyo_blue;
  font-size:20px;
  margin-bottom:5px;
  text-align:left;
}

@include min-tab{
  .p-training-exp__img{
    margin-right:40px;
  }
}

@include max-tab{
  .p-training-exp__img{
    margin-bottom:20px;
    text-align:center;
  }
}