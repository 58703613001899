@charset "utf-8";

/***************************

object/project/_teacher-training.scss

***************************/

.p-teacher-training {
    .p-teacher-training__box {
        .p-teacher-training__box__img {
            width: 30%;
            margin-right: 4%;
        }

        .p-teacher-training__box__text {
            width: 66%;
        }
    }
}

@media screen and (max-width: 768px) {

    .p-teacher-training {
        .p-teacher-training__box {
            .p-teacher-training__box__img {
                width: 100%;
                margin-right: 0%;
                margin-bottom: 25px;
                text-align: center;
            }

            .p-teacher-training__box__text {
                width: 100%;
            }
        }
    }
}