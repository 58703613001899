@charset "utf-8";
 /***************************

layout/_breadcrumbs.scss

***************************/
.breadcrumbs{
  @include flexbox;
  @include flex-justify-start;
  @include flex-wrap;
  .current-item a{
      pointer-events:none;
      text-decoration:none;
    }
  a{
  color:#000;
  display:block;
  }
}
.l-page-title{
  margin-bottom:44px;
}

.l-page-title .breadcrumbs{
  position:absolute;
  bottom: -44px;
  left:0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width:1280px;

  .home{
//  background:$bunkyo_blue;
  width: 44px;
    a{
      text-align:center;
      color:$bunkyo_blue;
      padding: 15px 14px;
      text-decoration:none;
    }
  }

  li:nth-of-type(n+2){
  padding: 16px 0 11px 36px;
  //background:#F4F4F4;
  position:relative;
  }

  li:nth-of-type(n+2):before{
  content: "";
  position: absolute;
  top: calc(50% - 3px);
  left: 15px;
  width: 4px;
  height: 4px;
  border-top: 1px solid #222;
  border-right: 1px solid #222;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  }

  li:last-of-type{
  padding-right:20px;
  }
}

footer .breadcrumbs{
  padding: 15px 0;

  .home a i{
    color:$bunkyo_blue;
//    margin-right:5px;
  }

  li:nth-of-type(n+2){
  padding-left: 36px;
  position:relative;
  }

  li:nth-of-type(n+2):before{
  content: "";
  position: absolute;
  top: calc(50% - 3px);
  left: 15px;
  width: 4px;
  height: 4px;
  border-top: 1px solid #222;
  border-right: 1px solid #222;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  }
}

@include max-tab{
  .l-page-title{
    margin-bottom:30px;
    .breadcrumbs{
      bottom:-30px;
      background-color:#fff;
    }
  }
  .l-page-title .breadcrumbs .home{
    //width:30px;
    width:auto;
  }
  .l-page-title .breadcrumbs .home a{
    padding: 10px 0 10px 9px;
  }
  .l-page-title .breadcrumbs li:nth-of-type(n+2){
    padding: 10px 0 7px 20px;
  }
  .l-page-title .breadcrumbs li:nth-of-type(n+2):before{
    top: calc(50% - 4px);
    left: 7px;
  }
  .l-page-title .breadcrumbs li:last-of-type {
    padding-right: 10px;
  }
  .breadcrumbs a{
    font-size:11px;
  }

  footer .breadcrumbs .home a {
    padding: 10px 0px;
  }
  footer .breadcrumbs li:nth-of-type(n+2){
    padding: 10px 0 7px 20px;
  }
  footer .breadcrumbs li:nth-of-type(n+2):before{
    top: calc(50% - 4px);
    left: 7px;
  }
  footer .breadcrumbs li:last-of-type {
    padding-right: 10px;
  }
}

@include max-sp{
  footer .breadcrumbs li:nth-of-type(n+2) {
    padding: 7px 0 7px 14px;
  }
  footer .breadcrumbs li:nth-of-type(n+2):before {
    top: calc(50% - 3px);
    left: 4px;
  }
}