@charset "utf-8";


/***************************

object/project/_lectureship.scss

***************************/

.p-lectureship {

    .p-lectureship__recruitment {
text-align: center;
    color: #1e56a3;
    font-size: 30px;
    font-weight: bold;
    }
}