@charset "utf-8";
 /***************************

object/utility/_text.scss

***************************/

.u-fv{
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
     letter-spacing:3px;
}

.u-tac{
  text-align:center;
}

.u-tal{
  text-align:left;
}

.u-tar{
  text-align:right;
}

.u-vat{
  vertical-align:top;
}

.u-vam{
  vertical-align:middle;
}

.u-vab{
  vertical-align:bottom;
}

@for $i from 10 through 36{
  .u-fs-#{$i}{ font-size: #{$i}px; }
}

.u-fwb{
  font-weight:bold;
}

.u-fwn{
  font-weight:normal;
}

.u-indent--1{
  text-indent:-1em;
  padding-left:1em;
}

.u-indent--2{
  text-indent:-2em;
  padding-left:2em;
}

.u-indent--3{
  text-indent:-3em;
  padding-left:3em;
}

.u-indent--4{
  text-indent:-4em;
  padding-left:4em;
}

.u-indent--5{
  text-indent:-5em;
  padding-left:5em;
}

.u-fc--white{
  color:#fff;
}

.u-fc--black{
  color:#000;
}

.u-fc--red{
  color:red;
}

.u-fc--bunkyo_blue{
  color:$bunkyo_blue;
}

.u-txtdeco-none{
  text-decoration: none;
}