@charset "utf-8";
 /***************************

object/project/_feature.scss

***************************/
.p-feature__rate{
  text-align:center;
  color:$bunkyo_blue;
  font-size:30px;
  font-weight:bold;
}


@include max-tab{
  .p-feature__rate{
    font-size:18px;
  }
  .p-feature__support-img{
    width:1000px;
  }
}