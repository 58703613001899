@charset "utf-8";



/***************************

object/project/_circle.scss

***************************/

.p-circle {

    .club-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .club-list__item {
            width: 15%;
            margin: 0 2% 15px 0;
            padding: 15px 5px;
            background-color: #f4f4f4;
            text-align: center;

            &:nth-child(6n) {
                margin: 0 0 15px 0;
            }
        }
    }

    .u-flexbox-tab {
        .u-flexbox-tab__item4 {
            padding: 25px 15px;
            background-color: #f4f4f4;
        }
    }
}



@media screen and (max-width: 1100px) {

    .p-circle {

        .club-list {

            .club-list__item {
                width: 49%;
                margin: 0 2% 10px 0;
                &:nth-child(2n),
                &:nth-child(6n) {
                    margin: 0 0 10px 0;
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {

.p-circle {
    .club-list {
        .club-list__item {
            width: 100%;
            margin: 0 0 10px 0;
            &:nth-child(2n),
            &:nth-child(6n) {
                margin: 0 0 10px 0;
            }
        }
    }
}
}