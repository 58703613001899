@charset "utf-8";
 /***************************

object/project/_advance_consulting.scss

***************************/

.p-advance_consulting-calendar__month{
  background-color:#eee;
  width:15%;
}

.p-advance_consulting-calendar__day{
  text-align:left;
  width:35%;
}