@charset "utf-8";
 /***************************

object/project/_detail.scss

***************************/

.p-detail{
  padding-bottom: 100px;
  .p-detail__img{
    margin-top: 50px;
    text-align: center;
  }
  .p-detail__txt{
    margin-top: 50px;
  }
  .p-detail__table{
    margin-top: 50px;
    .c-table-type1__th{
      text-align: center;
      white-space: nowrap;
    }
    .c-table-type1__td{
      text-align: left;
    }
  }
  .l-news-list__item__cat{
    margin:0 0 0 10px;
  }
}

@include max-tab{
  .p-detail{
    .p-detail__table{
      width: 100%;
    }
  }
}

@include max-sp{
  .p-detail{
    .p-detail__table{
      .c-table-type1__th{
        display: block;
        border: none;
      }
      .c-table-type1__td{
        display: block;
      }
    }
  }
}

.p-detail__txt{
  h1{
    font-size:32px;
    font-weight: normal;
    position: relative;
    border-top: 2px solid #1f1f1f;
    border-bottom: 1px solid #ddd;
    padding: 30px 2px;
    text-align:left;
    margin-bottom:50px;
    }

  h1:before{
    content:"";
    width: 30vw;
    max-width: 128px;
    height: 2px;
    @include bunkyo_blue_bkg;
    position: absolute;
    top:-2px;
    left:0;
  }


  h2{
    font-size:18px;
    font-weight: bold;
    position: relative;
    border-left: 4px solid $bunkyo_blue;
    padding: 10px 15px;
    text-align:left;
    margin-bottom:30px;

    a{
      display:block;
      text-decoration:none;
      color:inherit;
    }
  }

  h3{
    font-size:16px;
    font-weight: bold;
    padding:10px 15px 10px 25px;
    background:#f4f4f4;
    text-align:left;
    position:relative;
    margin-bottom:30px;

    a{
      display:block;
      text-decoration:none;
      color:inherit;
    }
  }
  h3:before{
    content:"";
    width:15px;
    height:2px;
    position:absolute;
    top:calc(50% - 1px);
    left:0;
    background-color:$bunkyo_blue;
  }

  h4{
    font-size:16px;
    font-weight: bold;
    padding:3px;
    color:$bunkyo_blue;
    display:inline-block;
    text-align:left;
    margin-bottom:20px;
  }

  h5{
    font-size:18px;
    font-weight:bold;
    text-align:left;
    margin-bottom:20px;
  }

  h5:before{
    content:"―";
    margin-right:5px;
  }
  h6{
    font-size:16px;
    font-weight:bold;
    text-align:left;
    margin-bottom:20px;
  }

  h6:before{
    content:"・";
    margin-right:5px;
  }

  strong{
    font-size:24px;
  }
  small{
    font-size:12px;
  }

  a{
    padding-left:21px;
    position:relative;
    display:inline-block;
    width:auto;
    line-height:24px;
  }

  a:before{
    font-family: Font Awesome\ 5 Free;
    content:"\f105";
    font-weight:bold;
    color:$bunkyo_blue;
    position: absolute;
    top:calc(50% - 12px);
    left: 5px;
  }
  ul{
    padding:15px 0;
    li{
      position:relative;
      padding-left:25px;
      margin-bottom:10px;
    }

    li:last-of-type{
      margin-bottom:0;
    }

    li:before{
      content:"";
      position:absolute;
      top:3px;
      left:0;
      width:14px;
      height:14px;
      background:$bunkyo_blue;
    }
  }

  ol{
    padding:15px 0;
    counter-reset: item;
    li{
      position:relative;
      min-height:32px;
      padding-left:45px;
      padding-top:6px;
      margin-bottom:10px;
    }

    li:last-of-type{
      margin-bottom:0;
    }

    li:before{
      counter-increment: item;
      content:counter(item)"";
      color:#fff;
      line-height:32px;
      text-align:center;
      position:absolute;
      top:1px;
      left:0;
      width:32px;
      height:32px;
      @include border-radius(50%);
      background:#222;
    }
  }
  dl{
    margin:15px 0;
  }
  dt{
    font-weight:bold;
    padding:15px 0 0;
  }
  dd{
    padding:15px 0 15px 15px;
    border-bottom:1px solid #ddd;
  }
  dt:first-of-type{
    border-top:1px solid #ddd;
  }
  table{
    background:#fff;
    border-collapse:collapse;
    width:100%;
    margin:15px 0;
  }

  th{
    background:#7d7d7d;
    font-weight:bold;
    color:#fff;
    border:1px solid #ddd;
    padding:15px 20px;
  }

  td{
    border:1px solid #ddd;
    padding:15px 20px;
    text-align:center;
    vertical-align:top;
  }
  blockquote{
    border-left:6px solid #D1DDE8;
    background-color:#EDF2F8;
    background-image:url(../img/common/quote01.png),url(../img/common/quote01.png);
    background-repeat:no-repeat;
    background-position:top 23px left 18px, bottom 25px right 20px;
    padding:20px 40px 20px 45px;
    margin:15px 0;
  }

}

@include min-tab{
  .p-detail__txt{
    .dl{
      @include flexbox;
      @include flex-align-start;
      @include flex-justify-start;
      @include flex-wrap;
    }
    .dt{
      flex:0 0 15em;
      border-bottom:1px solid #ddd;
      padding:15px 0;
    }
    .dd{
      @include flex-item;
      width:calc(100% - 15em);
    }
    .dd:first-of-type{
      border-top:1px solid #ddd;
    }
  }
}

@include max-tab{
  .p-detail__txt{
    table{
      overflow-x:scroll;
    }
    tbody{
      width:$contents_width;
    }
  }
}

@include min-sp{
.p-detail__txt{
    h1{
      br{
        display:none;
      }
    }
  }
}
@include max-sp{
  .p-detail__txt{
    h1{
      font-size:26px;
    }
    blockquote{
      background-position:top 23px left 5px, bottom 25px right 10px;
      padding:20px 35px 20px 30px;
    }
  }
}