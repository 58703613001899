@charset "utf-8";
 /***************************

object/project/_greeting.scss

***************************/

.p-greeting-heading{
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 40px;
  margin-top: 40px;
}
@include min-tab{
  .p-greeting-president{
    flex:0 0 400px;
    margin-left:40px;
  }
}

@include max-sp{
  .p-greeting-heading{
    font-size:20px;
    text-align:left;
    br{
      display:none;
    }
  }
}