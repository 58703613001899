@charset "utf-8";
 /***************************

layout/_pager.scss

***************************/

.wp-pagenavi{
  margin: 20px 0 100px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.wp-pagenavi a, .wp-pagenavi span {
    color: #1f1f1f;
    font-size: 14px;
    margin: 5px;
    width: 3.5em;
    height: 3.5em;
    line-height: 3.5;
    display: block;
    text-decoration: none;
    padding: 0;
    background:#f4f4f4;
    opacity: 1;
}

.wp-pagenavi .previouspostslink,
.wp-pagenavi .nextpostslink{
  line-height: 3.5;
}

.wp-pagenavi span.current {
    color: #fff;
    background: $bunkyo_blue;
    cursor: default;
}

.wp-pagenavi a:hover{
  opacity: 0.8;
}

@include max-tab{
  .wp-pagenavi .extend{
    display:none;
    & + .larger{
      display:none;
    }
  }
}
@include max-sp{
  .wp-pagenavi a, .wp-pagenavi span {
    width: 2.5em;
  }
}



.pager{
  margin: 20px 0 100px 0;
  @include flexbox;
  @include flex-justify-between;
  text-align: center;
}

.pager a {
    color: #1f1f1f;
    font-size: 14px;
    margin: 5px;
    width: 3.5em;
    height: 3.5em;
    line-height: 3.5;
    display: block;
    text-decoration: none;
    padding: 0;
    background:#f4f4f4;
    opacity: 1;
}

.pager .back a{
  width: 200px;
  padding: 0 20px;
  font-weight: bold;
  color: #FFF;
  @include bunkyo_blue_bkg;
}

@include max-sp{

  .pager .back a{
    width: auto;
  }

}

