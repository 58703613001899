@charset "utf-8";
 /***************************

object/utility/display.scss

***************************/
.u-relative{
    position:relative;
}

.u-inline{
    display:inline;
}

.u-inline-block{
    display:inline-block;
}

.u-block{
    display:block;
}

.u-none{
    display:none;
}

.u-flexbox-tab__item1,
.u-flexbox-tab__item2,
.u-flexbox-tab__item3,
.u-flexbox-tab__item4{
    margin-bottom:40px;
}

@include min-tab{
    .u-flexbox-tab {
        @include flexbox;
        @include flex-wrap;
    }

    .u-flexbox-tab__item1{
        @include flex-item;
        width:100%;
        margin-bottom:40px;
    }

    .u-flexbox-tab__item2{
        @include flex-item;
        width:calc((100% - 40px) / 2);
        margin-bottom:40px;
    }
    .u-flexbox-tab__item2:nth-of-type(2n+1){
        margin-right:40px;
    }

    .is-reverse{
        .u-flexbox-tab__item2:nth-of-type(2n+1){
            margin-right:0px;
        }
        .u-flexbox-tab__item2:nth-of-type(2n){
            margin-right:40px;
        }
    }

    .u-flex-row-rev .u-flexbox-tab__item2:nth-of-type(2n+1){
        margin-right:0;
    }

    .u-flex-row-rev .u-flexbox-tab__item2:nth-of-type(2n){
        margin-right:40px;
    }

    .u-flexbox-tab__item3{
        @include flex-item;
        width:calc((100% - 80px) / 3);
        margin-bottom:40px;
    }
    .u-flexbox-tab__item3:not(:nth-of-type(3n)){
        margin-right:40px;
    }

        .u-flexbox-tab__item4{
        @include flex-item;
        width:calc((100% - 120px) / 4);
        margin-bottom:40px;
    }
    .u-flexbox-tab__item4:not(:nth-of-type(4n)){
        margin-right:40px;
    }
}

@include min-sp{
    .u-flexbox-sp {
        @include flexbox;
        @include flex-wrap;
    }

    .u-flexbox-sp__item2{
        @include flex-item;
        width:calc((100% - 20px) / 2);
        margin-bottom:20px;
    }
    .u-flexbox-sp__item2:nth-of-type(2n+1){
        margin-right:20px;
    }
}



.u-flexbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.u-flex-row {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.u-flex-row-rev {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.u-flex-col {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: col;
    flex-direction: column;
}

.u-flex-col-rev {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -ms-flex-direction: col-reverse;
    flex-direction: column-reverse;
}

.u-flex-nowrap {
    -ms-flex-wrap: none;
    flex-wrap: nowrap;
}

.u-flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.u-flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
}

.u-flex-justify-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.u-flex-justify-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.u-flex-justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.u-flex-justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.c-flex-justify-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.u-flex-align-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.u-flex-align-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.u-flex-align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.u-flex-align-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.u-flex-item{
    @include flex-item;
}

.u-flex-item0{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

@include min-tab{
    .sp {
        display: none;
    }
}

@include max-tab{
    .pc{
        display: none;
    }
}