@charset "utf-8";
 /***************************

object/utility/_link.scss

***************************/

.u-link--under-line{
  text-decoration:underline;
}

.u-link--under-line:hover{
  text-decoration:none;
}