@charset "utf-8";
 /***************************

object/project/_expense.scss

***************************/

.p-expense-table{
  table-layout: fixed;
}

.p-expense-table--A{
  table-layout: fixed;
  .c-table-type1__th:first-child{
    width: 100px;
  }
  .c-table-type1__td{
    vertical-align: middle;
  }
}
