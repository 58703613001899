@charset "utf-8";
 /***************************

object/component/_table.scss

***************************/

.c-table-type1,
.c-table-type2{
  background:#fff;
  border-collapse:collapse;
  width:100%;
}

.c-table-type1__th,
.c-table-type2__th{
  background:#7d7d7d;
  font-weight:bold;
  color:#fff;
  border:1px solid #ddd;
  padding:15px 20px;
}

.c-table-type1__td,
.c-table-type2__td{
  border:1px solid #ddd;
  padding:15px 20px;
  text-align:center;
  vertical-align:top;
}

.c-table--scroll{
  overflow: auto;
  white-space: nowrap;
}

//.c-table-type1__td:first-of-type{
//  background:#eee;
//  font-weight:bold;
//}

@include max-tab{
  .c-table-type1{
    width:$contents_width;
  }

  .c-table-type1.is-half{
    width:640px;
  }

  .c-table-type1.is-fit{
    width:100%;
    .c-table-type1__th,
    .c-table-type1__td{
      padding:15px 10px;
    }
  }
    .c-table-type2__th,
    .c-table-type2__td{
    display:block;
    width:100%;
  }
  .c-table-type2__th,
  .c-table-type2__td{
    border-bottom:none;
  }
  tr:last-of-type{
    .c-table-type2__td{
      border-bottom:1px solid #ddd;
    }
  }


  .c-table-type1.is-fix--x{
    display: block;
    position: relative;
    overflow-x: scroll;
    border-collapse: separate;
    border-spacing: 0;
    width:100%;
    border-left:1px solid #ddd;

      tbody {
        display: block;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
      }
    .c-table-type1__th,
    .c-table-type1__td{
      border-left:none;
      border-bottom:none;
    }
    tr:last-of-type{
      .c-table-type1__th,
      .c-table-type1__td{
        border-bottom:1px solid #ddd;
      }
    }
    .c-table-type2__th,
    .c-table-type2__td{
      display: inline-block;
    }
  }
  .c-table-type1__th.is-fix--x,
  .c-table-type1__td.is-fix--x{
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
    border-left:none;
  }

}

@include max-sp{
  .c-table-type1__th.is-fix--x,
  .c-table-type1__td.is-fix--x{
    width:100px;
  }
}

@supports (-ms-ime-align: auto) {
  @include max-tab{
    .c-table-type1.is-fix--x{
      tbody {
        width:$contents_width;
      }
    }
  }
}