@charset "utf-8";
 /***************************

object/project/_ttc.scss

***************************/

.p-ttc-subject-table__th{
  width:calc(100% / 6);
}

.p-ttc-idea__box{
  padding:20px;
}

@include max-sp{
  .p-ttc-idea__box{
    padding:20px 0;
    br{
      display:none;
    }
  }
}

.p-ttc-idea__list{
  background-color:#FFF;
  padding:20px;
  max-width:500px;
  margin:0 auto;
}

@include min-sp{
  .p-ttc-idea__list{
    @include flexbox;
    @include flex-wrap;
  }

  .p-ttc-idea__list__item{
    @include flex-item;
    width:calc(50% - 20px);
  }
  .p-ttc-idea__list__item:nth-of-type(2n+1){
    margin-right:40px;
  }
  .p-ttc-idea__list__item:nth-last-of-type(2){
    margin-bottom:0;
  }
}
.p-ttc-gudance__grade{
  width:125px;
}

@include max-tab{
  .p-ttc-gudance__table{
    width:100%;
  }
}

@include max-sp{
  .p-ttc-gudance__grade{
    width:80px;
  }
}

.p-ttc-practice__list__item{
  background-image:url(../img/common/arrow02.png);
  background-position:right bottom;
  background-repeat:no-repeat;
  padding: 0 40px 20px 15px;
  width:200px;
}
.p-ttc-practice__list__item:nth-of-type(2){
  background-image:url(../img/common/arrow03.png);
}
.p-ttc-practice__list__item:nth-of-type(3){
  background-image:url(../img/common/arrow04.png);
}

@include min-tab{
  .p-ttc-practice__list{
    @include flexbox;
  }
  .p-ttc-practice__list__item{
    @include flex-item;
    margin-right:20px;
  }
}

@include max-tab{
  .p-ttc-practice__list__item{
    margin:0 auto 30px;
  }
}

.p-ttc-voice{
  border:1px solid #ccc;
  padding:25px;
}

.p-ttc-voice__heading{
  font-size:20px;
  background-image:url(../img/common/underline_yellow-thin.png);
  background-repeat:repeat-x;
  background-position:left bottom;
  padding-bottom:20px;
  text-align:center;
  margin-bottom:20px;
}

.p-ttc-voice__heading:before{
  content:url(../img/common/ico_voice.png);
  margin-right: 10px;
}

.p-ttc-voice__text{
  background-color:#f4f4f4;
  padding:20px;
}

.p-ttc-voice__text:not(:last-of-type){
  margin-bottom:15px;
}

.p-ttc-voice--flex{
  border:1px solid #ccc;
  padding:25px;
}
.p-ttc-voice--flex__heading{
  font-size:20px;
}
.p-ttc-voice--flex__heading:before{
  content:url(../img/common/ico_voice.png);
  margin-right: 10px;
}
.p-ttc-voice__text-wrap{

}

@include min-tab{
  .p-ttc-voice--flex{
    @include flexbox;
    @include flex-align-center;
  }
  .p-ttc-voice--flex__heading{
    flex:0 0 auto;
    padding-right:25px;
  }
  .p-ttc-voice__text-wrap{
    flex: 1 1 auto;
    background-image:url(../img/common/vartical-line_yerrow.png);
    background-repeat:repeat-y;
    background-position:left;
    padding-left: 25px;
  }
}

@include max-tab{
  .p-ttc-voice--flex__heading{
    background-image:url(../img/common/underline_yellow-thin.png);
    background-repeat:repeat-x;
    background-position:left bottom;
    padding-bottom:20px;
    text-align:center;
    margin-bottom:20px;
  }
}