@charset "utf-8";
 /***************************

object/project/_brief.scss

***************************/


.p-brief-people-table__sorting{
  width:200px;
}

.p-brief-people-table__academic{
  width:12%;
}

.p-brief-schedule-table{
  width:100%;
}

.p-brief-schedule-table__th{
  text-align:center;
  width:150px;
}

.p-brief-schedule-table__td{
  text-align:left;
}

@include min-tab{
  .p-brief-schedule-table__th{
    width:260px;
  }
}

@include max-tab{
  .p-brief-schedule-table__th{
    width:100%;
  }

  .p-brief-people-table{
    .c-table-type1__th,
    .c-table-type1__td{
      width:100px;
    }
    .c-table-type1__th.is-left,
    .c-table-type1__td.is-left{
      left:100px;
      background:#fff;
    }
  }
}
@include max-sp{
  .p-brief-people-table{
    .c-table-type1__th.is-fix--x,
    .c-table-type1__td.is-fix--x{
      width:80px;
    }
    .c-table-type1__th.is-left,
    .c-table-type1__td.is-left{
      left:80px;
    }
  }
}